import * as Sentry from '@sentry/react';
import { PropsWithChildren } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function ErrorFallback() {
  return (
    <Box sx={{ margin: '2em' }}>
      <Alert severity="error">
        <Typography variant="h5">An error occurred</Typography>
      </Alert>
    </Box>
  );
}

export default function ErrorBoundaryWrapper({ children }: PropsWithChildren) {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
}

import * as ClickService from 'src/services/ClickService';
import { useLocation } from 'react-router';

const useLastClick = () => {
  const location = useLocation();

  return {
    getLast: () => ClickService.getClickedElement(),
    saveLast: (element: string) => {
      const route = location.pathname.replace('/', '');

      ClickService.saveElementClick(element, route);
    },
    clear: () => ClickService.clearElementClick(),
  };
};

export default useLastClick;

import LayoutLanding from 'src/layouts/LayoutLanding';
import LandingPage from 'src/pages/landing/LandingPage';
import productMain from 'src/layouts/assets/landing-prizes-ticket/iphone-14.png';
import { FunctionComponent } from 'react';
import useRequest from 'src/hooks/useRequest';
import * as OffersService from 'src/services/OffersService';
import { isLoading, isNotAsked, isSuccess } from 'src/lib/remoteData';
import { LayoutThemeBase } from 'src/layouts/LayoutDesignThemeOld';
import Loader from 'src/components/Loader';

const LayoutOfferTheme: FunctionComponent<{
  offerId: number;
  isNewDesign?: boolean;
}> = ({ offerId, isNewDesign }) => {
  const [offerRequest] = useRequest(
    async () => await OffersService.getOffer(offerId)
  );

  if (isNotAsked(offerRequest)) {
    return null;
  }

  if (isLoading(offerRequest)) {
    return (
      <LayoutThemeBase>
        <Loader fullSize />
      </LayoutThemeBase>
    );
  }

  if (isSuccess(offerRequest) && offerRequest.data) {
    return (
      <LayoutLanding>
        <LandingPage
          icon={offerRequest.data.prize.icon}
          image={offerRequest.data.prize.image}
          isNewDesign={isNewDesign}
        />
      </LayoutLanding>
    );
  }

  return (
    <LayoutLanding>
      <LandingPage isNewDesign={isNewDesign} />
    </LayoutLanding>
  );
};

const LayoutDesignTheme: FunctionComponent<{
  offerId?: number;
  isNewDesign?: boolean;
}> = ({ offerId, isNewDesign }) => {
  if (offerId) {
    return <LayoutOfferTheme offerId={offerId} isNewDesign={isNewDesign} />;
  }

  return (
    <LayoutLanding>
      <LandingPage isNewDesign={isNewDesign} />
    </LayoutLanding>
  );
};

export default LayoutDesignTheme;

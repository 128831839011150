import { useEffect } from 'react';
import * as TrafficSourceService from 'src/services/TrafficSourceService';

export function useTrafficSource(): void {
  useEffect(() => {
    const params = new URL(window.location.href).searchParams;

    const trafficSource = params.get('ur');
    if (trafficSource) {
      TrafficSourceService.setTrafficSource(trafficSource);
    }
  }, []);
}

import { useEffect, useState } from 'react';
import { isSuccess, RemoteData } from 'src/lib/remoteData';
import { useProfileState } from 'src/providers/ProfileProvider';
import { useLocaleState } from 'src/providers/LocaleProvider';
import * as OffersService from 'src/services/OffersService';
import useRequest from './useRequest';
import { useIFrameTaskDispatch } from 'src/providers/TaskIFrameProvider';
import { initNotix } from 'src/hooks/extraScripts/usePushSubscription';
import { useApplixirDispatch } from 'src/providers/ApplixirProvider';
import { TASK_NAME, TASK_TYPE } from 'src/constants/enums';
import { MainDataStatus, useMainState } from 'src/recoil/main';

const TIMEOUT_TASKS_RELOAD = 2000;

export function extractSubscriptionParams(task: Task): object {
  try {
    return JSON.parse(task.url);
  } catch (err) {
    return {};
  }
}

const excludeSubscriptions = (request: RemoteData<TaskContainer, any>) => {
  if (isSuccess(request)) {
    request.data.items = request.data.items.filter((task) => {
      return !(
        task.name === TASK_NAME.SUBSCRIPTION &&
        Notification?.permission === 'denied'
      );
    });
  }

  return request;
};

export function useTasks(type: TASK_TYPE) {
  const locale = useLocaleState();
  const [request, manager] = useRequest(
    async () => await OffersService.getTasks(type, locale)
  );

  useEffect(() => {
    const interval = setTimeout(() => {
      (async () => {
        if (isSuccess(request)) {
          await manager.reloadAsyncSoftly();
        }
      })();
    }, TIMEOUT_TASKS_RELOAD);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return {
    request: excludeSubscriptions(request),
    manager,
  };
}

const addParam = (originUrl: string, paramName: string, paramValue: string) => {
  const url = new URL(originUrl);

  url.searchParams.append(paramName, paramValue);

  return url;
};

export function useCurrentTask() {
  const [currentTask, setCurrentTask] = useState<Task | null>(null);
  const { setTask: setIFrameTask } = useIFrameTaskDispatch();
  const { setTask: setApplixirTask } = useApplixirDispatch();
  const { profile } = useProfileState();

  const applyTask = async (source: string, task: Task) => {
    if (task.name == TASK_NAME.APLIXIR && profile) {
      await setApplixirTask(task);
      return;
    }
    if (task.is_iframe) {
      await setIFrameTask(task);
      return;
    }
    if (task.url) {
      const w = window.open(addParam(task.url, 'route', source), '_blank');
      if (w) {
        w.focus();
      }
    }

    setCurrentTask(null);
  };

  const clearCurrentTask = () => {
    setCurrentTask(null);
  };

  const openTask = async (task: Task) => {
    if (task.name == TASK_NAME.SUBSCRIPTION && profile) {
      initNotix(Number(profile.id), task.id, extractSubscriptionParams(task));
      return;
    }
    setCurrentTask(task);
  };

  return {
    currentTask,
    applyTask,
    clearCurrentTask,
    openTask,
  };
}

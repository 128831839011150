import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import axiosRetry from 'axios-retry';
import * as AuthService from 'src/services/AuthService';
import * as TokenService from 'src/services/TokenService';
import * as TrafficSourceService from 'src/services/TrafficSourceService';
import * as storage from 'src/lib/storage';
import { isSuccess } from './remoteData';
import { ROUTE_SIGNIN } from 'src/constants/routes';
import { STORAGE_PROFILE_KEY } from 'src/providers/ProfileProvider';
import { BUILD_VERSION } from 'src/constants/app';

const updateTokens = async (): Promise<boolean> => {
  const token = TokenService.getToken();
  if (token && token['refresh-token']) {
    axiosInstance.defaults.headers.common['x-refresh-token'] =
      token['refresh-token'];
    try {
      const response = await AuthService.refresh();
      if (isSuccess(response)) {
        TokenService.setToken(response.data);
        return true;
      }
    } catch (err) {
      console.warn(err);
      return false;
    }
  }
  return false;
};

export const axiosInstance = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = TokenService.getToken();
    if (token && token['access-token']) {
      config.headers.Authorization = `Bearer ${token['access-token']}`;
    }

    const trafficSource = TrafficSourceService.getTrafficSource();
    if (trafficSource) {
      config.headers['X-Ur'] = trafficSource;
    }

    if (BUILD_VERSION) {
      config.headers['X-Fv'] = BUILD_VERSION;
    }

    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';

    return config;
  },
  (error) => Promise.reject(error)
);

interface ConfigWithRetry extends InternalAxiosRequestConfig<any> {
  _retry?: boolean;
}

let updateTokenPromise: Promise<boolean> | null = null;

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const config = error.config as ConfigWithRetry;

    if (!config || config._retry) {
      return Promise.reject(error);
    }

    if (error.response?.config.url === AuthService.refreshUrl) {
      return Promise.reject(error);
    }

    if (
      error.response?.config.url === AuthService.signupUrl &&
      error.response?.status === 400
    ) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      config._retry = true;

      if (updateTokenPromise === null) {
        updateTokenPromise = updateTokens();
      }

      const wasUpdated = await updateTokenPromise;

      updateTokenPromise = null;

      if (wasUpdated) {
        return axiosInstance(config);
      } else {
        storage.reset(STORAGE_PROFILE_KEY);
        TokenService.resetToken();
        window.location.href = ROUTE_SIGNIN;
      }

      return Promise.reject(new Error('Refresh token error'));
    }

    return Promise.reject(error);
  }
);

import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';

export const regClick = (
  zone_id: number,
  element: string,
  route?: string
): Promise<RemoteDataResult<any>> => {
  return request({
    url: `v1/popup_click/`,
    method: 'GET',
    params: {
      zone_id,
      element,
      route,
    },
  });
};

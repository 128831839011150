import './src/sentry';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'rewardis/App';
import { setupYandexMetrika } from 'src/lib/extraScripts';

if (import.meta.env.VITE_YM_ID) {
  setupYandexMetrika(import.meta.env.VITE_YM_ID);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />
);

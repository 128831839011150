import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FEATURE } from 'src/constants/enums';
import { useProfileState } from 'src/providers/ProfileProvider';
import {
  featuresGetIsLoaded,
  featuresGetFeatures,
  featuresAtom,
} from 'src/recoil/features';
import { registrationParamsState } from "src/recoil/registrationParams";

export const useFeature = <T extends FEATURE>(name: T) => {
  const features = useRecoilValue(featuresGetFeatures);

  return Object.keys(features?.[name] || {}).length
    ? features?.[name]
    : undefined;
};

export const useFeatureCallback = () => {
  const features = useRecoilValue(featuresGetFeatures);

  return <T extends FEATURE>(name: T) =>
    features ? features[name] : undefined;
};

export const useFeatureLoadingState = () => {
  return useRecoilValue(featuresGetIsLoaded);
};

const useUpdateFeatures = () => {
  const setFeaturesState = useSetRecoilState(featuresAtom);
  const { profile } = useProfileState();
  const [regParams] = useRecoilState(registrationParamsState);
  const geo = profile?.geo || regParams?.geo

  // TODO: this is temp solution
  return (features: Features) => {
    const featuresWithEnabledGeoExcludes = !geo
      ? features
      : Object.entries(features)
          .filter(
            ([_, value]) =>
              // @ts-ignore
              !value?.['excludes_geos'] ||
              // @ts-ignore
                !value['excludes_geos'].includes(geo)
          )
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    setFeaturesState({
      isLoaded: true,
      features: featuresWithEnabledGeoExcludes,
    });
  };
};

export const useFeaturesUpdater = () => {
  const updateFeatures = useUpdateFeatures();

  return (features: Features) => {
    updateFeatures(features);
  };
};

const FeaturesProvider = () => {
  const { profile } = useProfileState();
  const updateFeatures = useUpdateFeatures();

  useEffect(() => {
    if (profile) {
      updateFeatures(profile.features);
    }
    if (!profile) {
      updateFeatures({});
    }
  }, [profile]);

  return null;
};

export default FeaturesProvider;

import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';

export function getMainSweepstake(
  language: string = 'en'
): Promise<RemoteDataResult<Sweepstake>> {
  return request(
    {
      url: 'v1/sweepstakes/main',
      method: 'GET',
      params: { language },
    },
    (response) => response.data.data.sweepstake
  );
}

export function getSweepstakes(
  language: string = 'en',
  page: number = 1,
  limit: number
): Promise<RemoteDataResult<SweepstakeContainer>> {
  return request({
    url: 'v1/sweepstakes/',
    method: 'GET',
    params: { language, page, page_size: limit },
  });
}

export function getSweepstake(
  id: number,
  language: string = 'en'
): Promise<RemoteDataResult<Sweepstake>> {
  return request({
    url: `v1/sweepstakes/${id}/get`,
    method: 'GET',
    params: { language },
  });
}

export function applyTicket(
  source: string,
  sweepstakeId: number,
  amount: number = 1
): Promise<RemoteDataResult<any>> {
  return request({
    url: `v1/sweepstakes/${sweepstakeId}/buy`,
    method: 'POST',
    data: JSON.stringify({ amount, route: source }),
  });
}

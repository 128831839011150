import { useEffect, useState } from 'react';

enum COMPONENTS_READY {
  NONE,
  ONE,
  TWO,
}

const useCaptchaAwait = (isCaptchaUsed: boolean, callback: () => void) => {
  const [components, update] = useState(
    isCaptchaUsed ? COMPONENTS_READY.NONE : COMPONENTS_READY.ONE
  );

  const next = () => update((current: COMPONENTS_READY) => current + 1);
  const back = () => update((current: COMPONENTS_READY) => current - 1);

  useEffect(() => {
    if (components == COMPONENTS_READY.TWO) {
      callback();
      back();
    }
  }, [components]);

  return next;
};

export default useCaptchaAwait;

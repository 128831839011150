import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
    release: `{rewardis-frontend}@${process.env.npm_package_version}`,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Non-Error promise rejection captured with value: Timeout',
      'Non-Error promise rejection captured with value: XhrError',
      'Invalid PixelID',
      'Method not found',
      /AbortError/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [/^chrome:\/\//i, /^chrome-extension:\/\//i],
    integrations: [
      Sentry.browserApiErrorsIntegration({
        XMLHttpRequest: true,
      }),
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
      Sentry.httpClientIntegration(),
    ],
  });
}

import { useProfileState } from 'src/providers/ProfileProvider';
import * as VerificationService from 'src/services/VerificationService';
import { useCallback } from 'react';

const useVerificationOffer = () => {
  const { profile } = useProfileState();
  const createServiceCall =
    (regMethod: (id: number, geo: string, route: string) => void) => () =>
      profile?.id &&
      regMethod(+profile.id, profile.geo, window.location.pathname);

  const regClick = useCallback(
    createServiceCall(VerificationService.regClick),
    [Boolean(profile)]
  );
  const regImpression = useCallback(
    createServiceCall(VerificationService.regImpression),
    [Boolean(profile)]
  );

  return {
    regClick,
    regImpression,
  };
};

export default useVerificationOffer;

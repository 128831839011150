import { SyncResponse } from 'src/services/CookieSyncService';

export const syncResponseToRegistrationParams = (
  data: SyncResponse
): Partial<RegistrationParams> => ({
  oaid: data.gid,
});

export const impressionResponseToRegistrationParams = (
  impressionResponse: RegistationImpression
): Partial<RegistrationParams> => ({
  geo: impressionResponse.geo,
  reg_imp_id: impressionResponse.reg_imp_id,
  ab_experiment_id: impressionResponse.ab_experiment_id ?? undefined,
  features: impressionResponse.features,
  components: impressionResponse.components,
});

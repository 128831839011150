import { createPortal } from 'react-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MouseEventHandler, useState } from 'react';
import {
  useLocaleDispatch,
  useLocaleState,
  getProjectLocales,
  Locale,
} from 'src/providers/LocaleProvider';

import enImage from './assets/en.svg';
import esImage from './assets/es.svg';
import esArImage from './assets/es-ar.svg';
import esMxImage from './assets/es-mx.svg';
import frImage from './assets/fr.svg';
import deImage from './assets/de.svg';
import itImage from './assets/it.svg';
import idImage from './assets/id.svg';
import ptImage from './assets/pt.svg';
import ptBrImage from './assets/pt-br.svg';
import thImage from './assets/th.svg';
import vnImage from './assets/vn.svg';
import myImage from './assets/my.svg';
import phImage from './assets/ph.svg';
import * as ProfileService from 'src/services/ProfileService';
import { useProfileState } from 'src/providers/ProfileProvider';
// import trImage from './assets/tr.svg';

const primaryFlags: PartialRecord<Locale, string> = {
  en: enImage,
  es: esImage,
  pt: ptImage,
  id: idImage,
  th: thImage,
  vi: vnImage,
  ms: myImage,
  tl: phImage,
  fr: frImage,
  de: deImage,
  it: itImage,
  // tr: trImage,
};

const secondaryFlags: Record<string, Record<string, string>> = {
  es: {
    ar: esArImage,
    mx: esMxImage,
  },
  pt: {
    br: ptBrImage,
  },
};

const navigatorLanguage = window.navigator.language.toLowerCase();
const [primaryLanguage, secondaryLanguage] = navigatorLanguage.split('-');

const selectLocaleImage = (locale: Locale) => {
  if (locale === primaryLanguage && primaryLanguage in secondaryFlags) {
    return (
      secondaryFlags[primaryLanguage][secondaryLanguage] || primaryFlags[locale]
    );
  }

  return primaryFlags[locale];
};

const zIndex = 1000;

interface LanguageModalOverlayProps {
  onClick: () => void;
}

function LanguageModalOverlay({ onClick }: LanguageModalOverlayProps) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: zIndex + 1,
        opacity: 1,
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}
      onClick={onClick}
    />
  );
}

type ModalCoords = {
  x: number;
  y: number;
};

interface LanguageModalContentProps {
  locales: PartialRecord<Locale, string>;
  selectedLocale: Locale;
  fullWidth?: boolean;
  onClick: () => void;
  onSelect: (locale: Locale) => void;
  coords: ModalCoords;
}

function LanguageModalContent({
  locales,
  selectedLocale,
  fullWidth,
  onClick,
  onSelect,
  coords,
}: LanguageModalContentProps) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: coords.y + 16 + 'px',
        zIndex: zIndex + 2,
        right: coords.x + 'px',
      }}
      onClick={onClick}
    >
      <Box
        component="main"
        sx={(theme) => ({
          flex: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '100%',
          maxWidth: fullWidth ? '100%' : null,
          [theme.breakpoints.up('lg')]: {
            maxWidth: fullWidth ? '100%' : null,
          },
        })}
      >
        <Box
          sx={{
            background: '#FFF',
            padding: 2,
            borderRadius: 2,
            width: '240px',
          }}
        >
          {Object.entries(locales).map(([locale, title]) => (
            <Button
              key={locale}
              tabIndex={1}
              color="inherit"
              onClick={() => onSelect(locale as Locale)}
              sx={{
                padding: 1,
                width: '100%',
                color: '#2E2E32',
                display: 'flex',
                justifyContent: 'flex-start',
                backgroundColor:
                  locale === selectedLocale ? '#F6F8FA' : undefined,
                marginTop: 0.5,
                '&:first-of-type': {
                  marginTop: 0,
                },
              }}
            >
              <Box
                component="img"
                src={selectLocaleImage(locale as Locale)}
                sx={{ marginRight: 1, width: '20px', height: '15px' }}
              />
              {title}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

interface LanguageModalProps {
  locale: Locale;
  locales: PartialRecord<Locale, string>;
  fullWidth?: boolean;
  handleClose: () => void;
  handleSelect: (locale: Locale) => void;
  coords?: ModalCoords;
}

function LanguageModal({
  locale,
  locales,
  fullWidth,
  handleClose,
  handleSelect,
  coords,
}: LanguageModalProps) {
  if (!coords) {
    return null;
  }

  return createPortal(
    <>
      <LanguageModalOverlay onClick={handleClose} />
      <LanguageModalContent
        coords={coords}
        fullWidth={fullWidth}
        onClick={handleClose}
        onSelect={handleSelect}
        locales={locales}
        selectedLocale={locale}
      />
    </>,
    document.body
  );
}

interface LanguageButtonProps {
  isOpen: boolean;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  locale: Locale;
  isRedesigned?: boolean;
}

function LanguageButton({
  isOpen,
  locale,
  handleClick,
  isRedesigned,
}: LanguageButtonProps) {
  return (
    <Button
      tabIndex={isOpen ? 1 : 0}
      onClick={handleClick}
      color="inherit"
      sx={{
        borderRadius: 2,
        paddingX: 1,
        height: '32px',
        width: '46px',
        fontWeight: 400,
        fontSize: '16px',
        zIndex: 100,
        '&:focus': {
          outline: 'none',
        },
        ...(isRedesigned
          ? {
              border: '1px solid #131526 !important',
              background: 'inherit',
              color: '#FFFFFF',
            }
          : {
              background: isOpen ? '#FFF' : '#f9fafe',
              color: '#2E2E32',
              '&:hover': {
                background: isOpen ? '#FFF' : '#f9fafe',
              },
            }),
      }}
    >
      {locale.slice(0, 1).toLocaleUpperCase() + locale.slice(1)}
      <ArrowDropDownIcon
        fontSize="medium"
        sx={{
          color: isRedesigned ? 'rgba(255,255,255,0.2)' : 'rgba(0, 0, 0, 0.54)',
          marginLeft: 1,
        }}
      />
    </Button>
  );
}

interface SwitchLanguageProps {
  fullWidth?: boolean;
  isRedesigned?: boolean;
}

export default function SwitchLanguage({
  fullWidth,
  isRedesigned,
}: SwitchLanguageProps) {
  const locale = useLocaleState();
  const locales = getProjectLocales();
  const { setLocale } = useLocaleDispatch();
  const [modalCoords, setCoords] = useState<{ x: number; y: number }>();
  const { profile } = useProfileState();

  const changeProfileLanguage = async (language: string) => {
    if (profile) {
      await ProfileService.updateLanguage({ language });
    }
  };

  const handleSelect = async (locale: Locale) => {
    setLocale(locale);
    await changeProfileLanguage(locale);
    setCoords(undefined);
  };

  if (Object.entries(locales).length < 2) {
    return null;
  }

  return (
    <>
      <LanguageButton
        locale={locale}
        isOpen={!!modalCoords}
        isRedesigned={isRedesigned}
        handleClick={(e) => {
          const bodyRect = document.body.getBoundingClientRect();
          const targetRect = (e.target as HTMLElement).getBoundingClientRect();

          setCoords({
            x: bodyRect.width - targetRect.right,
            y: targetRect.bottom,
          });
        }}
      />
      <LanguageModal
        locale={locale}
        locales={locales}
        coords={modalCoords}
        fullWidth={fullWidth}
        handleSelect={handleSelect}
        handleClose={() => setCoords(undefined)}
      />
    </>
  );
}

import { RemoteDataResult, failure, success } from 'src/lib/remoteData';
import { request } from 'src/lib/request';
import * as storage from 'src/lib/storage';
import { PROJECT_ALIAS } from 'src/constants/app';
import { CustomCaptchaValue } from 'src/components/forms/RegistationForm/fields/CustomCaptchField';

export const refreshUrl = 'v1/entryway/refresh';
export const signupUrl = 'v1/entryway/reg';
export const logoutUrl = 'v1/user_logout';

const REG_FORM_DATA_KEY = 'rw.formData';
const STORAGE_PARAMS_KEY = 'rw.params';

export interface SigninBody {
  email: string;
  password: string;
}

export function signin(
  recaptchaToken2: string = '',
  recaptchaToken3: string = '',
  data: SigninBody
): Promise<RemoteDataResult<Token>> {
  return request({
    url: 'v1/entryway/auth',
    method: 'POST',
    headers: {
      'x-recaptcha-token-2': recaptchaToken2,
      'x-recaptcha-token-3': recaptchaToken3,
    },
    data: JSON.stringify({
      ...data,
      project: PROJECT_ALIAS,
    }),
  });
}

export interface SignupBody {
  oaid?: string;
  first_name?: FirstName;
  last_name?: LastName;
  birth_date?: string;
  phone?: string;
  postcode?: string;
  email?: Email;
  gender?: Gender;
  language?: string;
  sub_id1?: string;
  sub_id2?: string;
  sub_id3?: string;
  sub_id4?: string;
  sub_id5?: string;
  sub_id6?: string;
  sub_id7?: string;
  sub_id8?: string;
  ab2r?: string;
  partner_id?: number;
  red_offer_id?: number;
  design_id?: number;
  reg_imp_id?: number;
  partners?: number[];
  has_metric?: boolean;
  registration_url?: string;
  referer_url?: string;
  age?: string;
  custom_captcha?: CustomCaptchaValue;
  address?: string;
  city?: string;
  elapsed_time?: number;
  offers?: string[];
}

export function signup(
  recaptchaToken2: string = '',
  recaptchaToken3: string = '',
  cloudflareCaptchaToken: string = '',
  data: SignupBody
): Promise<RemoteDataResult<Token & { auth?: boolean }>> {
  return request({
    url: signupUrl,
    method: 'POST',
    headers: {
      'x-recaptcha-token-2': recaptchaToken2,
      'x-recaptcha-token-3': recaptchaToken3,
      'x-cloudflare-captcha-token': cloudflareCaptchaToken,
    },
    data: JSON.stringify({
      ...data,
      project: PROJECT_ALIAS,
    }),
  });
}

export function refresh(): Promise<RemoteDataResult<Token>> {
  return request({
    url: refreshUrl,
    method: 'POST',
  });
}

export const logout = (): Promise<RemoteDataResult<Token>> =>
  request({
    url: logoutUrl,
    method: 'POST',
  });

export function checkConfirmation(
  confirmationToken: string = ''
): Promise<RemoteDataResult<any>> {
  return request({
    url: 'v1/entryway/confirm',
    method: 'POST',
    data: JSON.stringify({
      token: confirmationToken,
    }),
  });
}

export function resendConfirmation(): Promise<RemoteDataResult<any>> {
  // recaptchaToken2: string,
  // recaptchaToken3: string,
  return request({
    url: 'v1/confirmation/resend',
    method: 'POST',
    headers: {
      // 'x-recaptcha-token-2': recaptchaToken2,
      // 'x-recaptcha-token-3': recaptchaToken3,
    },
  });
}

export function getNameBlacklist(): Promise<RemoteDataResult<string[]>> {
  return request({
    url: 'v1/blacklists/',
    method: 'GET',
  });
}

export function recoveryPassword(
  email: string
): Promise<RemoteDataResult<any>> {
  return request({
    url: 'v1/entryway/pass',
    method: 'POST',
    data: JSON.stringify({
      email,
      project: PROJECT_ALIAS,
    }),
  });
}

export function auth(token: string): Promise<RemoteDataResult<any>> {
  return request({
    url: 'v1/entryway/token',
    method: 'POST',
    data: JSON.stringify({ token }),
  });
}

export const validateEmail = (email: string) => {
  return request<{ email: string; valid: boolean }>({
    url: 'v1/email/validate',
    method: 'POST',
    data: JSON.stringify({ email }),
  });
};

export async function sendAuditory(auditoryCode: string) {
  try {
    const searchParams = new URLSearchParams({
      f: 'sync',
      partner: auditoryCode,
    });

    const response = await fetch(
      'https://my.rtmark.net/img.gif?' + searchParams,
      {
        credentials: 'include',
      }
    );

    const data = await response.json();

    return success(data);
  } catch (err: any) {
    return failure(err);
  }
}

export const RegFormStorageService = {
  get data() {
    return storage.get(REG_FORM_DATA_KEY);
  },
  set data(_data: Record<string, any>) {
    storage.set(REG_FORM_DATA_KEY, _data);
  },
  clear() {
    storage.reset(REG_FORM_DATA_KEY);
  },
  get hasData(): boolean {
    return Object.keys(storage.get(REG_FORM_DATA_KEY, {})).length > 0;
  },
};

export const RegParamsStorageService = {
  get data() {
    return storage.get(STORAGE_PARAMS_KEY, {});
  },
  set data(params) {
    storage.set(STORAGE_PARAMS_KEY, params);
  },
  clear() {
    storage.reset(STORAGE_PARAMS_KEY);
  },
};

export const getGoogleAccountInfo = async (accessToken: string) => {
  try {
    const userInfoResponse = await fetch(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      }
    );
    return (await userInfoResponse.json()) as {
      email: string;
      given_name: string;
      family_name: string;
      picture: string;
    };
  } catch (err) {
    return null;
  }
};

export const getGooglePersonalData = async (
  accessToken: string,
  fields: string
) => {
  try {
    const peopleResponse = await fetch(
      `https://people.googleapis.com/v1/people/me?access_token=${accessToken}&personFields=${fields}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      }
    );

    return (await peopleResponse.json()) as {
      genders?: { value: string }[];
      birthdays?: [
        {
          date: {
            year: string;
            month: string;
            day: string;
          };
        },
      ];
    };
  } catch (e) {
    return null;
  }
};

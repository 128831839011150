export const MIN_AGE_LIMIT = 18;
export const MAX_AGE_LIMIT = 100;
export const CURRENT_YEAR = new Date().getFullYear();

export function formatDate(date: Date) {
  return (
    ('0' + date.getDate()).slice(-2) +
    '.' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '.' +
    date.getFullYear()
  );
}

export function formatDateForParser(date: Date) {
  return `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}`;
}

export function daysInMonth(year: number, month: number): number {
  return new Date(year, month, 0).getDate();
}

export function createRange(start: number, stop: number) {
  return start > stop
    ? Array.from({ length: start - stop + 1 }, (_, index) => start - index)
    : Array.from({ length: stop - start + 1 }, (_, index) => start + index);
}

import { mountFrameToDocument } from 'src/lib/extraScripts';
import * as TokenService from 'src/services/TokenService';

import { BackEndProfile } from 'src/constants/enums';
import {PIXEL_IFRAME_ERROR, PIXEL_IFRAME_WARNING} from "src/constants/app";

const REG_STEP_PIXEL_URL = '/api/v1/pixel/reg';
const AUTHED_PIXEL_URL = '/api/v1/pixel/member';

const mountHTMLToFrame = async (res: Response) => {
  const htmlText = await res.text();

  const modifiedHTML = htmlText.replace(
    '<head>',
    `<head>
      <script>
        window.onerror = function(message, source, lineno, colno, error) {
          window.parent.postMessage({
            type: '${PIXEL_IFRAME_ERROR}',
            message: message,
            source: source,
            lineno: lineno,
            colno: colno,
            error: error ? error.toString() : null
          }, '*');
          return false;
        };

        const originalWarn = console.warn;
        console.warn = function(...args) {
          window.parent.postMessage({
            type: '${PIXEL_IFRAME_WARNING}',
            message: args.join(' ')
          }, '*');
          originalWarn.apply(console, args);
        };
      </script>`
  );

  const blob = new Blob([modifiedHTML], { type: 'text/html' });
  const urlObject = URL.createObjectURL(blob);

  mountFrameToDocument('pixel-frame', urlObject);
};

export const loadPixelForSignUp = async (
  regImpId: number,
  subId1: string,
  subId4: string
) => {
  try {
    const params = new URLSearchParams();
    params.set(BackEndProfile.REG_IMP, regImpId.toString());
    params.set(BackEndProfile.SUB_ID1, subId1);
    params.set(BackEndProfile.SUB_ID4, subId4);

    const res = await fetch(`${REG_STEP_PIXEL_URL}?${params}`, {
      method: 'GET',
    });
    await mountHTMLToFrame(res);
  } catch (e) {}
};

export const getPixelForAuthed = async (type: number) => {
  try {
    const token = TokenService.getToken();
    const res = await fetch(`${AUTHED_PIXEL_URL}?type=${type}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token['access-token']}`,
      },
    });

    await mountHTMLToFrame(res);
  } catch (e) {}
};

import { Navigate, Outlet } from 'react-router-dom';
import { useProfileState } from 'src/providers/ProfileProvider';

type RouteProtectedProps = {
  isAuth?: boolean;
  redirectPath?: string;
};

export default function RouteProtected({
  redirectPath = '/',
}: RouteProtectedProps) {
  const { profile } = useProfileState();
  const isAuth = !!profile;

  if (isAuth) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
}

import { useEffect, useState } from 'react';
import * as CookieSyncService from 'src/services/CookieSyncService';
import * as ImpressionService from 'src/services/ImpressionService';
import { isSuccess } from 'src/lib/remoteData';
import { clearUrlParams, getRegistrationUrlParams } from 'src/lib/url';
import { RegParamsStorageService } from 'src/services/AuthService';

import { useGlobalTasksListener } from 'src/providers/GlobalTasksProvider';
import { useFlowUpdater } from 'src/providers/RegFlowProvider';
import { useRecoilState } from 'recoil';
import {
  registrationParamsState,
  RegistrationParamsState,
} from 'src/recoil/registrationParams';
import {
  impressionResponseToRegistrationParams,
  syncResponseToRegistrationParams,
} from 'src/hooks/useExtraRegistrationParams/utils';

export interface RegistrationParamsProps {
  locale: string;
  features?: Features;
}

export const REG_IMP_TTL = 60 * 60 * 1000;

export const restoredRegistrationParams = {
  ...RegParamsStorageService.data,
  ...getRegistrationUrlParams(),
};

export function useExtraRegistrationParams({
  locale,
}: RegistrationParamsProps): {
  registrationParams: Partial<RegistrationParamsState>;
  clearRegistrationParams: () => void;
} {
  const [registrationParamsInState, setRegistrationParamsInState] =
    useRecoilState(registrationParamsState);
  const [isLoading, setLoading] = useState(true);

  const { createdAt, ...restFromState } = registrationParamsInState;
  const { clear: clearGlobalTasks } = useGlobalTasksListener();
  const { clear: clearFlowRemained } = useFlowUpdater();
  const clearRegistrationParams = useClearRegistrationParams();

  const load = async () => {
    const timeStamp = new Date().getTime();
    const isReRequest = createdAt && timeStamp - REG_IMP_TTL > createdAt;
    const isExpiredRegImpId = !createdAt || isReRequest;

    if (registrationParamsInState?.reg_imp_id && !isExpiredRegImpId) {
      setLoading(false);
      return;
    }

    clearGlobalTasks();
    clearFlowRemained();

    const syncResponse = await CookieSyncService.sync();

    const oaid = isSuccess(syncResponse)
      ? syncResponseToRegistrationParams(syncResponse.data)?.oaid
      : registrationParamsInState?.oaid;

    const impressionResponse = await ImpressionService.getRegistationImpression(
      {
        ...registrationParamsInState,
        oaid,
        re_request: isReRequest ? 1 : 0,
      }
    );

    const impressionData = isSuccess(impressionResponse)
      ? impressionResponseToRegistrationParams(impressionResponse.data)
      : {};
    const meta = {
      ...restFromState,
      ...impressionData,
      oaid,
      createdAt: timeStamp,
    };

    setRegistrationParamsInState({
      ...meta,
      language: locale,
    });
    RegParamsStorageService.data = meta;
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return {
    registrationParams: isLoading ? {} : registrationParamsInState,
    clearRegistrationParams,
  };
}

export const useClearRegistrationParams = () => {
  const [, setRegistrationParamsInState] = useRecoilState(
    registrationParamsState
  );

  return () => {
    clearUrlParams();
    RegParamsStorageService.clear();
    setRegistrationParamsInState({});
  };
};

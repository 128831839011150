import { IntlShape, useIntl } from 'react-intl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Theme } from '@mui/material';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { useApplixirDispatch, useApplixirState } from '.';
import uuid from 'src/lib/uuid';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';

const APPLIXIR_URL = 'https://cdn.applixir.com/applixir.sdk3.0m.js';
const ERROR_TIMEOUT = 5_000;

let isScriptInjected = false;

export const setupApplixir = (onLoad: Function, onError: Function) => {
  const scriptElement = document.createElement('script');

  scriptElement.src = APPLIXIR_URL;
  scriptElement.async = true;
  scriptElement.onload = () => onLoad();
  scriptElement.onerror = () => onError();

  document.head.append(scriptElement);
};

const containerStyle = (theme: Theme) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  background: '#FFF',
  boxShadow: 2,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    width: '70%',
    height: '80%',
    maxWidth: '960px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    height: '95%',
  },
});

const closeStyle = () => ({
  background: '#000',
  color: '#FFF',
  position: 'absolute',
  top: 0,
  right: 0,
  lineHeight: '40px',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
});

const createIFrameStyle = (isIFrameLoaded: boolean) => ({
  width: isIFrameLoaded ? '100%' : '1px',
  height: isIFrameLoaded ? '100%' : '1px',
  border: 0,
});

const getMessageByStatus = (intl: IntlShape, status: string) => {
  if (status === 'network-error' || status == 'cors-error') {
    return intl.formatMessage({
      id: 'applixir.error.network',
      defaultMessage: 'Network error',
    });
  }

  if (status === 'ad-blocker') {
    return intl.formatMessage({
      id: 'applixir.error.blocked',
      defaultMessage: 'Blocked',
    });
  }

  if (status === 'ad-blocker') {
    return intl.formatMessage({
      id: 'applixir.error.unavailable',
      defaultMessage: 'Unavailable',
    });
  }

  return intl.formatMessage({
    id: 'applixir.error.default',
    defaultMessage: 'Something was wrong',
  });
};

const TIMER_SECONDS = 30;

export default function ApplixirModal() {
  const intl = useIntl();
  const feature = useFeature(FEATURE.APLIXIR);

  const { task, error } = useApplixirState();
  const [status, setStatus] = useState<string>();
  const [timer, setTimer] = useState<number>(0);
  const { setError, resetTask } = useApplixirDispatch();
  const [isInjected, setInjected] = useState<boolean>(isScriptInjected);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const close = () => {
    resetTask();
  };

  useEffect(() => {
    if (timer <= 0) {
      return;
    }
    const timerId = setTimeout(() => {
      setTimer((timer) => timer - 1);
    }, 1_000);

    return () => {
      clearTimeout(timerId);
    };
  }, [setTimer, timer]);

  useEffect(() => {
    if (!error) {
      return;
    }

    const timerId = setTimeout(() => {
      close();
    }, ERROR_TIMEOUT);

    return () => {
      clearTimeout(timerId);
    };
  }, [error, close]);

  useEffect(() => {
    if (!feature) {
      return;
    }

    if (isInjected || !task) {
      return;
    }

    setupApplixir(
      () => {
        setInjected(true); // local
        isScriptInjected = true; // global

        window.invokeApplixirVideoUnit({
          zoneId: feature.zone_id,
          accountId: feature.account_id,
          gameId: feature.game_id,
          userId: uuid(),
          adStatusCb: (status) => {
            console.log('applixir status', status);
            setStatus(status);
          },
        });
      },
      () => {
        setError('Cannot setup applixir');
        close();
      }
    );
  }, [feature, task, isInjected]);

  useEffect(() => {
    if (!status) {
      return;
    }
    if (
      status === 'ad-watched' ||
      status === 'ad-interrupted' ||
      status === 'fb-watched'
    ) {
      close();
    }

    if (status === 'ad-started') {
      setTimer(TIMER_SECONDS);
    }

    if (
      status === 'sys-closing' ||
      status === 'cors-error' ||
      status === 'network-error' ||
      status === 'ad-blocker' ||
      status === 'ads-unavailiable' //
    ) {
      setError(getMessageByStatus(intl, status));
    }
  }, [task, status]);

  const handleClose = useCallback(
    (e: any, reason: string) => {
      if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && timer) {
        return;
      }
      close();
    },
    [close]
  );

  if (!feature) {
    return null;
  }

  if (!task) {
    return null;
  }

  return (
    <Modal open={Boolean(task)} onClose={handleClose}>
      <Box sx={containerStyle}>
        {timer ? null : (
          <Box sx={closeStyle} onClick={close}>
            x
          </Box>
        )}
        {!isInjected ? (
          <CircularProgress
            size={'3em'}
            sx={{ margin: '0 auto', display: 'block' }}
          />
        ) : null}
        {error ? (
          <Box>error: {error}</Box>
        ) : (
          <div
            id="applixir_vanishing_div"
            style={{ width: '100%', height: '100%' }}
          >
            <iframe
              id="applixir_parent"
              allow="autoplay"
              ref={iframeRef}
              style={createIFrameStyle(isInjected)}
            />
          </div>
        )}
      </Box>
    </Modal>
  );
}

import { FunctionComponent, useEffect, useState } from 'react';
import { FieldName } from 'src/components/forms/RegistationForm/typings';
import MultiStepRegistrationForm from 'src/components/forms/RegistationForm/MultiStepRegistration/MultiStepRegistrationForm';
import { FormData } from './';
import Loader from 'src/components/Loader';
import { SignupBody } from 'src/services/AuthService';
import { useIntl } from 'react-intl';

type Props = {
  prefilledData: any;
  onSubmit: (data: SignupBody) => Promise<any>;
  handleErrorsChange: any;
};

const ExtraForm: FunctionComponent<Props> = ({
  prefilledData,
  onSubmit,
  handleErrorsChange,
}) => {
  const [fields, setFields] = useState<FieldName[]>([]);
  const intl = useIntl();

  const attemptToReg = async () => {
    const result = await onSubmit(prefilledData);
    if (result?.errors) {
      setFields(Object.keys(result.errors) as FieldName[]);
    }
  };

  useEffect(() => {
    attemptToReg();
  }, []);

  const onHandleSubmit = (data: FormData) => {
    return onSubmit({ ...prefilledData, ...data });
  };

  return fields.length ? (
    <MultiStepRegistrationForm
      handleErrorsChange={handleErrorsChange}
      onSubmit={onHandleSubmit}
      fields={fields}
      submitButtonText={intl.formatMessage({
        id: 'sign_up.complete',
        defaultMessage: 'Complete registration',
      })}
    />
  ) : (
    <Loader />
  );
};

export default ExtraForm;

import * as PopupService from 'src/services/PopupService';

enum Formats {
  onclick = 'onclick',
}

enum OnclickFormatEvents {
  click = 'click',
  iframeWindowOpen = 'iframeWindowOpen',
}

export const addPopunderClickReg = (
  zoneId: number,
  getElement: () => string[],
  clearClick: () => void
) => {
  const state = {
    eventCounter: 0,
  };

  const checkAndReg = async () => {
    if (!window?.zfgformats || window?.zfgformats.length === 0) {
      return;
    }
    const onclick = window.zfgformats.find(
      ({ format, zoneId: formatZoneId }) =>
        format === Formats.onclick && formatZoneId == zoneId
    );

    if (!onclick || !onclick?.extra?.gum) {
      return;
    }

    const onClickFrameEvents = onclick.extra
      .gum()
      .filter((event) => event === OnclickFormatEvents.iframeWindowOpen);
    const step = onClickFrameEvents.length / 2;

    if (step != state.eventCounter) {
      state.eventCounter = step;

      const elementInfo = getElement();

      await PopupService.regClick(zoneId, elementInfo[0], elementInfo?.[1]);
    }
    clearClick();
  };

  setInterval(() => {
    checkAndReg();
  }, 1000);
};

import {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useProfileState } from 'src/providers/ProfileProvider';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import { isPushDefault } from 'src/hooks/extraScripts/usePushSubscription';
import Modal from 'src/components/Modal';
import Button from '@mui/material/Button';
import useStyles from './get-styles';
import { Outlet } from 'react-router-dom';
import { setupPushTag } from 'src/lib/extraScripts';
import { getRegistrationUrlParams } from 'src/lib/url';
import { useIntl } from 'react-intl';
import usePushRegSubscription from 'src/hooks/extraScripts/usePushRegSubscription';

const PUSH_TAG_INJECT_TIMEOUT = 30_000;

const LayoutPushReg: FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { profile } = useProfileState();
  const pushRegInjected = useRef<boolean>(false);
  const featurePushRegModal = useFeature(FEATURE.PUSH_REG_MODAL);
  const classes = useStyles();
  const handleClickNo = useCallback(() => setIsModalOpened(false), []);
  const intl = useIntl();

  const handleClickYes = useCallback(async () => {
    try {
      const { sub_id1 } = getRegistrationUrlParams();
      setupPushTag(
        '',
        sub_id1 ?? '',
        featurePushRegModal?.zone,
        featurePushRegModal?.link
      );
      pushRegInjected.current = true;
      setIsModalOpened(false);
    } catch (e) {
      console.warn('Can not init pusher', e);
    }
  }, [profile?.sub_id_1, featurePushRegModal]);

  useEffect(() => {
    if (!featurePushRegModal) return;
    const showPushModal = () => {
      !pushRegInjected.current && isPushDefault() && setIsModalOpened(true);
    };
    const timeoutId = setTimeout(
      showPushModal,
      (featurePushRegModal.interval || PUSH_TAG_INJECT_TIMEOUT) * 1_000
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [featurePushRegModal]);

  usePushRegSubscription();

  return (
    <>
      <Modal isOpen={isModalOpened}>
        <div className={classes.title}>
          {intl.formatMessage({
            id: 'push_reg_modal.title',
            defaultMessage:
              'Would you like to receive information about new giveaways?',
          })}
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={handleClickYes}
            fullWidth
            variant="contained"
            color="primary"
          >
            {intl.formatMessage({
              id: 'push_reg_modal.yes',
              defaultMessage: 'Yes',
            })}
          </Button>
          <div className={classes.smallButton} onClick={handleClickNo}>
            {intl.formatMessage({
              id: 'push_reg_modal.no',
              defaultMessage: 'No',
            })}
          </div>
        </div>
      </Modal>
      {children || <Outlet />}
    </>
  );
};

export default memo(LayoutPushReg);

import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { isFailure, isSuccess } from 'src/lib/remoteData';
import * as AuthService from 'src/services/AuthService';
import {
  sendAuditory,
  SignupBody,
  validateEmail,
} from 'src/services/AuthService';
import * as PageService from 'src/services/PageService';
import RegistrationForm from 'src/components/forms/RegistationForm/';
import { createFields } from 'src/components/forms/RegistationForm/utils';
import { setToken } from 'src/services/TokenService';
import { useLocaleState } from 'src/providers/LocaleProvider';
import DocumentPage from 'src/pages/documents/_DocumentPage';
import RegistrationPartnerModal from 'src/components/modal/RegistrationPartnerModal';
import Modal from 'src/components/Modal';
import { getAuditoryId } from 'src/constants/auditories';
import { useRedirectToPopUnder } from 'src/providers/RegPopunderProvider';
import { DESIGN_ID } from 'src/constants/landings';
import useBlackListOfNames from 'src/hooks/useBlackListOfNames';
import useRegPartner from 'src/hooks/useRegPartner';
import { ErrorContainer } from 'src/lib/errors';
import { sendError } from 'src/services/ErrorService';
import ErrorsWrapper, { ErrorsData } from './ErrorsWrapper';
import { FEATURE, SMARTLOOK_CUSTOM_EVENT } from 'src/constants/enums';
import { useDesignParams } from 'src/hooks/useDesign';
import {
  SMARTLOOK_ELAPSED_TIME,
  smartlookTrackEvent,
} from 'src/services/GoogleTagManagerService';
import {
  getFieldsByDesignID,
  getStepsFieldsByDesignID,
  isShowFBAuthButton,
  isHideGoogleAuthButton,
  shortFieldsInitialValues,
} from 'src/components/forms/RegistationForm/contstants';
import { fbAsyncInit } from 'src/services/FacebookAuthService';
import { setupFBApi } from 'src/lib/extraScripts';
import {
  EXTERNAL_AUTH,
  useExternalAuthProfile,
  useSupplementExternalAuthProfile,
  useUpdateExternalAuthProfile,
} from 'src/recoil/externalAuth';
import MultiStepRegistration from 'src/components/forms/RegistationForm/MultiStepRegistration';
import * as Sentry from '@sentry/react';
import { useRecoilValue } from 'recoil';
import {
  recaptchaFullStateSelector,
  useCaptchaMutation,
} from 'src/recoil/recaptcha';
import RegistrationBackModal from 'src/components/modal/RegistrationBackModal';
import { useReCaptchaContext } from 'src/providers/ReCaptchaProvider';
import { useResetWidget } from 'src/providers/CloudflareCaptchaProvider/useCloudflareCaptchaWidget';

enum ErrorsType {
  FORM,
  RESPONSE,
}

const getPreparedUrl = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('g-recaptcha-response');

  return url.href;
};

export default function StepRegistration({
  onNext,
  redirectUrl,
  registrationParams,
}: {
  onNext: (auth: boolean) => Promise<void>;
  redirectUrl?: string;
  registrationParams: Partial<RegistrationParams>;
}) {
  const intl = useIntl();
  const [recaptchaVersion, setRecaptchaVersion] = useState<RecaptchaVersion>(3);
  const [errors, setErrors] = useState<ErrorsData>({
    data: {},
  });

  const { designId } = useDesignParams();
  const locale = useLocaleState();

  // modal behavior
  const [isRegistrationPartnerModalOpen, setRegistrationPartnerModalOpen] =
    useState<boolean>(false);
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [isTermsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const { selectedPartnerIds, onPartnerSelect, partnerRequest } =
    useRegPartner(locale);
  const redirectToRegPopUnder = useRedirectToPopUnder();
  const supplementExternalAuthProfile = useSupplementExternalAuthProfile();
  const updateExtraAuthProfile = useUpdateExternalAuthProfile();
  const externalAuthProfile = useExternalAuthProfile();
  const blacklist = useBlackListOfNames();
  const context = useReCaptchaContext();
  const resetCloudflareWidget = useResetWidget();

  const [timestampFirstInteraction, setTimestampFirstInteraction] = useState<
    number | null
  >(null);
  const onFirstInteraction = useCallback(
    (timestamp: number) => setTimestampFirstInteraction(timestamp),
    []
  );
  useEffect(() => {
    if (isShowFBAuthButton(designId)) {
      fbAsyncInit();
      setupFBApi();
    }
  }, []);

  const formFields = createFields(
    getFieldsByDesignID(designId),
    registrationParams.ab_experiment_id
  );
  const needV3 = formFields.includes('captchaV3');
  const needCloudflare = formFields.includes('cloudflare_captcha');

  // handlers
  const handleSubmit = useCallback(
    async (data: any) => {
      const { captchaV2, captchaV3, cloudflare_captcha, ...formData } = data;

      if (redirectUrl) {
        AuthService.RegFormStorageService.data = data;
        redirectToRegPopUnder(redirectUrl);

        return;
      }

      try {
        // TODO don't work with popunder
        const elapsedTime = timestampFirstInteraction
          ? new Date().getTime() - timestampFirstInteraction
          : undefined;

        if (elapsedTime && elapsedTime < SMARTLOOK_ELAPSED_TIME) {
          smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.ELAPSED_TIME);
        }
        const isFbAuthed =
          externalAuthProfile?.type === EXTERNAL_AUTH.FB
            ? 'use_fb_auth'
            : undefined;
        const isGoogleAuthed =
          externalAuthProfile?.type === EXTERNAL_AUTH.GOOGLE
            ? 'use_google_auth'
            : undefined;

        const { city, chosen_prize, ...restFormData } = formData;
        const { features, components, ...regParams } = registrationParams;

        if (needV3 && !captchaV3) {
          Sentry.captureException('empty captchaV3 token', (scope) => {
            scope.setExtra('form values', data);
            scope.setExtra('captcha context', context);
            return scope;
          });
        }
        const signupRequest = await AuthService.signup(
          captchaV2,
          captchaV3,
          cloudflare_captcha,
          {
            ...restFormData,
            ...regParams,
            city: chosen_prize,
            sub_id6: isFbAuthed || isGoogleAuthed,
            partners: selectedPartnerIds,
            has_metric: false,
            registration_url: getPreparedUrl(),
            referer_url:
              document.referrer || window.frames?.top?.document.referrer,
            elapsed_time: elapsedTime,
          }
        );

        // @TODO: refactor
        if (isSuccess(signupRequest)) {
          const { auth, ...token } = signupRequest.data;

          await sendAuditory(
            getAuditoryId(formData['gender'], formData['birth_date'])
          );
          setToken(token);
          await onNext(!!auth);
          return;
        }
        if (isFailure(signupRequest)) {
          if (needCloudflare) {
            resetCloudflareWidget();
          }
          if (needV3 && signupRequest.error?.response?.status === 428) {
            // @TODO: refactor
            setRecaptchaVersion(2);
            return;
          }
          setErrors({ type: ErrorsType.RESPONSE, data: signupRequest.error });

          return signupRequest.error.response.data;
        }
      } catch (err) {
        console.warn(err);
        return;
      }
    },
    [
      redirectUrl,
      selectedPartnerIds,
      registrationParams,
      externalAuthProfile,
      timestampFirstInteraction,
    ]
  );

  const createEmailValidation = (callback: (data: any) => void) => {
    return async (data: SignupBody) => {
      const request = await validateEmail(data.email as string);

      if (isFailure(request)) {
        setErrors({ type: ErrorsType.RESPONSE, data: request.error });
        return;
      }
      if (!request.data.valid) {
        setErrors({
          type: ErrorsType.FORM,
          data: {
            email: intl.formatMessage({
              id: 'form.validation.email',
              defaultMessage: 'Enter a valid email',
            }),
          },
        });
      } else {
        callback(data);
      }
    };
  };

  const handleValidation = useCallback(
    createEmailValidation(supplementExternalAuthProfile),
    [externalAuthProfile]
  );

  const handlePreSubmitValidation = useCallback(
    createEmailValidation((data) =>
      updateExtraAuthProfile(EXTERNAL_AUTH.FAKE, data)
    ),
    [externalAuthProfile]
  );

  const handleErrorsChange = useCallback(
    (errors: Record<string, string>, errorContainer?: ErrorContainer) => {
      if (errorContainer) {
        sendError(errorContainer, registrationParams?.reg_imp_id ?? 0);
      }
      setErrors({ type: ErrorsType.FORM, data: errors });
    },
    [registrationParams.reg_imp_id]
  );

  const handleTermsClick = useCallback(() => setTermsModalOpen(true), []);
  const handlePrivacyClick = useCallback(() => setPrivacyModalOpen(true), []);
  const handleMarketingClick = useCallback(
    () => setRegistrationPartnerModalOpen(true),
    []
  );

  const multiStepsFields = useMemo(
    () => getStepsFieldsByDesignID(designId),
    [designId]
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 2,
          marginBottom: 2,
        }}
        className="form-title"
      >
        <Typography variant="h5">
          {intl.formatMessage({
            id: 'RegistrationPage.createAccount',
            defaultMessage: 'Create an account',
          })}
        </Typography>
      </Box>

      <RegistrationBackModal />
      <RegistrationPartnerModal
        partnerRequest={partnerRequest}
        selectedPartnerIds={selectedPartnerIds}
        onPartnerSelect={onPartnerSelect}
        handleClose={() => setRegistrationPartnerModalOpen(false)}
        isOpen={isRegistrationPartnerModalOpen}
      />

      <ErrorsWrapper errors={errors} />

      <Modal
        fullWidth
        withClose
        isOpen={isPrivacyModalOpen}
        handleClose={() => setPrivacyModalOpen(false)}
      >
        <DocumentPage service={PageService.getPrivacyPage} />
      </Modal>

      <Modal
        fullWidth
        withClose
        isOpen={isTermsModalOpen}
        handleClose={() => setTermsModalOpen(false)}
      >
        <DocumentPage service={PageService.getTermsPage} />
      </Modal>

      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: { minHeight: '300px' },
        })}
      >
        {multiStepsFields.length > 0 && externalAuthProfile?.data ? (
          <MultiStepRegistration
            stepsFields={multiStepsFields}
            prefilledData={externalAuthProfile?.data}
            onSubmit={handleSubmit}
            onValidationEmail={handleValidation}
            handleErrorsChange={handleErrorsChange}
          />
        ) : (
          <RegistrationForm
            designId={designId}
            abExperimentId={registrationParams.ab_experiment_id}
            fields={formFields}
            extInitialValues={
              designId === DESIGN_ID.SHORT_FORM ? shortFieldsInitialValues : {}
            }
            internalAuthButtons={{
              fb: !externalAuthProfile && isShowFBAuthButton(designId),
              google: !(
                externalAuthProfile || isHideGoogleAuthButton(designId)
              ),
            }}
            blacklist={blacklist}
            recaptchaVersion={recaptchaVersion}
            handleSubmit={
              designId === DESIGN_ID.INNER_AUTH_SHORT_FORM_2_STEPS
                ? handlePreSubmitValidation
                : handleSubmit
            }
            handleErrorsChange={handleErrorsChange}
            handleTermsClick={handleTermsClick}
            handlePrivacyClick={handlePrivacyClick}
            handleMarketingClick={handleMarketingClick}
            onFirstInteraction={onFirstInteraction}
          />
        )}
      </Box>
    </>
  );
}

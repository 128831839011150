import { useFlowConsumer } from 'src/providers/RegFlowProvider';
import { useEffect, useState } from 'react';
import {
  useFeature,
  useFeatureLoadingState,
} from 'src/providers/FeaturesProvider';
import { FEATURE, REG_FLOW } from 'src/constants/enums';
import * as AdvService from 'src/services/AdvService';
import { isSuccess } from 'src/lib/remoteData';

export const redirectToRegPopUnder = (url: string) => {
  const search = location.search
    ? `${location.search}&redirect=true`
    : '?redirect=true';

  window.open(`/signup${search}&redirect=true`, '_blank');
  location.href = url;
};

export const useRedirectToPopUnder = () => {
  const [, pop] = useFlowConsumer();

  return (url: string) => {
    pop();
    redirectToRegPopUnder(url);
  };
};

export const useRegMarkerUrl = () => {
  const [url, setUrl] = useState<string>();
  const markerFeature = useFeature(FEATURE.MARKER);
  const isFeaturesLoaded = useFeatureLoadingState();
  const [step, pop] = useFlowConsumer();

  useEffect(() => {
    if (!isFeaturesLoaded) {
      return;
    }
    if (step === REG_FLOW.POPUNDER && !markerFeature?.zone) {
      pop();
      return;
    }
    if (step === REG_FLOW.POPUNDER && markerFeature?.zone) {
      const callRequest = async () => {
        const urlSearchParams = new URLSearchParams(location.search);
        const subId1 = urlSearchParams.get('sub_id1') as string;
        const subId2 = urlSearchParams.get('sub_id2');
        const request = await AdvService.getMarkerUrl(
          markerFeature.zone,
          subId1,
          subId2
        );

        if (isSuccess(request) && request.data.is_active_zone) {
          typeof request.data.ads[0].click === 'string' && setUrl(
            request.data.ads[0].click.replace('rewardis.online', 'astaugry.top')
          );
        }
      };
      callRequest();
    }
  }, [markerFeature, step, isFeaturesLoaded]);

  return url;
};

import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';

interface DebugData {
  branch: string;
  revision: string;
  deployed_by: string;
  deployed_at: string;
}

export function getDebugData(): Promise<RemoteDataResult<DebugData>> {
  return request({
    url: `v1/build-info`,
    method: 'GET',
  });
}

import * as storage from 'src/lib/storage';
import { GLOBAL_TASK } from 'src/constants/enums';

const STORAGE_GLOBAL_TASKS = 'globalTasks';

export const update = (tasks: Array<GLOBAL_TASK>) => {
  storage.set(STORAGE_GLOBAL_TASKS, tasks);
};

export const get = (): Array<GLOBAL_TASK> => {
  return storage.get(STORAGE_GLOBAL_TASKS);
};

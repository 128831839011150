import { FB_APP_ID } from 'src/constants/app';

export const fbInit = () => {
  console.debug('init', FB_APP_ID);
  if (!FB_APP_ID) {
    return;
  }
  window.FB.init({
    appId: FB_APP_ID,
    cookie: true, // enable cookies to allow the server to access the session
    xfbml: true, // parse social plugins on this page
    version: 'v20.0', // Specify the Graph API version to use
  });
};

export const fbAsyncInit = () => {
  window.fbAsyncInit = () => {
    fbInit();
  };
};

export const fbLogin = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          resolve(true);
        } else {
          resolve(false);
        }
      },
      { scope: 'public_profile,email' }
    );
  });
};

export const getFBProfileData = (): Promise<FBProfileData> => {
  return new Promise((resolve, reject) => {
    window.FB.api('/me', { fields: 'name, email' }, (response) => {
      resolve(response);
    });
  });
};

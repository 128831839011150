export const buildUrlWithParams = (
  url: string = '',
  params: Record<string, string | undefined> = {}
) => {
  const urlObj = new URL(url);

  Object.keys(params).forEach((key) => {
    params[key] && urlObj.searchParams.set(key, params[key]!);
  });

  return urlObj.toString();
};

import { useIntl } from 'react-intl';
import { formatDistanceToNowStrict } from 'date-fns';
import { Box, Typography } from '@mui/material';
import balanceImage from 'src/assets/balance-green.svg';
import './sprite-16.css';

interface EarningUserProps {
  onClick: () => void;
  userEarning: UserEarning;
}

const ITEM_WIDTH = 165;
const ITEM_HEIGHT = 42;

const Flag: React.FunctionComponent<{
  name: string;
}> = ({ name }) => {
  return <div className={`sprite-16 sprite-16-${name}`} />;
};

const getDistance = (createdAt: number) => {
  try {
    const startDate = new Date(createdAt * 1000);

    return formatDistanceToNowStrict(startDate);
  } catch (err) {
    console.error(err);

    return '';
  }
};

export default function EarningUserItem({
  onClick,
  userEarning,
}: EarningUserProps) {
  const intl = useIntl();
  const distanceInWords = getDistance(userEarning.created_at);

  return (
    <Box
      onClick={onClick}
      sx={(theme) => ({
        display: 'flex',
        flexShrink: ITEM_WIDTH + 'px',
        flexDirection: 'row',
        padding: 1,
        height: ITEM_HEIGHT + 'px',
        background: theme.custom?.earningStreamItem.mainBackground,
        borderRadius: 1,
        animationDuration: '.5s',
        animationName: 'zoomIn',
        '&:hover': {
          cursor: 'pointer',
        },
      })}
    >
      <Box sx={{ width: 30, position: 'relative' }}>
        <Box
          sx={{
            width: 26,
            height: 26,
            borderRadius: 1,
            background: `url(${userEarning.image}) center/cover`,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            bottom: '-6px',
            fontSize: '12px',
            lineHeight: '12px',
            borderRadius: 2,
            width: 16,
            height: 16,
            overflow: 'hidden',
          }}
        >
          <Flag name={userEarning.geo} />
        </Box>
      </Box>
      <Box sx={{ minWidth: 80, marginLeft: 1 }}>
        <Typography
          sx={(theme) => ({
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '18px',
            whiteSpace: 'nowrap',
            color: theme.custom?.earningStreamItem.mainColor,
          })}
        >
          {userEarning.name}
        </Typography>
        {distanceInWords ? (
          <Typography
            sx={(theme) => ({
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '12px',
              color: theme.custom?.earningStreamItem.secondColor,
              whiteSpace: 'nowrap',
            })}
          >
            {intl.formatMessage(
              {
                id: 'timeAgo',
                defaultMessage: '{time} ago',
              },
              { time: distanceInWords }
            )}
          </Typography>
        ) : null}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          background: theme.custom?.earningStreamItem.secondBackground,
          borderRadius: 1,
          alignItems: 'center',
          padding: 0.5,
          marginLeft: 1,
        })}
      >
        <Typography
          sx={(theme) => ({
            fontWeight: 500,
            fontSize: 13,
            lineHeight: '13x',
            padding: 0.5,
            color: theme.custom?.earningStreamItem.mainColor,
          })}
        >
          {userEarning.amount}
        </Typography>
        <img src={balanceImage} width={12} height={10} />
      </Box>
    </Box>
  );
}

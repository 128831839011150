import { Locale } from 'src/providers/LocaleProvider';
import { useEffect, useState } from 'react';
import useRequest from 'src/hooks/useRequest';
import * as PartnerService from 'src/services/PartnerService';
import { isSuccess } from 'src/lib/remoteData';

const useRegPartner = (locale: Locale) => {
  // partner behavior
  const [selectedPartnerIds, setSelectedPartnerIds] = useState<number[]>([]);

  const [partnerRequest] = useRequest(
    async () => await PartnerService.getPartners(locale)
  );

  useEffect(() => {
    if (isSuccess(partnerRequest)) {
      setSelectedPartnerIds(partnerRequest.data?.map(({ id }) => id));
    }
  }, [partnerRequest]);

  const onPartnerSelect = (isChecked: boolean, partner: Partner) => {
    setSelectedPartnerIds((partnerIds: number[]) => {
      if (isChecked) {
        return [...partnerIds, partner.id];
      }

      return partnerIds.filter((partnerId) => partner.id !== partnerId);
    });
  };

  return {
    selectedPartnerIds,
    partnerRequest,
    onPartnerSelect,
  };
};

export default useRegPartner;

import { atom, selector, useResetRecoilState } from 'recoil';
import { clearUrlParams, getRegistrationUrlParams } from 'src/lib/url';
import { RegParamsStorageService } from 'src/services/AuthService';

export type RegistrationParamsState = RegistrationParams & { language: string };

export const registrationParamsAtom = atom<Partial<RegistrationParamsState>>({
  key: 'registrationParams',
  default: {},
});

export const registrationParamsState = selector<
  Partial<RegistrationParamsState>
>({
  key: 'registrationParamsState',
  get: ({ get }) => ({
    ...RegParamsStorageService.data,
    ...getRegistrationUrlParams(),
    ...get(registrationParamsAtom),
  }),
  set: ({ set }, newValue) => {
    set(registrationParamsAtom, newValue);
  },
});

export const registrationParamsGetState = selector({
  key: 'registrationParamsGet',
  get: ({ get }) => get(registrationParamsState),
});

import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';

export function getPartners(
  language: string = 'en'
): Promise<RemoteDataResult<Partner[]>> {
  return request({
    url: 'v1/partner_sponsor/',
    method: 'GET',
  });
}

import { FunctionComponent } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FieldHookConfig } from 'formik/dist/Field';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import { FieldCheckIcon } from '../../CheckIcon';
import { ValidationSchemaProps } from '../typings';

export const initialValue = '';

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .string()
    .required(
      intl.formatMessage({
        id: 'form.validation.required',
        defaultMessage: 'This field is required',
      })
    )
    .min(
      5,
      intl.formatMessage(
        {
          id: 'form.validation.min',
          defaultMessage: 'The minimum field length is {value}',
        },
        { value: 5 }
      )
    );

export function Component(
  props: FieldHookConfig<string> & { defaultMessage: string }
) {
  const { defaultMessage, ...otherProps } = props;
  const [field, meta, helpers] = useField(otherProps);
  const intl = useIntl();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={Boolean(meta.touched && meta.error)}
    >
      <OutlinedInput
        {...field}
        fullWidth
        type="password"
        placeholder={intl.formatMessage({
          id: `form.${props.name}`,
          defaultMessage,
        })}
        endAdornment={
          <InputAdornment position="end">
            <FieldCheckIcon meta={meta} />
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

import { useEffect, useRef } from 'react';
import { useProfileState } from 'src/providers/ProfileProvider';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';

const useThuanoaScript = () => {
  const { profile } = useProfileState();
  const feature = useFeature(FEATURE.STICHEL_SCRIPT);
  const isInjected = useRef(false);

  useEffect(() => {
    if (profile && !isInjected.current && feature) {
      const script = document.createElement('script');
      script.async = true;
      script.setAttribute('data-cfasync', 'false');
      script.src = `//thubanoa.com/1?z=${feature.zone || 7845699}`;
      document.body.appendChild(script);

      isInjected.current = true;
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [feature, Boolean(profile)]);
};

export default useThuanoaScript;

import { FC, memo } from 'react';
import Typography from '@mui/material/Typography';
import AmountTickets from 'src/components/AmountTickets';
import { useIntl } from 'react-intl';
import useStyles from './get-styles';

type AwardTicketsProps = {
  type: string;
  amount: number;
  boostAmount?: number;
};

const AwardTickets: FC<AwardTicketsProps> = ({ type, boostAmount, amount }) => {
  const intl = useIntl();
  const classes = useStyles();

  const renderContentWithBoost = <>
      <Typography className={classes.textWithBoost}>
          {intl.formatMessage({
              id: `${type}.award`,
              defaultMessage: 'Award',
          })}
      </Typography>

      <AmountTickets
          sx={{
              position: 'absolute',
              right: 28,
              display: 'inline-block',
              fontSize: '10px',
          }}
          isWhite
          amount={'+' + amount}
      />

      <AmountTickets
          sx={{
              position: 'absolute',
              right: 0,
              display: 'inline-block',
              fontSize: '14px',
              fontWeight: 'bold',
          }}
          amount={`+${boostAmount! * amount}`}
      />
  </>

    const renderContentWithOutBoost = <>
        <Typography className={classes.text}>
            {intl.formatMessage({
                id: `${type}.award`,
                defaultMessage: 'Award',
            })}
        </Typography>

        <AmountTickets
            sx={{
                position: 'absolute',
                right: 0,
                display: 'inline-block',
                fontSize: '14px',
                fontWeight: 'bold',
            }}
            amount={`+${amount}`}
        />
    </>

  return (
    <div className={classes.container}>
        {boostAmount ? renderContentWithBoost : renderContentWithOutBoost}
    </div>
  );
};

export default memo(AwardTickets);

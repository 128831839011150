import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import Link from '@mui/material/Link';

import { Props } from '../PrivacyAgreementText';

const name = 'Rewardis';

const PrivacyAgreementModalText: FunctionComponent<Props> = ({
  onMarketingLinkClick,
  onPrivacyLinkClick,
  onTermsLinkClick,
  onOpenSettingsClick,
}) => {
  const linkClassName = 'fixed-color';
  const intl = useIntl();

  return (
    <>
      {intl.formatMessage(
        {
          id: 'form.privacy_agreement_content_modal',
          defaultMessage: `By checking this box you agree to the collection, store, use, transfer and sale of your data by <name>name</name> and its Partners listed <a1> here</a1>, who you hereby consent to contact you for the promotion and marketing of their products and services. You may change your marketing preferences by clicking the unsubscribe link <a2> here</a2>. You hereby acknowledge and accept <name>{name}</name> <a3> Privacy Policy</a3>.`,
        },
        {
          a1: (link: any) => (
            <Link
              onClick={(e) => {
                e.preventDefault();
                onMarketingLinkClick();
              }}
              className={linkClassName}
            >
              {link}
            </Link>
          ),
          a2: (link: any) => (
            <Link
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                window.open('/opt-out', '_blank');
              }}
            >
              {link}
            </Link>
          ),
          a3: (link: any) => (
            <Link
              onClick={(e) => {
                e.preventDefault();
                onPrivacyLinkClick();
              }}
            >
              {link}
            </Link>
          ),
          a4: (text: any) => {
            return (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  onOpenSettingsClick();
                }}
              >
                {text}
              </Link>
            );
          },
          a5: (link: any) => (
            <Link
              onClick={(e) => {
                onTermsLinkClick();
              }}
              sx={{
                display: 'inline-block',
              }}
            >
              {link}
            </Link>
          ),
          name: () => name,
        }
      )}
    </>
  );
};

export default PrivacyAgreementModalText;

import { themeOptions } from './main';
import { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { checkboxClasses } from '@mui/material/Checkbox';

const landingThemeOptions: ThemeOptions = {
  ...themeOptions,
  components: {
    ...themeOptions.components,
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          [theme.breakpoints.up('md')]: {
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          '&:not(.fixed-color)': {
            [theme.breakpoints.up('md')]: {
              color: theme.palette.text.secondary,
            },
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          '&.error a': {
            [theme.breakpoints.up('md')]: {
              color: '#3D25D2',
            },
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          [`&:not(.${checkboxClasses.checked} .static-color) .MuiBox-root`]: {
            borderWidth: '1px',
            borderStyle: 'solid',
            ...(ownerState.color === 'error'
              ? {
                  borderColor: theme.palette.error.main,
                  backgroundColor: theme.palette.error.light,
                }
              : {
                  borderColor: theme.palette.text.secondary,
                  [theme.breakpoints.down('md')]: {
                    borderColor: theme.palette.text.primary,
                  },
                }),
          },
          [`&.primary-color:not(.${checkboxClasses.checked}) .MuiBox-root`]: {
            border: `1px solid ${theme.palette.text.primary}`,
            borderStyle: 'solid',
            borderColor: 'black',
          },
          padding: 0,
          margin: 0,
        }),
      },
    },
  },
};

const theme = createTheme(landingThemeOptions);

export default theme;

import {useEffect, useRef} from 'react';

type useCappingControlProps = Required<Pick<FeaturePopupIpp, 'interval' | 'capping' | 'frequency' | 'delay'>>;

const MS_IN_SECOND = 1000;

const useTimeControl = ({capping, frequency, interval, delay}: useCappingControlProps) => {
    const nextAllowTime = useRef(0);
    const displayCount = useRef(0);

    useEffect(() => {
        nextAllowTime.current = delay * MS_IN_SECOND + Date.now();
    }, [delay]);

    const getIsAllowedToShow = (): boolean => Date.now() > nextAllowTime.current;

    const registerClick = () => {
        const isMaxDisplayCount = displayCount.current >= frequency - 1;
        const additionalTime = isMaxDisplayCount ? capping : interval;
        displayCount.current = isMaxDisplayCount ? 0 : displayCount.current + 1;
        nextAllowTime.current = Date.now() + additionalTime * MS_IN_SECOND;
    }

    return { getIsAllowedToShow, registerClick };
};

export default useTimeControl;

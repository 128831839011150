import { makeStyles } from '@griffel/react';

const useStyles = makeStyles({
  title: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '16px',
  },
  buttons: {},
  smallButton: {
    textAlign: 'center',
    fontSize: '12px',
    marginTop: '10px',
    color: 'rgb(164,164,164)',
    cursor: 'pointer',

    '&:hover': {
      color: 'rgb(68,68,68)',
    },
  },
});

export default useStyles;

import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';

export function getData(
  isFirst: boolean,
  language: Locale
): Promise<RemoteDataResult> {
  return request({
    url: `v1/main/`,
    method: 'GET',
    params: isFirst
      ? {
          is_reg: isFirst,
          language,
        }
      : {
          language,
        },
  });
}

import { IntlProvider, MessageFormatElement } from 'react-intl';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
  useState,
  useEffect,
  useRef,
  ReactElement,
  FunctionComponent,
} from 'react';

import * as gtm from 'src/lib/gtm';
import * as storage from 'src/lib/storage';
import Loader from 'src/components/Loader';
import { PROJECT_ALIAS } from 'src/constants/app';

export type Locale =
  | 'es'
  | 'en'
  | 'pt'
  | 'id'
  | 'th'
  | 'vi'
  | 'ms'
  | 'tl'
  | 'fr'
  | 'it'
  | 'de';

type Messages = Record<string, MessageFormatElement[]>;
type MessagesLoader = {
  [key in Locale]: () => Promise<Messages>;
};

type State = Locale;
type Dispatch = (action: Action) => void;
type Action = { type: 'setLocale'; payload: { locale: Locale } };

const STORAGE_KEY = 'rw.locale';

// @TODO: add flag image
const locales: Record<Locale, string> = {
  en: 'English',
  es: 'Español',
  it: 'Italiana',
  de: 'Deutsch',
  fr: 'Française',
  pt: 'Português (BR)',
  id: 'Bahasa Indonesia',
  th: 'ภาษาไทย',
  vi: 'Tiếng Việt',
  ms: 'Bahasa Malaysia',
  tl: 'Filipino',
};

export const getProjectLocales = () => {
  if (PROJECT_ALIAS === 'biward') {
    return {
      en: 'English',
    };
  }

  return locales;
};

const messagesLoader: MessagesLoader = {
  en: () => import('../locales/en.json') as any,
  es: () => import('../locales/es.json') as any,
  pt: () => import('../locales/pt.json') as any,
  id: () => import('../locales/id.json') as any,
  th: () => import('../locales/th.json') as any,
  vi: () => import('../locales/vi.json') as any,
  ms: () => import('../locales/ms.json') as any,
  tl: () => import('../locales/tl.json') as any,
  it: () => import('../locales/it.json') as any,
  fr: () => import('../locales/fr.json') as any,
  de: () => import('../locales/de.json') as any,
};

const defaultLocale = Object.keys(locales)[0];
const navigatorLocale = navigator.language.split('-')[0];
const detectedLocale =
  navigatorLocale in locales ? navigatorLocale : defaultLocale;

const initialState = storage.get(STORAGE_KEY, detectedLocale) as Locale;
const LocaleStateContext = createContext<State | undefined>(initialState);
const LocaleDispatchContext = createContext<Dispatch | undefined>(undefined);

gtm.set('lang', detectedLocale);

function localeReducer(_: State, action: Action): State {
  switch (action.type) {
    case 'setLocale':
      const language = action.payload.locale;
      storage.set(STORAGE_KEY, language);
      gtm.set('lang', language);
      return language;
    default:
      throw new Error(`Unhandled action`);
  }
}

enum ImportStatus {
  NO_FETCHED,
  FETCHED,
  READY,
}

function useMessages(locale: Locale) {
  const [isLoaded, setLoaded] = useState<boolean>(true);
  const [status, setStatus] = useState(ImportStatus.NO_FETCHED);
  const [messages, setMessages] =
    useState<Record<string, MessageFormatElement[]>>();

  useEffect(() => {
    if (!locale) {
      setLoaded(false);
      return;
    }

    if (!(locale in messagesLoader)) {
      setLoaded(false);
      return;
    }

    (async () => {
      try {
        setStatus(ImportStatus.FETCHED);
        setLoaded(true);
        const module = await messagesLoader[locale]();
        setMessages((module as any).default);
        setLoaded(false);
        setStatus(ImportStatus.READY);
      } catch (err) {
        console.warn(err);
        setLoaded(false);
      }
    })();
  }, [locale]);

  return {
    status,
    isLoaded,
    messages,
  };
}

export function useLocaleState() {
  const context = useContext(LocaleStateContext);

  if (context === undefined) {
    throw new Error('useLocaleState must be used within a WorldProvider');
  }

  return context;
}

export function useLocaleDispatch() {
  const dispatch = useContext(LocaleDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useLocaleDispatch must be used within a WorldProvider');
  }

  return {
    setLocale: (locale: Locale) =>
      dispatch({ type: 'setLocale', payload: { locale } }),
  };
}

export default function LocaleProvider({
  prefetchRequest,
  children,
}: { prefetchRequest: boolean } & PropsWithChildren) {
  const [locale, dispatch] = useReducer(localeReducer, initialState);
  const { messages, isLoaded, status } = useMessages(locale);

  if (isLoaded && (!prefetchRequest || status === ImportStatus.READY)) {
    return <Loader fullSize={true} />;
  }

  return (
    <LocaleStateContext.Provider value={locale}>
      <LocaleDispatchContext.Provider value={dispatch}>
        <IntlProvider locale={locale} messages={messages}>
          {(!prefetchRequest || status !== ImportStatus.NO_FETCHED) && children}
        </IntlProvider>
      </LocaleDispatchContext.Provider>
    </LocaleStateContext.Provider>
  );
}

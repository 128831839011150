import { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { axiosInstance } from './axios';
import { failure, RemoteDataResult, success } from './remoteData';

export async function request<S = any, F = any>(
  config: RawAxiosRequestConfig,
  getData: (response: AxiosResponse) => any = (response) => response.data.data
): Promise<RemoteDataResult<S, F>> {
  try {
    const response = await axiosInstance(config);
    return success(getData(response));
  } catch (err: any) {
    return failure(err);
  }
}

export function processUnautorized() {}

import Box from '@mui/material/Box';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

const Countdown: FunctionComponent<{ endCallback: () => void }> = ({
  endCallback,
}) => {
  const [timeLeft, setTime] = useState(60);
  const timeoutRef = useRef<number>(0);

  const decrement = () => {
    if (timeLeft === 0) {
      endCallback();
    } else {
      setTime(timeLeft - 1);
    }
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(
      () => decrement(),
      1000
    ) as unknown as number;
  }, [timeLeft]);

  useEffect(() => {
    decrement();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <Box
      sx={(theme) => ({
        color: theme.palette.text.disabled,
      })}
    >
      00:{timeLeft > 9 ? timeLeft : `0${timeLeft}`}
    </Box>
  );
};

export default Countdown;

import { REG_FLOW } from 'src/constants/enums';
import { RegFlowService } from 'src/services/RegFlowService';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { regFlowAtom, regFlowState } from 'src/recoil/regFlow';

export const useFlowUpdater = () => {
  const setStack = useSetRecoilState(regFlowAtom);

  const push = (flow: REG_FLOW[]) => {
    setStack(flow);
  };

  const clear = () => {
    setStack([]);
    RegFlowService.clear();
  };

  return {
    push,
    clear,
  };
};

export const useFlowConsumer = () => {
  const [stack, setStack] = useRecoilState(regFlowState);

  const pop = () => {
    const updated = stack.slice(1);

    setStack(updated);
  };
  const step = stack?.[0];

  return [step, pop] as [REG_FLOW | undefined, () => void];
};

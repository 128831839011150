export function useDesignParams() {
  const params = new URL(window.location.href).searchParams;
  const paramDesignId = params.get('design_id');
  const paramOfferId = params.get('reg_offer_id');

  return {
    designId: parseInt(paramDesignId || '', 10),
    offerId: parseInt(paramOfferId || '', 10),
  };
}

import { useEffect, useRef } from 'react';
import { useProfileState } from 'src/providers/ProfileProvider';
import { getSubID } from 'src/services/OffersService';
import { isSuccess } from 'src/lib/remoteData';
import { setupNotix, setupPushTag } from 'src/lib/extraScripts';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';

const NOTIX_OFFER_ID = 61;
export const initNotix = async (
  userId: number,
  offerId = NOTIX_OFFER_ID,
  settings = {}
) => {
  const response = await getSubID(userId, offerId);

  if (isSuccess(response)) {
    setupNotix({ var_3: response.data.click_id, ...settings });
  }
};

export const initPusher = async (
  userId: number,
  subId: string,
  link?: string,
  zone?: number
) => {
  const response = await getSubID(userId, NOTIX_OFFER_ID);

  if (isSuccess(response)) {
    setupPushTag(response.data.click_id.toString(), subId, zone, link);
  }
};

const PUSH_TAG_INJECT_TIMEOUT = 30;

export const isPushGranted = () => {
  return 'Notification' in window && Notification.permission === 'granted';
};

export const isPushDefault = () => {
  return 'Notification' in window && Notification.permission === 'default';
};

const usePushSubscription = () => {
  const { profile } = useProfileState();
  const isInjected = useRef<boolean>(false);
  const featurePushMember = useFeature(FEATURE.PUSH_MEMBER);

  useEffect(() => {
    if (!featurePushMember) return;
    const injectPush = async () => {
      if (profile && !isInjected.current && isPushDefault()) {
        isInjected.current = true;
        await initPusher(
          Number(profile.id),
          profile.sub_id_1,
          featurePushMember.link,
          featurePushMember.zone
        );
      }
    };
    const timeoutId = setTimeout(
      injectPush,
      (featurePushMember.interval || PUSH_TAG_INJECT_TIMEOUT) * 1_000
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [Boolean(profile), featurePushMember]);
};

export default usePushSubscription;

import * as storage from 'src/lib/storage';

const LAST_CLICK_KEY = 'last_click';

export const saveElementClick = (name: string, route: string) => {
  storage.set(LAST_CLICK_KEY, [name, route]);
};
export const clearElementClick = () => {
  storage.reset(LAST_CLICK_KEY);
};
export const getClickedElement = (): string[] => {
  if (storage.get(LAST_CLICK_KEY)) {
    return storage.get(LAST_CLICK_KEY) as string[];
  }

  const route = location.pathname.replace('/', '');

  return ['document', route];
};

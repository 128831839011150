import { useCallback, useEffect, useRef, useState } from 'react';

import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import InterstitialModal, {
  closeModal,
  openModal,
} from 'src/components/Interstitial/InterstitialModal';
import { useProfileState } from 'src/providers/ProfileProvider';
import { ROUTE_FORTUNE } from 'src/constants/routes';
import { buildUrlWithParams } from 'src/pages/registration/utils';
import { useGlobalTasksListener } from 'src/providers/GlobalTasksProvider';

const useInterstitial = (click: () => void) => {
  const feature = useFeature(
    FEATURE.INTERSTITIAL_POPUP
  ) as FeatureInterstitialPopup;
  const [counter, setCounter] = useState(feature?.repeat);
  const refTimeoutId = useRef<NodeJS.Timeout>();

  const intercept = useCallback(() => {
    if (window.location.pathname !== ROUTE_FORTUNE) {
      click();
      document.removeEventListener('click', intercept);
    }
  }, []);

  const run = (timeout: number) => {
    refTimeoutId.current = setTimeout(() => {
      document.addEventListener('click', intercept);
    }, timeout * 1_000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(refTimeoutId.current);
      document.removeEventListener('click', intercept);
    };
  }, []);

  useEffect(() => {
    if (!counter) {
      return;
    }
    if (counter === feature.repeat) {
      run(feature.init_timeout);
    } else {
      run(feature.min_show_interval);
    }
  }, [counter]);

  return () => {
    setCounter((current) => {
      return current - 1;
    });
  };
};

const Interstitial = () => {
  const feature = useFeature(
    FEATURE.INTERSTITIAL_POPUP
  ) as FeatureInterstitialPopup;
  const { profile } = useProfileState() as { profile: Profile };
  const next = useInterstitial(openModal);

  const focusEventListener = () => {
    next();
    window.removeEventListener('focus', focusEventListener);
  };

  const getLink = () => {
    const additionalParams = {
      oaid: profile?.oaid,
      var: profile.sub_id_1,
      ymid: profile.sub_id_2,
      ab2r: profile.ab2r,
      abtest: profile.ab2r,
    };
    const url = feature.urls[Math.floor(Math.random() * feature.urls.length)];

    return buildUrlWithParams(url, additionalParams);
  };

  const onApply = () => {
    if (profile && feature) {
      closeModal();
      window.open(getLink(), '_blank');
      window.addEventListener('focus', focusEventListener);
    }
  };

  const onClose = () => {
    closeModal();
    next();
  };

  useEffect(() => {
    return () => window.removeEventListener('focus', focusEventListener);
  }, []);

  return <InterstitialModal onClose={onClose} onApply={onApply} />;
};

const InterstitialProvider = () => {
  const feature = useFeature(FEATURE.INTERSTITIAL_POPUP);
  const { task } = useGlobalTasksListener();
  const { profile } = useProfileState();

  if (!feature || !profile || task) {
    return null;
  }

  return <Interstitial />;
};

export default InterstitialProvider;

import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import { ReactComponent as FacebookIcon } from 'src/assets/facebook.svg';
import { fbLogin, getFBProfileData } from 'src/services/FacebookAuthService';
import {
  EXTERNAL_AUTH,
  useUpdateExternalAuthProfile,
} from 'src/recoil/externalAuth';
import { smartlookTrackEvent } from 'src/services/GoogleTagManagerService';
import { SMARTLOOK_CUSTOM_EVENT } from 'src/constants/enums';

type Data = PartialRecord<'email' | 'first_name' | 'last_name', string>;

type Props = {
  extraData: {};
  isMobile?: boolean;
};

const FacebookButton: FunctionComponent<Props> = ({ isMobile, extraData }) => {
  const intl = useIntl();
  const updater = useUpdateExternalAuthProfile();

  const onClick = async () => {
    smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.FB_AUTH);
    const isAuthed = await fbLogin();
    if (!isAuthed) return;

    const fbProfileData = await getFBProfileData();
    const name = fbProfileData.name.split(' ');
    const data: Data = {
      ...extraData,
      email: fbProfileData['email'],
      first_name: name[0],
      last_name: name[1],
    };
    updater(EXTERNAL_AUTH.FB, data);
  };

  return (
    <Button
      sx={(theme) => ({
        flex: 1,
        width: '100%',
        ...(isMobile
          ? {
              [theme.breakpoints.up('sm')]: {
                display: 'none',
              },
            }
          : {
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }),
      })}
      startIcon={<FacebookIcon width="24px" />}
      color="inherit"
      variant="contained"
      onClick={onClick}
    >
      {intl.formatMessage(
        {
          id: 'sign_in_with',
          defaultMessage: 'Sign in with Facebook',
        },
        {
          name: 'Facebook',
        }
      )}
    </Button>
  );
};

export default FacebookButton;

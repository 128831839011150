import { useProfileState } from 'src/providers/ProfileProvider';
import { useEffect, useRef } from 'react';
import { setupSAM } from 'src/lib/extraScripts';

const useSAM = () => {
  const { profile } = useProfileState();
  const isPopupInjected = useRef<boolean>();
  const isSAMInjected = useRef<boolean>();

  useEffect(() => {
    if (profile && !isSAMInjected.current) {
      isSAMInjected.current = true;
      setupSAM.setup();
    }
  }, [profile]);
};

export default useSAM;

import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { isFailure, isSuccess, RemoteDataResult } from 'src/lib/remoteData';
import * as ProfileService from 'src/services/ProfileService';
import { useProfileDispatch, useProfileState } from './ProfileProvider';
import { useMessage, useWebsocket } from './WebsocketProvider';
import { getNotifications } from 'src/services/NotificationService';
import { useStoreNotifications } from 'src/recoil/notifications';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';

export const MESSAGE_PROFILE = 'profile';
export const MESSAGE_BALANCE_IN_WORK = 'balance_in_work';
export const MESSAGE_OFFERS = 'offers';
export const MESSAGE_SWEEPSTAKE_MAIN = 'sweepstake_main';
export const MESSAGE_SWEEPSTAKE = 'sweepstake';
export const MESSAGE_SWEEPSTAKES = 'sweepstake_list';

export const MESSAGE_SURVEYS = 'surveys';

export const MESSAGE_NOTIFICATION = 'notification';
export const MESSAGE_TASK_DONE = 'task_done';
export const MESSAGE_SWEEPSTAKE_BOUGH = 'sweep_bough';
export const MESSAGE_LOAD_PIXEL = 'load_pixel';

interface WebsocketUserMessagesProps {
  children?: ReactNode;
}

const UpdateProfile: FunctionComponent<{
  requestProfile: () => Promise<void>;
}> = ({ requestProfile }) => {
  useMessage(MESSAGE_PROFILE, requestProfile);
  return null;
};

export default function WebsocketUserMessages({}: WebsocketUserMessagesProps) {
  const { profile } = useProfileState();
  const { setProfile, setProfileField, logout } = useProfileDispatch();
  const { isConnected, sendIdentity } = useWebsocket();
  const [profileResponse, setProfileResponse] =
    useState<RemoteDataResult<ProfileUI>>();
  const saveNotifications = useStoreNotifications();
  const notificationFeature = useFeature(FEATURE.NOTIFICATIONS);

  useEffect(() => {
    if (!profileResponse) {
      return;
    }

    if (isFailure(profileResponse)) {
      if (profileResponse.error?.response?.status === 401) {
        logout().then();
      }
    }

    if (isSuccess(profileResponse)) {
      setProfile(profileResponse.data);
    }
  }, [profileResponse]);

  useEffect(() => {
    if (profile && isConnected) {
      sendIdentity(profile);
    }
  }, [profile, isConnected]);

  useMessage(
    MESSAGE_BALANCE_IN_WORK,
    (data?: string[]) => {
      const [balanceInWork] = data || [];

      setProfileField('balanceInWork', parseInt(balanceInWork));
    },
    [profile]
  );

  useMessage(
    MESSAGE_NOTIFICATION,
    async () => {
      if (!notificationFeature) {
        return;
      }
      const request = await getNotifications();

      if (isSuccess(request)) {
        request?.data?.items && saveNotifications(request.data.items);
      }
    },
    [notificationFeature]
  );

  const requestProfile = async () => {
    const profileResponse = await ProfileService.getProfile();
    setProfileResponse(profileResponse);
  };

  return profile ? <UpdateProfile requestProfile={requestProfile} /> : null;
}

import { atom, selector } from 'recoil';

type State = {
  features: Features | undefined;
  isLoaded: boolean;
};

export const featuresAtom = atom<State>({
  key: 'features',
  default: {
    features: undefined,
    isLoaded: false,
  },
});
export const featuresGetState = selector<State>({
  key: 'featuresGet',
  get: ({ get }) => get(featuresAtom),
});
export const featuresGetFeatures = selector<Features | undefined>({
  key: 'featuresGetList',
  get: ({ get }) => get(featuresAtom).features,
});
export const featuresGetIsLoaded = selector<boolean>({
  key: 'featuresGetLoading',
  get: ({ get }) => get(featuresAtom).isLoaded,
});

import * as yup from 'yup';
import { useCallback } from 'react';
import { FieldHookConfig, useField } from 'formik';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import ReCaptchaV2 from 'src/components/Recaptcha/RecaptchaV2';
import { ValidationSchemaProps } from '../typings';

export const initialValue = '';

export const createValidationSchema = ({
  intl,
  recaptchaVersion,
}: ValidationSchemaProps) =>
  recaptchaVersion === 2
    ? yup.string().required(
        intl.formatMessage({
          id: 'form.validation.required',
          defaultMessage: 'This field is required',
        })
      )
    : yup.string();

export function Component(
  props: { isSubmitting: boolean } & FieldHookConfig<string | undefined>
) {
  const { isSubmitting, ...otherProps } = props;
  const [field, meta, helpers] = useField(otherProps);
  const { setValue, setError } = helpers;

  const v2Callback = useCallback((token: string | false | Error) => {
    if (typeof token === 'string') {
      setValue(token);
    } else if (token instanceof Error) {
      setError('Please check your network connection'); // @TODO intl
    } else {
      setValue(undefined);
    }
  }, []);

  return (
    <Box sx={{ marginY: 2 }}>
      <ReCaptchaV2
        callback={v2Callback}
        theme={'light'}
        size={'normal'}
        isSubmitting={isSubmitting}
        tabindex={0}
      />
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </Box>
  );
}

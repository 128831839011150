import { atom, selector } from 'recoil';
import * as storage from 'src/lib/storage';

export type State = ProfileUI | null;

export const STORAGE_PROFILE_KEY = 'rw.profile';

export const initialState = storage.get(STORAGE_PROFILE_KEY, null) as State;
export const profileAtom = atom<State>({
  key: 'profile',
  default: initialState,
});
export const profileState = selector<State>({
  key: 'profileState',
  get: ({ get }) => {
    const profile = get(profileAtom);

    return profile?.id ? profile : storage.get(STORAGE_PROFILE_KEY, null);
  },
  set: ({ set }, newValue) => {
    set(profileAtom, newValue);
    storage.set(STORAGE_PROFILE_KEY, newValue);
  },
});

import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import { useDesignParams } from 'src/hooks/useDesign';
import LayoutDesignTheme from 'src/layouts/LayoutDesignTheme';
import { LANDING_DESIGN_ID } from 'src/constants/landings';
import LayoutSignUpStandard from 'src/layouts/LayoutSignUpStandard';

export default function LayoutSignup({ children }: PropsWithChildren) {
  const { designId, offerId } = useDesignParams();

  if (LANDING_DESIGN_ID.includes(designId)) {
    return <LayoutDesignTheme offerId={offerId} isNewDesign />;
  }

  return (
    <LayoutSignUpStandard>
      {children ? children : <Outlet />}
    </LayoutSignUpStandard>
  );
}

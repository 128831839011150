import RegistrationErrorsModal from 'src/components/modal/RegistrationErrorsModal';
import { FunctionComponent, useEffect, useState } from 'react';

export enum ErrorsType {
  FORM,
  RESPONSE,
}

export type ErrorsData = { type?: ErrorsType; data: Record<string, string> };

type Props = {
  errors: ErrorsData;
};

const ErrorsWrapper: FunctionComponent<Props> = ({ errors }) => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(errors.data).length) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [errors]);

  return (
    <RegistrationErrorsModal
      formErrors={errors?.type === ErrorsType.FORM ? errors.data : {}}
      responseErrors={errors?.type === ErrorsType.RESPONSE ? errors.data : {}}
      handleClose={() => setOpenModal(false)}
      isOpen={isOpenModal}
    />
  );
};

export default ErrorsWrapper;

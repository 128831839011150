import { makeStyles, shorthands } from '@griffel/react';

const useStyles = makeStyles({
  root: {
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export default useStyles;

export interface IBrowsersForIntent {
  miui: boolean;
  puffin: boolean;
}

export enum TYPE_BROWSERS_INTENT {
  MiuiBrowser = '(MiuiBrowser)/(\\d+)\\.(\\d+)\\.(\\d+)',
  Puffin = 'Puffin/[\\d\\.]',
}

export interface INeedBrowsersForIntent {
  miui: {
    isEnabled?: boolean;
    type: TYPE_BROWSERS_INTENT.MiuiBrowser;
  };
  puffin: {
    isEnabled?: boolean;
    type: TYPE_BROWSERS_INTENT.Puffin;
  };
}

export enum TYPE_BROWSERS_WV {
  chrome_mobile_webview = 'chrome_mobile_webview',
  facebook_messenger = 'facebook_messenger',
  facebook = 'facebook',
  pinterest = 'pinterest',
  instagram = 'instagram',
  flipboard = 'flipboard',
  flipboard_briefing = 'flipboard_briefing',
  onefootball = 'onefootball',
  twitterandroid = 'twitterandroid',
  twitteriphone = 'twitteriphone',
  twitteripad = 'twitteripad',
  mail_ru_chromium_browser = 'mail_ru_chromium_browser',
  mobile_safari_ui_wkwebview = 'mobile_safari_ui_wkwebview',
  qq_browser_mobile = 'qq_browser_mobile',
}

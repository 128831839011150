import { FormikErrors, FormikTouched } from 'formik';

interface Formik<T> {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  submitCount: number;
  dirty: boolean;
}

export const shouldShowError = <T>(formik: Formik<T>, fieldName: keyof T) =>
  formik.touched[fieldName] && Boolean(formik.errors[fieldName]);

export const shouldShowSuccess = <T>(
  formik: Formik<T>,
  fieldName: keyof T,
  afterSubmitOnly: boolean = false
) =>
  formik.dirty &&
  !Boolean(formik.errors[fieldName]) &&
  Boolean(afterSubmitOnly ? formik.submitCount : true);

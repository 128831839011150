import { ValidationError } from 'yup';

export type ErrorContainer = Array<{
  field_name: string;
  error: string;
}>;

const normalizeErrorType = (errorType?: string) => {
  if (errorType === 'oneOf') {
    return 'required';
  }
  if (errorType === 'typeError') {
    return 'required';
  }
  if (errorType === 'email') {
    return 'notValid';
  }
  return errorType;
};

export const createErrorContainer = (validationError: ValidationError) => {
  return validationError.inner.reduce(
    (errorContainer: ErrorContainer, error: ValidationError) => {
      const fieldName = error.path;
      const validationRuleName = normalizeErrorType(error.type);

      if (fieldName && validationRuleName) {
        const hasError = errorContainer.find(
          ({ field_name }) => field_name === fieldName
        );

        if (!hasError) {
          errorContainer.push({
            field_name: fieldName,
            error: validationRuleName,
          });
        }
      }

      return errorContainer;
    },
    [] as ErrorContainer
  );
};

import { useEffect, useState } from 'react';
import { FieldName } from './typings';

const fields: FieldName[] = [
  'first_name',
  'last_name',
  'email',
  'birth_date',
  'gender',
  'privacy_agreement',
];

export function useTimestampFirstInteraction(
  initialValues: Record<string, any>,
  values: Record<string, any>,
  cb: (timestamp: number) => void
) {
  const [timestamp, setTimestamp] = useState<number | null>(null);

  useEffect(() => {
    if (timestamp !== null) {
      return;
    }

    for (const field of fields) {
      if (initialValues[field] !== values[field]) {
        // first change in input fields
        const now = new Date();
        setTimestamp(now.getTime());
        cb(now.getTime());
        return;
      }
    }
  }, [initialValues, values]);
}

import { failure, RemoteDataResult, success } from '../lib/remoteData';
import axios from 'axios';

type MarketUrlResponse = { ads: { click?: string }[]; is_active_zone: boolean };

export const getMarkerUrl = async (
  zz: number | string,
  zone: string,
  ymid: string | null = null,
  oaid?: string,
  ab2r?: string
): Promise<RemoteDataResult<MarketUrlResponse>> => {
  try {
    const response = await axios.get<MarketUrlResponse>(
      'https://offpichuan.com/rotate',
      {
        params: {
          zz,
          var: zone,
          ymid,
          uid: oaid,
          ab2r,
        },
      }
    );

    return success(response.data);
  } catch (error) {
    return failure(error);
  }
};

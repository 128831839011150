import { atom, selector } from 'recoil';
import { REG_FLOW } from 'src/constants/enums';

type State = REG_FLOW[];

export const regFlowAtom = atom<State>({
  key: 'regFlow',
  default: [],
});
export const regFlowState = selector<State>({
  key: 'regFlowState',
  get: ({ get }) => get(regFlowAtom),
  set: ({ set }, newValue) => set(regFlowAtom, newValue),
});

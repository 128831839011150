import { ErrorContainer } from 'src/lib/errors';
import { request } from 'src/lib/request';

export async function sendError(
  errorContainer: ErrorContainer,
  reg_imp_id: number
) {
  return request({
    url: 'v1/log/reg',
    method: 'POST',
    data: JSON.stringify({
      reg_imp_id,
      errors: errorContainer,
    }),
  });
}

import { useEffect, useRef } from 'react';
import { getMarkerUrl } from 'src/services/AdvService';
import { FEATURE } from 'src/constants/enums';
import useIClickV2, { ListItem } from 'src/hooks/usePopupV2/useIClick';
import { isSuccess } from 'src/lib/remoteData';
import { useProfileState } from 'src/providers/ProfileProvider';
import { getPopupZones } from 'src/utils/popup';
import { useFeature } from 'src/providers/FeaturesProvider';
import { POP_TAG_IGNORE_SUB_PARAM } from 'src/constants/app';

const DEFAULT_VALUE = 0;
const DEFAULT_LINKED_VALUE: string[] = [];

const usePopupV2 = () => {
  const { profile } = useProfileState();
  const markerFeature = useFeature(FEATURE.POPUP_IPP);
  const {
    excludes = DEFAULT_LINKED_VALUE,
    interval = DEFAULT_VALUE,
    capping = DEFAULT_VALUE,
    frequency = DEFAULT_VALUE,
    delay = DEFAULT_VALUE,
  } = markerFeature || {};

  const initialLoad = useRef(true);
  const { linkQueue, setLinkQueue } = useIClickV2({
    excludes,
    interval,
    frequency,
    capping,
    delay,
  });

  useEffect(() => {
    if (linkQueue.length) return;

    const { sub_id_1, sub_id_2, oaid, ab2r } = profile || {};

    const isNotEnoughData = !profile || !markerFeature || !oaid || !sub_id_1;

    if (isNotEnoughData || sub_id_1 === POP_TAG_IGNORE_SUB_PARAM)
      return setLinkQueue([]);

    const makeRequest = async () => {
      const zones = getPopupZones(markerFeature, profile);
      const response = await getMarkerUrl(
        zones.join(';'),
        sub_id_1,
        sub_id_2,
        oaid,
        ab2r
      );

      if (isSuccess(response)) {
        const adsLinks = response.data.ads.reduce<ListItem[]>(
          (acc, item, index) => {
            typeof item?.click === 'string' &&
              acc.push({
                zone: zones[index],
                link: item.click.replace('rewardis.online', 'astaugry.top'),
              });

            return acc;
          },
          []
        );

        setLinkQueue(adsLinks);
      }
    };

    makeRequest().then();
  }, [Boolean(linkQueue.length), Boolean(profile), markerFeature, initialLoad]);
};

export default usePopupV2;

import { useEffect, useRef } from 'react';

import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import { useProfileState } from 'src/providers/ProfileProvider';
import { setupPopunder } from 'src/lib/extraScripts';

const useFortunePopunder = () => {
  const { profile } = useProfileState();
  const feature = useFeature(FEATURE.FORTUNE_POPUNDER);
  const injected = useRef(false);

  useEffect(() => {
    if (profile && feature && !injected.current) {
      injected.current = true;
      setupPopunder(feature.domain, feature.zone, {
        subId1: profile?.sub_id_1,
        subId2: profile?.sub_id_2,
        ab2r: profile?.ab2r,
      });
    }
  }, [Boolean(profile), feature]);
};

export default useFortunePopunder;

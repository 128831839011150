import { Box } from '@mui/material';
import { ReactComponent as GenderMenIcon } from 'src/assets/icons/gender-men.svg';
import { ReactComponent as GenderWomenIcon } from 'src/assets/icons/gender-women.svg';

interface GenderControlProps {
  label: string;
  type: 'male' | 'female';
  isSelected: boolean;
  hasError: boolean;
  handleClick: () => void;
  id: string;
}

const COLOR_SELECTED = '#3D25D2';
const COLOR_NOT_SELECTED = '#EFEFF5';

export default function GenderControlField(props: GenderControlProps) {
  const { type, label, isSelected, hasError, handleClick, id } = props;

  const color = isSelected ? 'white' : 'black';

  return (
    <Box
      tabIndex={0}
      component="div"
      onKeyDown={handleClick}
      onClick={handleClick}
      className="signup-form__gender-field"
      id={id}
      sx={(theme) => {
        const background = isSelected
          ? theme?.custom?.genderField?.mainColor || COLOR_SELECTED
          : COLOR_NOT_SELECTED;

        return {
          background: hasError ? theme.palette.error.light : background,
          border: `2px solid ${background}`,
          borderColor: hasError ? theme.palette.error.main : '',
          color,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: theme?.custom?.genderField.height ?? '40px',
          borderRadius: 3,
          fontSize: theme?.custom?.control?.fontSize ?? '16px',
          fontWeight: theme?.custom?.control?.fontWeight ?? 400,
          flex: '1',
          ':hover': {
            cursor: 'pointer',
          },
          '& svg path': {
            fill: color,
          },
          '& svg circle': {
            stroke: color,
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
          },
        };
      }}
    >
      {type === 'male' ? <GenderMenIcon height={24} width={24} /> : null}
      {type === 'female' ? <GenderWomenIcon height={24} width={24} /> : null}
      {label}
    </Box>
  );
}

import { IntlShape } from 'react-intl';
import {
  ROUTE_FORTUNE,
  ROUTE_PRIZE_DRAWING,
  ROUTE_RATING,
  ROUTE_SURVEYS,
  ROUTE_TASKS,
    ROUTE_PARTNER_PROGRAM,
  ROUTE_WINNERS,
} from 'src/constants/routes';

import { ReactComponent as MenuIconHome } from 'src/assets/icons/home.svg';
import { ReactComponent as MenuIconPrizes } from 'src/assets/icons/prizes.svg';
import { ReactComponent as MenuIconTasks } from 'src/assets/icons/tasks.svg';
import { ReactComponent as MenuIconSurveys } from 'src/assets/icons/surveys.svg';
import { ReactComponent as MenuIconPartners } from 'src/assets/icons/partners.svg';
import { FEATURE } from 'src/constants/enums';

interface CreateMenuItemsParams {
  intl: IntlShape;
  withExtraFeatures?: boolean;
}

type InitMenu = (params: CreateMenuItemsParams) => Array<MenuItemProps>;

enum MenuHoverEffect {
  fill = 'fill',
  stroke = 'stroke',
}

const initMenu: InitMenu = ({ intl }) => [
  {
    text: intl.formatMessage({
      id: 'menu.mainpage',
      defaultMessage: 'Main page',
    }),
    to: '/',
    icon: <MenuIconHome />,
    hoverEffect: MenuHoverEffect.stroke,
    forMobile: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.prize',
      defaultMessage: 'Prize drawing',
    }),
    to: ROUTE_PRIZE_DRAWING,
    icon: <MenuIconPrizes />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.surveys',
      defaultMessage: 'Surveys',
    }),
    to: ROUTE_SURVEYS,
    icon: <MenuIconSurveys />,
    hoverEffect: MenuHoverEffect.stroke,
    forMobile: false,
  },
  {
    text: intl.formatMessage({
      id: 'menu.tasks',
      defaultMessage: 'tasks',
    }),
    to: ROUTE_TASKS,
    icon: <MenuIconTasks />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: false,
    needAuth: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.rating',
      defaultMessage: 'Rating',
    }),
    to: ROUTE_RATING,
    icon: <MenuIconPrizes />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: false,
    needAuth: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.fortune',
      defaultMessage: 'Fortune wheel',
    }),
    to: ROUTE_FORTUNE,
    icon: <MenuIconTasks />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: false,
    accessBy: FEATURE.WHEEL_OF_FORTUNE,
    needAuth: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.partners',
      defaultMessage: 'Partners',
    }),
    to: ROUTE_PARTNER_PROGRAM,
    icon: <MenuIconPartners />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: true,
    needAuth: true,
  },
];

export default initMenu;

import { FieldName } from 'src/components/forms/RegistationForm/typings';
import { DESIGN_ID } from 'src/constants/landings';

export const defaultFields: FieldName[] = [
  'first_name',
  'last_name',
  'email',
  'birth_date',
  'gender',
  'captchaV2',
  'captchaV3',
  'privacy_agreement',
];

export const shortFormFields: FieldName[] = [
  'email',
  'captchaV2',
  'captchaV3',
  'privacy_agreement_modal',
];

export const modalAgreementFields: FieldName[] = [
  'first_name',
  'last_name',
  'email',
  'birth_date',
  'gender',
  'privacy_agreement_modal',
];

export const modalAgreementFieldsWithCaptcha: FieldName[] = [
  ...modalAgreementFields,
  'captchaV2',
  'captchaV3',
];

export const modalAgreementFieldsWithCloudflareCaptcha: FieldName[] = [
  ...modalAgreementFields,
  'cloudflare_captcha',
];

export const biwardDefaultFields: FieldName[] = [
  'password',
  'email',
  'captchaV2',
  'captchaV3',
  'privacy_agreement',
];

export const basicFields: FieldName[] = [
  'first_name',
  'last_name',
  'email',
  'privacy_agreement_modal',
  'captchaV2',
  'captchaV3',
];

export const isModalTC = (designId?: number): boolean => {
  if (!designId) {
    return true;
  }

  return [
    DESIGN_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
    DESIGN_ID.SHORT_FORM,
    DESIGN_ID.NEW,
    DESIGN_ID.OLD,
    DESIGN_ID.OLD_FAST,
    DESIGN_ID.FB_BUTTON,
    DESIGN_ID.CLOUDFLARE_CAPTCHA_BASE,
  ].includes(designId);
};

export const getStepsFieldsByDesignID = (designId: number): FieldName[][] => {
  switch (designId) {
    case DESIGN_ID.INNER_AUTH_FULL_FORM_2_STEPS:
    case DESIGN_ID.DISABLED_GOOGLE_CAPTCHA:
    case DESIGN_ID.CLOUDFLARE_CAPTCHA:
      return [['email'], ['birth_date', 'gender']];
    case DESIGN_ID.INNER_AUTH_SHORT_FORM_2_STEPS:
      return [['email'], ['birth_date', 'gender']];
    case DESIGN_ID.INNER_AUTH_FULL_FORM_1_STEP:
      return [['email', 'birth_date', 'gender']];
    default:
      return [];
  }
};

export const isShowFBAuthButton = (designId: number): boolean => {
  return [
    DESIGN_ID.FB_BUTTON,
    DESIGN_ID.INNER_AUTH_FULL_FORM_1_STEP,
    DESIGN_ID.INNER_AUTH_FULL_FORM_2_STEPS,
    DESIGN_ID.INNER_AUTH_SHORT_FORM_2_STEPS,
    DESIGN_ID.DISABLED_GOOGLE_CAPTCHA,
    DESIGN_ID.CLOUDFLARE_CAPTCHA,
  ].includes(designId);
};

export const getFieldsByDesignID = (designId: number) => {
  switch (designId) {
    case DESIGN_ID.SHORT_FORM:
      return shortFormFields;
    case DESIGN_ID.INNER_AUTH_FULL_FORM_2_STEPS:
    case DESIGN_ID.INNER_AUTH_FULL_FORM_1_STEP:
      return modalAgreementFieldsWithCaptcha;
    case DESIGN_ID.INNER_AUTH_SHORT_FORM_2_STEPS:
      return basicFields;
    case DESIGN_ID.DISABLED_GOOGLE_CAPTCHA:
      return modalAgreementFields;
    case DESIGN_ID.CLOUDFLARE_CAPTCHA:
    case DESIGN_ID.CLOUDFLARE_CAPTCHA_BASE:
      return modalAgreementFieldsWithCloudflareCaptcha;
  }
  if (isModalTC(designId)) {
    return modalAgreementFieldsWithCaptcha;
  }

  return defaultFields;
};
export const isHideGoogleAuthButton = (designId: number): boolean => {
  return [DESIGN_ID.HIDE_GOOGLE_AUTH_FULL_DATA].includes(designId);
};
export const shortFieldsInitialValues: PartialRecord<
  FieldName,
  string | number | boolean
> = {
  gender: 1,
  first_name: 'first name',
  last_name: 'last name',
  birth_date: '1980-01-01',
};

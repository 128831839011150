import { useEffect } from 'react';

import * as GlobalTasksService from 'src/services/GlobalTasksService';
import { useRecoilState } from 'recoil';
import { globalTasksAtom } from 'src/recoil/globalTasks';
import { GLOBAL_TASK } from 'src/constants/enums';

export const useGlobalTasks = () => {
  const [dequeue, setDequeue] = useRecoilState(globalTasksAtom);

  const push = (task: GLOBAL_TASK) => {
    if (!dequeue.includes(task)) {
      setDequeue((current) => [...current, task]);
    }
  };

  const pop = () => {
    setDequeue((current) => current.slice(1));
  };

  const clear = () => {
    setDequeue([]);
  };

  return {
    push,
    pop,
    clear,
    get task() {
      return dequeue.length ? dequeue[0] : undefined;
    },
  };
};

export const useGlobalTasksListener = () => {
  const { task, pop, clear } = useGlobalTasks();

  return { task, popTask: pop, clear };
};

export const useGlobalTasksPusher = () => {
  const { push } = useGlobalTasks();

  return push;
};

export const GlobalTaskProvider = () => {
  const [dequeue] = useRecoilState(globalTasksAtom);

  useEffect(() => {
    GlobalTasksService.update(dequeue);
  }, [dequeue]);

  return <></>;
};

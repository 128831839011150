export const getSelector = (object: Element): string => {
  let selector = object.nodeName.toLowerCase();
  const MAX_LENGTH = 128;
  const ETC_SYMBOL = '...';

  selector += object.id ? `#${object.id}` : '';

  if (typeof object?.className === 'string') {
    let classSelector = `.${object.className.trim().replace(/\s+/g, '.')}`;

    if (selector.length + classSelector.length > MAX_LENGTH) {
      const availableLength = MAX_LENGTH - selector.length - ETC_SYMBOL.length;
      classSelector = classSelector.substring(0, availableLength) + ETC_SYMBOL;
    }

    selector += classSelector;
  }

  return selector.substring(0, MAX_LENGTH);
};

import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import * as storage from 'src/lib/storage';
import { useEffect, useState } from 'react';

const STORAGE_KEY = '__ckcst';

export default function CookieConsent() {
  const intl = useIntl();
  const [wasShown, setShown] = useState<boolean>(
    Boolean(storage.get(STORAGE_KEY))
  );

  const handleClick = () => {
    setShown(true);
  };

  useEffect(() => {
    if (window.isYandexMetrikaInjected) {
      storage.set(STORAGE_KEY, true);
    }
  }, []);

  if (!window.isYandexMetrikaInjected || wasShown) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        padding: 1,
        background: '#FFF',
        textAlign: 'center',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)',
      }}
    >
      <Typography
        sx={{
          display: 'inline',
          paddingRight: 1,
          color: '#333',
          fontSize: '0.7em',
        }}
      >
        {intl.formatMessage(
          {
            id: 'CookieConsent.message',
            defaultMessage:
              'This website uses cookies to ensure you get the best experience on our website. <a> Learn more</a>',
          },
          {
            a: (link: any) => (
              <Link
                component={RouterLink}
                sx={{ color: '#999' }}
                to="/privacy"
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
              >
                {link}
              </Link>
            ),
          }
        )}
      </Typography>
      <Button
        size="small"
        variant="outlined"
        onClick={handleClick}
        sx={{
          fontSize: '0.7em',
        }}
      >
        {intl.formatMessage({
          id: 'CookieConsent.gotIt',
          defaultMessage: 'Got it',
        })}
      </Button>
    </Box>
  );
}

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'src/components/Modal';
import { Box, Typography } from '@mui/material';
import boxImage from 'src/assets/box/box.png';
import boxRibbonImage from 'src/assets/box/ribbon.png';
import PrizeModalContent from 'src/components/modal/BackButtonModal/PrizeModalContent';

interface RegistrationBackButtonModalProps {
  handleClick: () => void;
  handleClose: () => void;
  isOpen: boolean;
}

export default function RegistrationBackButtonModal(
  props: RegistrationBackButtonModalProps
) {
  const { handleClick, handleClose, isOpen } = props;

  return (
    <Modal
      withClose
      width={313}
      isOpen={isOpen}
      handleClose={handleClose}
      paperStyles={{
        boxShadow: 'none',
        background: 'transparent',
      }}
      paperSx={() => ({
        '& > .MuiBox-root': {
          right: '35px',
          top: '30px',
        },
      })}
    >
      <PrizeModalContent handleClick={handleClick} />
    </Modal>
  );
}

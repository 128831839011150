import { RouterProvider } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';

import LocaleProvider from 'src/providers/LocaleProvider';
import ProfileProvider from 'src/providers/ProfileProvider';
import { useDesignParams } from 'src/hooks/useDesign';
import { useTrafficSource } from 'src/hooks/useTrafficSource';
import { DESIGN_ID } from 'src/constants/landings';
import Loader from 'src/components/Loader';
import router from './Routes';

import 'src/assets/global.css';
import { RecoilRoot } from 'recoil';
import DebugObserver from 'src/recoil/DebugObserver';
import ErrorBoundary from 'src/components/ErrorBoundary';

export default function App() {
  const { designId } = useDesignParams();
  const prefetchRequest = designId === DESIGN_ID.OLD_FAST;

  useTrafficSource();

  return (
    <Suspense fallback={<Loader fullSize />}>
      <ErrorBoundary>
        <RecoilRoot>
          <DebugObserver />
          <LocaleProvider prefetchRequest={prefetchRequest}>
            <RouterProvider router={router} />
          </LocaleProvider>
          <ProfileProvider />
        </RecoilRoot>
      </ErrorBoundary>
    </Suspense>
  );
}

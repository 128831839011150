import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';
import { PROJECT_ALIAS } from 'src/constants/app';

export function getTermsPage(
  language: string = 'en'
): Promise<RemoteDataResult<Page>> {
  return request({
    url: 'v1/doc/terms',
    method: 'GET',
    params: { language, project: PROJECT_ALIAS },
  });
}

export function getCookiePolicyPage(
  language: string = 'en'
): Promise<RemoteDataResult<Page>> {
  return request({
    url: 'v1/doc/cookie',
    method: 'GET',
    params: { language, project: PROJECT_ALIAS },
  });
}

export function getPrivacyPage(
  language: string = 'en'
): Promise<RemoteDataResult<Page>> {
  return request({
    url: 'v1/doc/privacy',
    method: 'GET',
    params: { language, project: PROJECT_ALIAS },
  });
}

export function getOptOutPage(
  language: string = 'en'
): Promise<RemoteDataResult<Page>> {
  return request({
    url: 'v1/doc/privacy',
    method: 'GET',
    params: { language, project: PROJECT_ALIAS },
  });
}

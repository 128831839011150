import { useEffect, useState } from 'react';
import useRequest from 'src/hooks/useRequest';
import * as BlacklistService from 'src/services/BlacklistService';
import { isSuccess } from 'src/lib/remoteData';

const useBlackListOfNames = () => {
  const [blacklist, setBlacklist] = useState<string[]>([]);
  const [blacklistRequest] = useRequest(
    async () => await BlacklistService.getData()
  );

  useEffect(() => {
    if (isSuccess(blacklistRequest)) {
      setBlacklist(blacklistRequest.data);
    }
  }, [blacklistRequest]);

  return blacklist;
};

export default useBlackListOfNames;

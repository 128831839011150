import * as yup from 'yup';
import { FieldHookConfig, useField } from 'formik';
import { useIntl } from 'react-intl';
import { useRef, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import { ValidationSchemaProps } from '../../typings';

import TCSettingsModal from 'src/components/TermsConditionSettings/TCSettingsModal';
import { initialSettingsValues } from 'src/components/TermsConditionSettings/constants';
import { Values } from 'src/components/TermsConditionSettings/types';
import Checkbox from 'src/components/inputs/Checkbox';
import PrivacyAgreementText from 'src/components/forms/RegistationForm/fields/PrivacyAgreement/PrivacyAgreementText';

export const initialValue = false;

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .boolean()
    .required(
      intl.formatMessage({
        id: 'form.validation.required.privacyAgreement',
        defaultMessage: 'This field is required',
      })
    )
    .oneOf(
      [true],
      intl.formatMessage({
        id: 'form.validation.required.privacyAgreement',
        defaultMessage: 'This field is required',
      })
    );

export interface PrivacyAgreementFieldProps {
  onMarketingLinkClick: () => void;
  onPrivacyLinkClick: () => void;
  onTermsLinkClick: () => void;
}

export const REJECTED_ANY = 'rejected_any';

export function Component({
  onMarketingLinkClick,
  onPrivacyLinkClick,
  onTermsLinkClick,
  ...props
}: PrivacyAgreementFieldProps & FieldHookConfig<boolean>) {
  const [field, meta] = useField(props);
  const intl = useIntl();
  const hasError = Boolean(meta.touched && meta.error);
  const [isOpened, setOpenSettings] = useState(false);
  const [, , heplers] = useField(REJECTED_ANY);
  const settingsValues = useRef<Values>(initialSettingsValues);

  const settingsCallback = (isRejectedAny: boolean, values: Values) => {
    setOpenSettings(false);

    settingsValues.current = values;
    heplers.setValue(isRejectedAny || undefined);
  };

  return (
    <>
      <FormGroup
        sx={{
          marginY: 1,
          paddingTop: 1,
        }}
      >
        <FormControl required error={hasError}>
          <FormControlLabel
            {...field}
            control={<Checkbox hasError={hasError} id={field.name} />}
            checked={field.value}
            sx={{
              margin: 0,
              alignItems: 'flex-start',
            }}
            label={
              <Typography
                className={`${hasError && 'error'}`}
                sx={(theme) => ({
                  fontSize: '0.7em',
                  color: '#7B7B7B',
                  marginLeft: 1.5,
                  ...(hasError && {
                    backgroundColor: theme.palette.error.light,
                    boxShadow: `0px 0px 1px 2px ${theme.palette.error.main}`,
                  }),
                })}
                onClick={(e) => {
                  if (field.value) {
                    e.preventDefault();
                  }
                }}
              >
                <PrivacyAgreementText
                  onMarketingLinkClick={onMarketingLinkClick}
                  onPrivacyLinkClick={onPrivacyLinkClick}
                  onTermsLinkClick={onTermsLinkClick}
                  onOpenSettingsClick={() => setOpenSettings(true)}
                />
              </Typography>
            }
          />
        </FormControl>
      </FormGroup>
      {isOpened && (
        <TCSettingsModal
          onSave={settingsCallback}
          handleMarketingClick={onMarketingLinkClick}
          values={settingsValues.current}
        />
      )}
    </>
  );
}

import { BROWSER, BROWSER_INTENT_MAP } from './constants';

export const findKey = <T extends Record<string, unknown>>(
  obj: T,
  predicate: (value: T[keyof T], key: keyof T, obj: T) => boolean = () => true
): keyof T | undefined =>
  (Object.keys(obj) as Array<keyof T>).find((key) =>
    predicate(obj[key], key, obj)
  );

const getUrlWithoutProtocol = (url: URL): string =>
  url.hostname + url.pathname + url.search;

export const getChangeBrowserLinkFromChromium = (
  url: URL,
  toBrowser: BROWSER
): string =>
  `intent://${getUrlWithoutProtocol(url)}#Intent;scheme=https;package=${BROWSER_INTENT_MAP[toBrowser]};end`;

const BROWSERS_DEEP_LINKS: Partial<Record<BROWSER, string>> = {
  [BROWSER.CHROME]: 'googlechromes',
};
export const getChangeBrowserLinkFromIOS = (
  url: URL,
  toBrowser?: BROWSER
): string => `googlechromes://${getUrlWithoutProtocol(url)}`;

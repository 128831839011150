import { useEffect, useState } from 'react';

const MOBILE_WIDTH = 600;

const useWidthObserver = (maxWidth: number = MOBILE_WIDTH) => {
  const [isMobile, setMobile] = useState(window.screen.width <= maxWidth);

  useEffect(() => {
    const checkWidth = () => {
      setMobile(window.innerWidth <= maxWidth);
    };
    window.addEventListener('resize', checkWidth);

    return () => {
      window.removeEventListener('resize', checkWidth);
    };
  }, []);

  return isMobile;
};

export default useWidthObserver;

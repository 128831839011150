import { atom, RecoilState, selector, useRecoilState } from 'recoil';

export enum MAIN_STATE {
  SWEEP_MAIN = 'sweepstake_main',
  SWEEP_LIST = 'sweepstake_list',
  OFFER_LIST = 'offer_list',
  SURVEY_LIST = 'survey_list',
  TOP_OFFER_LIST = 'top_offer_list',
}

export enum MainDataStatus {
  NOT_LOADED,
  LOADED_FULL,
  LOADING,
}

export type MainData = {
  [MAIN_STATE.SWEEP_MAIN]: Sweepstake | null;
  [MAIN_STATE.SWEEP_LIST]: SweepstakeContainer | null;
  [MAIN_STATE.OFFER_LIST]: TaskContainer | null;
  [MAIN_STATE.SURVEY_LIST]: TaskContainer | null;
  [MAIN_STATE.TOP_OFFER_LIST]: Task[] | null;
};

type State = {
  data: MainData;
  status: MainDataStatus;
  locale?: Locale;
};

const defaultState: State = {
  data: {
    [MAIN_STATE.SWEEP_MAIN]: null,
    [MAIN_STATE.SWEEP_LIST]: null,
    [MAIN_STATE.OFFER_LIST]: null,
    [MAIN_STATE.SURVEY_LIST]: null,
    [MAIN_STATE.TOP_OFFER_LIST]: null,
  },
  status: MainDataStatus.NOT_LOADED,
  locale: undefined,
};

const mainAtom = atom<State>({
  key: 'main',
  default: defaultState,
});

const mainStateSelectors = () => {
  const selectors: Partial<Record<MAIN_STATE, RecoilState<any>>> = {};

  return <T extends MAIN_STATE>(type: T) => {
    if (!selectors[type]) {
      selectors[type] = selector({
        key: `main-${type}`,
        get: ({ get }) => {
          return get(mainAtom).data[type];
        },
        set: ({ get, set }, newValue) => {
          const state = { ...get(mainAtom) };
          state.data = { ...state.data, [type]: newValue };

          set(mainAtom, state);
        },
      });
    }

    return selectors[type] as RecoilState<State['data'][T]>;
  };
};

export const mainStateGetItemSelector = mainStateSelectors();

type UpdateStateByItem = <T extends MAIN_STATE>(
  items: PartialRecord<T, State['data'][T]>
) => void;
export const useUpdateMainStateItems = (): [MainData, UpdateStateByItem] => {
  const [mainState, setMainState] = useRecoilState(mainAtom);

  const update: UpdateStateByItem = (items) => {
    const updatedState = { ...mainState };

    updatedState.data = { ...mainState.data, ...items };

    setMainState(updatedState);
  };

  return [mainState.data, update];
};

export const useMainStateItem = <T extends MAIN_STATE>(state: T) => {
  return useRecoilState(mainStateGetItemSelector(state));
};

export const useMainState = (): [
  get: State,
  set: (data: MainData, locale: Locale) => void,
  setStatus: (status: MainDataStatus) => void,
] => {
  const [get, set] = useRecoilState(mainAtom);

  return [
    get,
    (data, locale) => {
      set({ data, status: MainDataStatus.LOADED_FULL, locale });
    },
    (status: MainDataStatus) => {
      set({ ...get, status });
    },
  ];
};

export const useClearMainState = () => {
  const [, set] = useRecoilState(mainAtom);
  return () => {
    set(defaultState);
  };
};

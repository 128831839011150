import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useIntl } from 'react-intl';

import { isSuccess } from 'src/lib/remoteData';
import { setFirstVisit } from 'src/hooks/useHomepage';
import * as ProfileService from 'src/services/ProfileService';
import { pushSignUpEvent } from 'src/services/GoogleTagManagerService';
import { ROUTE_SIGNIN } from 'src/constants/routes';
import { useRegMarkerUrl } from 'src/providers/RegPopunderProvider';
import {
  useClearRegistrationParams,
  useExtraRegistrationParams,
} from 'src/hooks/useExtraRegistrationParams';
import * as gtm from 'src/lib/gtm';
import { useLocaleState } from 'src/providers/LocaleProvider';

import StepRegistration from './registration/StepRegistration';
import { useDesignParams } from 'src/hooks/useDesign';
import { useFeaturesUpdater } from 'src/providers/FeaturesProvider';
import { useFlowUpdater } from 'src/providers/RegFlowProvider';
import usePostRegTaskController from 'src/hooks/usePostRegTaskController';

type Errors = Record<string, string>;

enum ErrorsType {
  FORM,
  RESPONSE,
}

export default function RegistrationPage() {
  const [errors, setErrors] = useState<{ type?: ErrorsType; data: Errors }>({
    data: {},
  });
  const intl = useIntl();
  const locale = useLocaleState();
  const { registrationParams } = useExtraRegistrationParams({ locale });
  const { clear, push: flowUpdater } = useFlowUpdater();
  const featuresUpdater = useFeaturesUpdater();
  const redirectUrl = useRegMarkerUrl();
  const { designId } = useDesignParams();
  const { setProfile } = usePostRegTaskController();

  useEffect(() => {
    if (registrationParams?.geo) {
      gtm.set('geo', registrationParams.geo);
    }
    if (registrationParams?.features) {
      featuresUpdater(registrationParams.features);
    }
    if (registrationParams?.components?.length) {
      flowUpdater(registrationParams.components);
    }
  }, [registrationParams]);

  const loadProfile = async () => {
    const profileRequest = await ProfileService.getProfile();

    if (isSuccess(profileRequest)) {
      setFirstVisit('reg');
      pushSignUpEvent(profileRequest.data.id);
      setProfile(profileRequest.data);
    } else {
      setErrors({
        type: ErrorsType.RESPONSE,
        data: profileRequest.error,
      });
    }
  };

  const afterReg = async (auth: boolean) => {
    if (auth) {
      clear();
    }
    await loadProfile();
  };

  return (
    <>
      <StepRegistration
        onNext={afterReg}
        redirectUrl={redirectUrl}
        registrationParams={registrationParams}
      />
      {!designId && (
        <Link
          component={RouterLink}
          to={ROUTE_SIGNIN}
          sx={{ marginTop: 4, display: 'inline-block' }}
        >
          {intl.formatMessage({
            id: 'RegistrationPage.signIn',
            defaultMessage: 'I already have an account',
          })}
        </Link>
      )}
    </>
  );
}

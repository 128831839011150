export enum BROWSER_NAME {
  SAFARI = 'safari',
}

export const BROWSER: { [key: string]: RegExp } = {
  messenger: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  facebook: /\bFB[\w_]+\//,
  twitter: /\bTwitter/i,
  line: /\bLine\//i,
  wechat: /\bMicroMessenger\//i,
  puffin: /\bPuffin/i,
  miui: /\bMiuiBrowser\//i,
  instagram: /\bInstagram/i,
  chrome: /\bCrMo\b|CriOS|Android.*Chrome\/[.0-9]* (Mobile)?/,
  [BROWSER_NAME.SAFARI]:
    /\bSafari\/[.0-9]*\b(?!.*Chrome|CriOS|CrMo|Edge|OPR|Edg|EdgiOS)/i,
  ie: /IEMobile|MSIEMobile/,
  firefox:
    /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
};

import { useIntl } from 'react-intl';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import useMenuItems from 'src/hooks/useMenuItems';
import { useProfileState } from 'src/providers/ProfileProvider';
import {
  ROUTE_ACCOUNT_LOGOUT,
  ROUTE_ACCOUNT_PROFILE,
  ROUTE_SIGNIN,
} from 'src/constants/routes';

import { ReactComponent as MenuIconLogin } from 'src/assets/icons/login.svg';

import logoImage from 'src/assets/logo.svg';
import arrowImage from 'src/assets/icons/arrow-close.svg';

type MenuItem = {
  text: string;
  to: string;
  icon: JSX.Element;
  hoverEffect: MenuHoverEffect;
};

function Item({ onClick, item }: { onClick?: Function; item: MenuItem }) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={NavLink}
        to={item.to!}
        onClick={onClick as any}
        sx={{
          '&.active, :hover ': {
            color: '#3C25CF',
            background: '#F1F0FB',
            borderRadius: 3,
          },
          '&.active, &:hover, &.active:hover': {
            path: {
              [item.hoverEffect]: '#3C25CF',
            },
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
  );
}

interface SidebarMenuProps {
  isMenuOpened?: boolean;
  setMenuOpened?: Function;
  logo?: string;
}

export function Sidebar({ onClickItem }: { onClickItem?: Function }) {
  const intl = useIntl();
  const { profile } = useProfileState();
  const menuItems = useMenuItems();

  return (
    <div>
      {profile ? (
        <div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginY: 2,
            }}
          >
            <Avatar
              sx={{ width: '56px', height: '56px' }}
              src={profile?.avatar}
              alt={profile?.first_name}
            >
              <Typography>
                {profile?.first_name?.substring(0, 1)}
                {profile?.last_name?.substring(0, 1)}
              </Typography>
            </Avatar>
            <Link
              underline="none"
              component={RouterLink}
              to={ROUTE_ACCOUNT_PROFILE}
              sx={{ flex: 'auto' }}
            >
              <Typography
                sx={{
                  marginLeft: 2,
                  marginRight: '0.25em',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '170px',
                  fontWeight: 500,
                  color: '#07091D',
                }}
              >
                {profile?.first_name?.length > 0
                  ? profile?.first_name
                  : profile?.email}
              </Typography>
            </Link>
          </Box>

          <Divider light sx={{ marginY: 3 }} />
        </div>
      ) : null}

      <List sx={{ padding: 0, marginTop: 2 }}>
        {menuItems.map((item, key) => (
          <Item onClick={onClickItem} key={key} item={item} />
        ))}
      </List>

      <Box sx={{ marginTop: 'auto' }}>
        <List sx={{ padding: 0, marginTop: 4, marginBottom: 1 }}>
          {profile ? (
            <Item
              onClick={onClickItem}
              item={{
                to: ROUTE_ACCOUNT_LOGOUT,
                icon: <MenuIconLogin />,
                hoverEffect: 'stroke' as MenuHoverEffect,
                text: intl.formatMessage({
                  id: 'logout',
                  defaultMessage: 'Logout',
                }),
              }}
            />
          ) : (
            <Item
              onClick={onClickItem}
              item={{
                to: ROUTE_SIGNIN,
                icon: <MenuIconLogin />,
                hoverEffect: 'stroke' as MenuHoverEffect,
                text: intl.formatMessage({
                  id: 'login',
                  defaultMessage: 'Login',
                }),
              }}
            />
          )}
        </List>
      </Box>
    </div>
  );
}

export default function SidebarMenu({
  isMenuOpened = false,
  setMenuOpened = () => {},
  logo,
}: SidebarMenuProps) {
  return (
    <Drawer
      anchor="left"
      open={isMenuOpened}
      onClose={() => setMenuOpened(false)}
      PaperProps={{
        sx: {
          borderBottomRightRadius: 20,
          borderTopRightRadius: 20,
        },
      }}
    >
      <Box
        role="presentation"
        sx={{
          width: '250px',
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          marginX: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 2,
            marginBottom: 3,
          }}
        >
          <Box
            component="img"
            src={logo ?? logoImage}
            sx={{ width: '90px', height: '16px' }}
          />
          <Link href="#" onClick={() => setMenuOpened(false)}>
            <Box
              component="img"
              src={arrowImage}
              sx={{
                width: '16px',
                height: '16px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Link>
        </Box>

        <Sidebar onClickItem={() => setMenuOpened(false)} />
      </Box>
    </Drawer>
  );
}

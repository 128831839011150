import { useEffect, useState } from 'react';
import { useFlowConsumer } from 'src/providers/RegFlowProvider';
import { GLOBAL_TASK, REG_FLOW } from 'src/constants/enums';
import { useProfileDispatch } from 'src/providers/ProfileProvider';
import { useGlobalTasksPusher } from 'src/providers/GlobalTasksProvider';
import { useClearRegistrationParams } from 'src/hooks/useExtraRegistrationParams';

const usePostRegTaskController = () => {
  const [_profile, _setProfile] = useState<ProfileUI>();
  const { setProfile } = useProfileDispatch();
  const [step, pop] = useFlowConsumer();
  const pushTask = useGlobalTasksPusher();
  const clearRegistrationParams = useClearRegistrationParams();

  const loadTutorials = () => {
    const regPrizeId = new URLSearchParams(location.search).get('reg_prize_id');

    if (_profile?.exp_branch === 'tutorial') {
      pushTask(GLOBAL_TASK.TUTORIAL);
      if (regPrizeId) {
        pushTask(GLOBAL_TASK.PREPOP);
      }
    }
  };

  useEffect(() => {
    if (!_profile) {
      return;
    }
    if (!step) {
      loadTutorials();
      setProfile(_profile);
      clearRegistrationParams();
      return;
    }
    switch (step) {
      case REG_FLOW.SECOND_REG:
        pushTask(GLOBAL_TASK.SECOND_REGISTRATION);
        pop();
        break;
      case REG_FLOW.PROMOTION:
        pushTask(GLOBAL_TASK.PROMOTION);
        pop();
        break;
      case REG_FLOW.POST_REG_SURVEY:
        pushTask(GLOBAL_TASK.POST_REG_SURVEY);
        pop();
        break;
      case REG_FLOW.POST_REG_TASKS:
        pushTask(GLOBAL_TASK.POST_REG_TASKS);
        pop();
        break;
      case REG_FLOW.PROMOTION_DATING:
        pushTask(GLOBAL_TASK.PROMOTION_DATING);
        pop();
        break;
    }
  }, [step, Boolean(_profile)]);

  return {
    setProfile: _setProfile,
  };
};

export default usePostRegTaskController;

import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';

import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import {
  ROUTE_CONFIRMATION_BASE,
  ROUTE_SURVEY_DETAIL,
} from 'src/constants/routes';
import { useProfileState } from 'src/providers/ProfileProvider';
import { useLocation } from 'react-router';

let nextUpdateTime = 0;

const useExcluded = () => {
  const matched = useMatch(ROUTE_SURVEY_DETAIL);

  return !!matched;
};

const useVerificationModal = (
  openCallback: () => void
): [boolean, () => void] => {
  const config = useFeature(FEATURE.EMAIL_CONFIRMATION);
  const timeoutTime = (config?.interval ?? 300) * 1000;
  const intervalRef = useRef<number>(0);
  const [isOpen, setOpen] = useState(false);
  const excluded = useExcluded();
  const { profile } = useProfileState();
  const location = useLocation();

  const onOpen = () => {
    if (excluded) {
      onClose();
    } else {
      openCallback();
      setOpen(true);
    }
  };

  const shiftNextTick = () =>
    (nextUpdateTime = new Date().getTime() + timeoutTime);

  const onClose = () => {
    setOpen(false);
    shiftNextTick();
    intervalRef.current = setTimeout(onOpen, timeoutTime) as unknown as number;
  };

  useLayoutEffect(() => {
    if (location.pathname.includes(ROUTE_CONFIRMATION_BASE)) {
      shiftNextTick();
    }
  }, [location]);

  useEffect(() => {
    const currentTime = new Date().getTime();

    if (!nextUpdateTime || currentTime >= nextUpdateTime) {
      onOpen();
    } else {
      intervalRef.current = setTimeout(
        onOpen,
        nextUpdateTime - currentTime
      ) as unknown as number;
    }
    return () => {
      clearTimeout(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (!profile) {
      return () => {
        nextUpdateTime = 0;
      };
    }
  }, [profile]);

  return [isOpen, onClose];
};

export default useVerificationModal;

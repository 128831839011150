import { createRef, memo, useCallback, useEffect, useState } from 'react';
import { useReCaptchaInit } from 'src/providers/ReCaptchaProvider';
import './styles.css';

interface ReCaptchaV2Props {
  callback: (token: string | false | Error) => void;
  theme: ReCaptchaV2.Theme;
  size: ReCaptchaV2.Size;
  tabindex?: number;
  isSubmitting: boolean;
}

const Component = memo((props: ReCaptchaV2Props) => {
  const { callback, isSubmitting, theme, size, tabindex } = props;
  const { siteKeyV2, loaded } = useReCaptchaInit();

  const ref = createRef<HTMLDivElement>();
  const [widgetId, setWidgetId] = useState<any>();

  const expiredCallback = useCallback(() => {
    callback(false);
  }, [callback]);

  const errorCallback = useCallback(() => {
    callback(new Error());
  }, [callback]);

  const successCallback = useCallback(
    (token: string) => {
      callback(token);
    },
    [callback]
  );

  useEffect(() => {
    if (loaded && ref.current && siteKeyV2) {
      try {
        const newWidgetId = grecaptcha.render(ref.current, {
          sitekey: siteKeyV2,
          callback: successCallback,
          'expired-callback': expiredCallback,
          'error-callback': errorCallback,
          theme,
          size,
          tabindex,
        });

        setWidgetId(newWidgetId);
      } catch (error) {
        console.warn(error);
        window.grecaptcha?.reset(widgetId);
      }
    }
  }, [loaded]);

  useEffect(() => {
    if (!isSubmitting && typeof widgetId === 'number') {
      expiredCallback();
      grecaptcha.reset(widgetId);
    }
  }, [isSubmitting]);

  useEffect(() => {
    return () => {
      if (window.grecaptcha && typeof widgetId === 'number') {
        window.grecaptcha.reset(widgetId);
      }
    };
  }, []);

  if (!siteKeyV2) {
    throw new Error(
      'The prop "siteKeyV2" must be set on the ReCaptchaProvider before using the ReCaptchaV2 component'
    );
  }

  return <div ref={ref} />;
});

export default Component;

import {
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';

import * as Storage from 'src/lib/storage';
export enum EXTERNAL_AUTH {
  GOOGLE,
  FB,
  FAKE,
}

export type ExtProfileData = Record<string, any>;

export type State = {
  type: EXTERNAL_AUTH;
  data: ExtProfileData;
};

const externalAuthAtom = atom<State>({
  key: 'external-auth',
  default: undefined,
});

export const useUpdateExternalAuthProfile = () => {
  const [, set] = useRecoilState(externalAuthAtom);

  return (type: EXTERNAL_AUTH, data: ExtProfileData) => {
    set({ type, data });
  };
};
export const useClearExternalAuthProfile = () => {
  return useResetRecoilState(externalAuthAtom);
};

export const useSupplementExternalAuthProfile = () => {
  const [state, set] = useRecoilState(externalAuthAtom);

  return (data: ExtProfileData) =>
    set({
      type: state.type,
      data: {
        ...state.data,
        ...data,
      },
    });
};

export const useExternalAuthProfile = () => {
  return useRecoilValue(externalAuthAtom) as State | undefined;
};

import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import Modal from 'src/components/Modal';
import TCSettingsForm from './TCSettingsForm';
import { initialSettingsValues } from 'src/components/TermsConditionSettings/constants';

import { Values } from './types';

type Props = {
  onSave: (isRejectedAny: boolean, values: Values) => void;
  values: Values;
  handleMarketingClick: () => void;
};

const TCSettingsModal: FunctionComponent<Props> = ({
  onSave,
  values,
  handleMarketingClick,
}) => {
  const intl = useIntl();

  const formik = useFormik({
    initialValues: values,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const isRejectedAny = Object.values(values).some((value) => !value);

      onSave(isRejectedAny, values);
    },
  });

  return (
    <Modal
      isOpen
      paperSx={(theme) => ({
        background: theme.palette.background.paper,
      })}
      handleClose={() => {
        const isRejectedAny = Object.values(values).some((value) => !value);

        onSave(isRejectedAny, values);
      }}
      titleSx={{
        textAlign: 'center',
      }}
      title={intl.formatMessage({
        id: 'tc_settings_modal.header',
        defaultMessage: 'Advertising Media Channels Settings',
      })}
      fullWidth
    >
      <TCSettingsForm
        handleMarketingClick={handleMarketingClick}
        onAcceptAll={() => onSave(false, initialSettingsValues)}
        formikInstance={formik}
      />
    </Modal>
  );
};

export default TCSettingsModal;

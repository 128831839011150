import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { PROJECT_ALIAS } from 'src/constants/app';

const name = PROJECT_ALIAS === 'rewardis' ? 'Rewardis' : 'MySurvey';

export type Props = {
  onMarketingLinkClick: () => void;
  onOpenSettingsClick: () => void;
  onTermsLinkClick: () => void;
  onPrivacyLinkClick: () => void;
};

const PrivacyAgreementText: FunctionComponent<Props> = ({
  onMarketingLinkClick,
  onTermsLinkClick,
  onOpenSettingsClick,
  onPrivacyLinkClick,
}) => {
  const intl = useIntl();

  return (
    <>
      {intl.formatMessage(
        {
          id: 'form.privacy_agreement_content',
          defaultMessage: `By checking this box you agree to the collection, store, use, transfer and sale of your data by <name>name</name> and its Partners listed <a1> here</a1>, who you hereby consent to contact you for the promotion and marketing of their products and services. You may change your marketing preferences by clicking the unsubscribe link <a2> here</a2>. You hereby acknowledge and accept <name>{name}</name> <a3> Privacy Policy</a3>.`,
        },
        {
          a1: (link: any) => (
            <Link
              onClick={(e) => {
                e.preventDefault();
                onMarketingLinkClick();
              }}
            >
              {link}
            </Link>
          ),
          a2: (link: any) => (
            <Link
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                window.open('/opt-out', '_blank');
              }}
            >
              {link}
            </Link>
          ),
          a3: (link: any) => (
            <Link
              onClick={(e) => {
                e.preventDefault();
                onPrivacyLinkClick();
              }}
            >
              {link}
            </Link>
          ),
          name: () => name,
        }
      )}
      <br />
      {intl.formatMessage(
        {
          id: 'form.tc_settings',
          defaultMessage:
            'You can adjust your third-party communication channel preferences in <a>Settings</a>.',
        },
        {
          a: (text: any) => {
            return (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  onOpenSettingsClick();
                }}
              >
                {text}
              </Link>
            );
          },
        }
      )}
      <br />
      {intl.formatMessage(
        {
          id: 'form.term_and_conditions',
          defaultMessage: `By clicking ’Create an account’ you agree to <name>name</name> <a1> Terms and Conditions</a1>.`,
        },
        {
          a1: (link: any) => (
            <Link
              onClick={(e) => {
                onTermsLinkClick();
              }}
              sx={{
                display: 'inline-block',
              }}
            >
              {link}
            </Link>
          ),
          name: () => name,
        }
      )}
    </>
  );
};

export default PrivacyAgreementText;

import { SOURCE, Values } from './types';

export const SOURCE_INFO: {
  name: SOURCE;
  message: { id: string; defaultMessage: string };
}[] = [
  {
    name: SOURCE.EMAIL,
    message: {
      id: 'tc_settings_modal.email',
      defaultMessage:
        'I agree that the organiser and the competition <a1>sponsors</a1> may contact me by email regarding offers from their respective business areas and that my data may be transmitted to said sponsors for this purpose. I can revoke my consent at any time. More information <a2>here</a2>.',
    },
  },
  {
    name: SOURCE.PHONE,
    message: {
      id: 'tc_settings_modal.phone',
      defaultMessage:
        'I agree that the organiser and the competition <a1>sponsors</a1> may contact me by phone regarding offers from their respective business areas and that my data may be transmitted to said sponsors for this purpose. I can revoke my consent at any time. More information <a2>here</a2>.',
    },
  },
  {
    name: SOURCE.SMS,
    message: {
      id: 'tc_settings_modal.sms',
      defaultMessage:
        'I agree that the organiser and the competition <a1>sponsors</a1> may contact me by sms regarding offers from their respective business areas and that my data may be transmitted to said sponsors for this purpose. I can revoke my consent at any time. More information <a2>here</a2>.',
    },
  },
];

export const initialSettingsValues: Values = {
  [SOURCE.EMAIL]: true,
  [SOURCE.PHONE]: true,
  [SOURCE.SMS]: true,
};

import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import { mainTheme } from 'src/themes';
import CookieConsent from 'src/components/CookieConsent';
import DebugPanel from '../components/DebugPanel';

export default function LayoutBase({ children }: PropsWithChildren) {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <DebugPanel />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '100vh',
          background: '#FFFFFF',
        }}
      >
        {children ? children : <Outlet />}
      </Box>
      <CookieConsent />
    </ThemeProvider>
  );
}

import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import RegistrationBackButtonModal from 'src/components/modal/BackButtonModal/RegistrationBackButtonModal';
import { buildUrlWithParams } from 'src/pages/registration/utils';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import { registrationParamsState } from 'src/recoil/registrationParams';

const RegistrationBackModal: FunctionComponent = () => {
  const featureBackRegPopup = useFeature(FEATURE.BACK_REG_POPUP);
  const registrationParams = useRecoilValue(registrationParamsState);
  const [isBackButtonModalOpen, setBackButtonModalOpen] =
    useState<boolean>(false);

  const openBackButtonModal = useCallback(
    () => setBackButtonModalOpen(true),
    []
  );

  const backButtonHijack = () => {
    for (let i = 0; i < 10; i += 1) {
      window.history.pushState(null, '');
    }

    window.addEventListener('popstate', () => {
      openBackButtonModal();
    });
  };

  useEffect(() => {
    if (featureBackRegPopup) {
      backButtonHijack();
    }
  }, [featureBackRegPopup]);

  const handleBackButtonModalClick = useCallback(() => {
    if (!registrationParams.oaid) return;

    const additionalParams = {
      oaid: registrationParams.oaid,
      var: registrationParams.sub_id1!,
      ymid: registrationParams.sub_id2!,
      ab2r: registrationParams.ab2r,
      abtest: registrationParams.ab2r,
    };
    const currentTabLink = buildUrlWithParams(
      featureBackRegPopup?.current_tab_link!,
      additionalParams
    );
    const newTabLink = buildUrlWithParams(
      featureBackRegPopup?.new_tab_link!,
      additionalParams
    );

    if (newTabLink) {
      const w = window.open(newTabLink, '_blank');

      if (w && currentTabLink) {
        window.location.replace(currentTabLink);
      }
    }
  }, [featureBackRegPopup, registrationParams]);

  return (
    <RegistrationBackButtonModal
      handleClick={handleBackButtonModalClick}
      handleClose={() => setBackButtonModalOpen(false)}
      isOpen={isBackButtonModalOpen}
    />
  );
};

export default RegistrationBackModal;

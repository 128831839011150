import { useEffect } from 'react';
import { setupCloudFlareCaptcha } from 'src/lib/extraScripts';
import { useCaptchaMutation } from 'src/recoil/recaptcha';
import { CLOUDFLARE_CAPTCHA_KEY } from 'src/constants/app';

export const renderCaptcha = (
  container: string,
  callback: (token: string) => void,
  beforeInteractiveCallback?: () => void
) => {
  if (!CLOUDFLARE_CAPTCHA_KEY) {
    return 0;
  }

  return window.turnstile.render(container, {
    sitekey: CLOUDFLARE_CAPTCHA_KEY,
    callback,
    size: 'flexible',
    appearance: 'interaction-only',
    'before-interactive-callback': beforeInteractiveCallback,
  });
};

export const resetCaptchaWidget = (widgetId: number) => {
  if (window.turnstile) {
    window.turnstile.reset(widgetId);
  }
};

export const useCloudflareCaptchaLoader = () => {
  const mutateRecaptcha = useCaptchaMutation();

  const onLoadCloudflare = () => {
    window.onloadTurnstileCallback = function () {
      mutateRecaptcha({
        loaded: true,
      });
      window.onloadTurnstileCallback = () => {};
    };
  };

  useEffect(() => {
    onLoadCloudflare();
    setupCloudFlareCaptcha();
  }, []);
};

import { FunctionComponent, memo, ReactNode } from 'react';
import { FieldHookConfig, useField } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';

import Checkbox from 'src/components/inputs/Checkbox';
import { SOURCE } from './types';

type Props = {
  name: SOURCE;
  label: string | ReactNode;
};

const TCSettingsSourceCheckbox: FunctionComponent<
  Props & FieldHookConfig<boolean>
> = (props) => {
  const { name, label } = props;
  const [field, meta, helpers] = useField(props);

  return (
    <FormGroup
      sx={{
        marginBottom: 1,
      }}
    >
      <FormControlLabel
        {...field}
        control={
          <Checkbox
            hasError={false}
            id={name}
            className="primary-color static-color"
          />
        }
        label={
          <Typography
            sx={(theme) => ({
              marginLeft: 1.5,
              color: theme.palette.text.disabled,
              [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: 1,
                textAlign: 'justify',
              },
            })}
          >
            {label}
          </Typography>
        }
        checked={field.value}
        sx={{
          margin: 0,
          alignItems: 'flex-start',
        }}
      ></FormControlLabel>
    </FormGroup>
  );
};

export default memo(TCSettingsSourceCheckbox);

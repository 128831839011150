import { ReactNode, createContext, useReducer, useContext } from 'react';
import IFrameModal from './IFrameModal';

interface State {
  task?: Task;
}

const initialState = {};

type Dispatch = (action: Action) => void;

const IFrameTaskStateContext = createContext<State | undefined>(undefined);
const IFrameTaskDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

type Action = { type: 'reset' } | { type: 'setTask'; payload: Task };

function IFrameTaskReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'reset': {
      return {};
    }
    case 'setTask': {
      return {
        task: action.payload,
        ...state,
      };
    }
    default:
      throw new Error(`Unhandled action`);
  }
}

export function useIFrameTaskState() {
  const state = useContext(IFrameTaskStateContext);

  if (state === undefined) {
    throw new Error(
      'useIFrameTaskState must be used within a `IFrameTaskStateContext`'
    );
  }

  return state;
}

export function useIFrameTaskDispatch() {
  const dispatch = useContext(IFrameTaskDispatchContext);

  if (dispatch === undefined) {
    throw new Error(
      'useIFrameTaskDispatch must be used within a `IFrameTaskDispatchProvider`'
    );
  }

  return {
    setTask: (payload: Task) => dispatch({ type: 'setTask', payload }),
    resetTask: () => dispatch({ type: 'reset' }),
  };
}

interface IFrameTaskProviderProps {
  children?: ReactNode;
}

export default function IFrameTaskProvider({
  children,
}: IFrameTaskProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(IFrameTaskReducer, initialState);

  return (
    <IFrameTaskStateContext.Provider value={state}>
      <IFrameTaskDispatchContext.Provider value={dispatch}>
        {children}
        <IFrameModal />
      </IFrameTaskDispatchContext.Provider>
    </IFrameTaskStateContext.Provider>
  );
}

import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { FieldHookConfig, useField } from 'formik';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FieldCheckIcon } from 'src/components/forms/CheckIcon';
import { useEffect, useState } from 'react';
import { ValidationSchemaProps } from '../typings';
import { Box } from '@mui/material';

export const initialValue = '';

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup.string().required(
    intl.formatMessage({
      id: 'form.validation.required',
      defaultMessage: 'This field is required',
    })
  );

const prepareZipCode = (
  zip_code_prefix: number,
  zip_code_base: number
): string => {
  return String(zip_code_prefix) + String(zip_code_base);
};

export function Component(props: FieldHookConfig<string>) {
  const [field, meta, helpers] = useField(props);
  const [zipCodePrefix, setZipCodePrefix] = useState<number>();
  const [zipCodeBase, setZipCodeBase] = useState<number>();
  const intl = useIntl();

  useEffect(() => {
    if (zipCodePrefix && zipCodeBase) {
      helpers.setTouched(true);
      helpers.setValue(prepareZipCode(zipCodePrefix, zipCodeBase));
    } else {
      helpers.setValue('');
    }
  }, [zipCodePrefix, zipCodeBase]);

  const hasError = Boolean(meta.touched && meta.error);

  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        marginY: 1,
      }}
    >
      <FormControl
        sx={{ flex: 3 }}
        fullWidth
        variant="outlined"
        error={hasError}
      >
        <OutlinedInput
          fullWidth
          name={props.name + '_code_prefix'}
          inputProps={{
            maxLength: 5,
            minLength: 5,
          }}
          placeholder={intl.formatMessage({
            id: 'form.zip_code_prefix',
            defaultMessage: 'Zip code prefix',
          })}
          endAdornment={
            <InputAdornment position="end">
              <FieldCheckIcon meta={meta} />
            </InputAdornment>
          }
          value={zipCodePrefix}
          onChange={(e) => {
            setZipCodePrefix(Number(e.target.value));
          }}
        />
      </FormControl>

      <Box
        sx={{
          flex: '0 0 10px',
          lineHeight: 3.5,
        }}
      >
        —
      </Box>
      <FormControl
        sx={{ flex: 2 }}
        fullWidth
        variant="outlined"
        error={hasError}
      >
        <OutlinedInput
          fullWidth
          name={props.name + '_code_base'}
          inputProps={{
            maxLength: 3,
          }}
          placeholder={intl.formatMessage({
            id: 'form.zip_code_base',
            defaultMessage: 'Zip code base',
          })}
          endAdornment={
            <InputAdornment position="end">
              <FieldCheckIcon meta={meta} />
            </InputAdornment>
          }
          value={zipCodeBase}
          onChange={(e) => {
            setZipCodeBase(Number(e.target.value));
          }}
        />
      </FormControl>
    </FormGroup>
  );
}

import { GTM_CODE } from 'src/constants/ids';

type _Window = Window & { dataLayer?: Array<any> };

const w: _Window & { dataLayer?: Array<any> } = window;

const isInited = () => Array.isArray(w?.dataLayer);

function init() {
  if (!GTM_CODE) {
    return;
  }
  const exec = (w: any, d: Document, s: any, l: string, i: string) => {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = d.getElementsByTagName<'script'>(s)[0];
    const j = d.createElement<'script'>(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f?.parentNode?.insertBefore(j, f);
  };

  if (!isInited()) {
    w.dataLayer = [];

    exec(window, document, 'script', 'dataLayer', GTM_CODE);
  }
}

function addGTMFrame() {
  if (!GTM_CODE) {
    return;
  }
  const noscript = document.createElement('noscript');
  const frame = document.createElement('frame');

  frame.src = `https://www.googletagmanager.com/ns.html?id=${GTM_CODE}`;
  frame.style.height = '0';
  frame.style.width = '0';
  frame.style.display = 'none';
  frame.style.visibility = 'hidden';

  noscript.append(frame);

  document.body.insertBefore(noscript, document.body.children[0]);
}

const pushGTMEvent = (data: Record<string, any>) => {
  if (!GTM_CODE || !isInited()) {
    return;
  }

  w.dataLayer?.push(data);
};

const registerGTMUser = (userID: string | number) => {
  pushGTMEvent({
    user_id: `rewardis-${userID}`,
  });
};

const pushSignUpEvent = (userID: string | number) => {
  pushGTMEvent({
    event: 'UAevent',
    eventAction: 'reg-form-complete',
    userID: `rewardis-${userID}`,
  });
};

const pushSignInEvent = (userID: string | number) => {
  pushGTMEvent({
    event: 'UAevent',
    eventAction: 'user-login-success',
    userID: `rewardis-${userID}`,
  });
};

const pushNavigateEvent = (
  pageURI: string,
  userID: string | number | undefined
) => {
  pushGTMEvent({
    event: 'virtualPageView',
    virtualPagePath: pageURI,
    ...(userID && {
      userID: `rewardis-${userID}`,
    }),
  });
};

const smartlookTrackEvent = (event: SMARTLOOK_CUSTOM_EVENT) => {
  if (window.smartlook && typeof window.smartlook === 'function') {
    window.smartlook('track', event);
  }
};

export const smartlookIdentification = (userId: number) => {
  if (window.smartlook && typeof window.smartlook === 'function') {
    window.smartlook('identify', userId);
  }
};

const SMARTLOOK_ELAPSED_TIME = 7000;

export {
  init,
  isInited,
  addGTMFrame,
  pushGTMEvent,
  registerGTMUser,
  pushSignUpEvent,
  pushSignInEvent,
  pushNavigateEvent,
  smartlookTrackEvent,
  SMARTLOOK_ELAPSED_TIME,
};

import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import { FieldName } from 'src/components/forms/RegistationForm/typings';
import MultiStepRegistrationForm from './MultiStepRegistrationForm';
import ExtraForm from 'src/components/forms/RegistationForm/MultiStepRegistration/ExtraForm';
import { SignupBody } from 'src/services/AuthService';

export type FormData = PartialRecord<FieldName, string | boolean | number>;

type Props = {
  stepsFields: FieldName[][];
  onSubmit: (data: SignupBody) => Promise<any>;
  onValidationEmail: (data: SignupBody) => Promise<any>;
  handleErrorsChange: any;
  prefilledData: SignupBody;
};

const toString = function (this: any) {
  return this.join(',');
};

const excludeFilledFields = (
  fieldsQueue: FieldName[][],
  values: PartialRecord<FieldName, any>
) => {
  return fieldsQueue
    .map((fields) => fields.filter((field) => !values[field]))
    .filter((fields) => fields?.length > 0);
};

const MultiStepRegistration: FunctionComponent<Props> = ({
  stepsFields,
  onValidationEmail,
  handleErrorsChange,
  prefilledData,
  onSubmit,
}) => {
  const intl = useIntl();
  const [fieldsQueue, updateFieldsQueue] = useState(stepsFields);

  useEffect(() => {
    if (!fieldsQueue.length) {
      return;
    }

    const updated = excludeFilledFields(stepsFields, prefilledData);
    updateFieldsQueue(updated);
  }, [prefilledData]);

  const unfilledFields = fieldsQueue.length ? fieldsQueue[0] : [];

  const onHandleSubmit = async (data: SignupBody) => {
    if (fieldsQueue.length === 1) {
      await onSubmit({ ...prefilledData, ...data });
      return;
    }
    if (data.email) {
      await onValidationEmail(data);
    }
  };

  const submitButtonText =
    fieldsQueue.length > 1
      ? intl.formatMessage({
          id: 'sign_up.continue',
          defaultMessage: 'Continue',
        })
      : intl.formatMessage({
          id: 'sign_up.complete',
          defaultMessage: 'Complete registration',
        });

  return (
    <>
      <Box
        sx={{
          marginY: 2,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h5"
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              color: '#FFFFFF',
            },
          })}
        >
          {intl.formatMessage(
            {
              id: 'sign_up.fill_profile',
              defaultMessage: 'Finish to Secure Your Entry, {name}',
            },
            {
              name: prefilledData.first_name,
            }
          )}
        </Typography>
      </Box>
      {unfilledFields?.length > 0 ? (
        <MultiStepRegistrationForm
          key={toString.apply(unfilledFields)}
          fields={unfilledFields}
          onSubmit={onHandleSubmit}
          handleErrorsChange={handleErrorsChange}
          submitButtonText={submitButtonText}
        />
      ) : (
        <ExtraForm
          prefilledData={prefilledData}
          onSubmit={onSubmit}
          handleErrorsChange={handleErrorsChange}
        />
      )}
    </>
  );
};

export default MultiStepRegistration;

import * as OfferService from './OffersService';
import * as ImpressionService from './ImpressionService';
import { request } from 'src/lib/request';

const OFFER_ID = 17;

export const regImpression = (userId: number, geo: string, route: string) => {
  return ImpressionService.offerImpression(userId, OFFER_ID, 1, geo, route);
};

export const send = () => {
  return request({
    method: 'POST',
    url: 'v1/confirmation/resend',
  });
};

export const regClick = (userId: number, route?: string) => {
  return OfferService.getSubID(userId, OFFER_ID, route);
};

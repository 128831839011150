import { atom, selectorFamily, useRecoilState } from 'recoil';

import { NOTIFICATION } from 'src/constants/enums';

type State = {
  [NOTIFICATION.BELL]: UserNotification[];
  [NOTIFICATION.IPP]: UserNotification[];
  [NOTIFICATION.MODAL]: UserNotification[];
};

export const notificationsAtom = atom<State>({
  key: 'notifications',
  default: {
    [NOTIFICATION.BELL]: [],
    [NOTIFICATION.IPP]: [],
    [NOTIFICATION.MODAL]: [],
  },
});

export const notificationsByType = selectorFamily({
  key: 'notification-list',
  get:
    (type: NOTIFICATION) =>
    ({ get }) => {
      const all = get(notificationsAtom);
      switch (type) {
        case NOTIFICATION.BELL:
          return !(
            all[NOTIFICATION.MODAL]?.length || all[NOTIFICATION.IPP]?.length
          )
            ? all[NOTIFICATION.BELL]
            : [];
        case NOTIFICATION.IPP:
          return !all[NOTIFICATION.MODAL]?.length ? all[NOTIFICATION.IPP] : [];
        case NOTIFICATION.MODAL:
          return all[NOTIFICATION.MODAL];
        default:
          return [];
      }
    },
});

export const useStoreNotifications = () => {
  const [, setState] = useRecoilState(notificationsAtom);

  return (notifications: UserNotification[]) => {
    const state: State = {
      [NOTIFICATION.BELL]: [],
      [NOTIFICATION.IPP]: [],
      [NOTIFICATION.MODAL]: [],
    };
    notifications.forEach((notification) => {
      state[notification.type].push(notification);
    });
    setState(state);
  };
};

import { request } from 'src/lib/request';
import * as TokenService from 'src/services/TokenService';

export const markNotificationsAsRead = (ids: number[]) => {
  return request({
    url: 'v1/notification/impression',
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${TokenService.getToken()['access-token']}`,
      Accept: 'application/json',
    },
    data: {
      ids,
    },
  });
};

export const markNotificationsAsClicked = (id: number) => {
  return request({
    url: 'v1/notification/click',
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${TokenService.getToken()['access-token']}`,
      Accept: 'application/json',
    },
    data: {
      id,
    },
  });
};

export const getNotifications = () => {
  return request<{ items: UserNotification[] }>({
    url: 'v1/notification/',
    headers: {
      Authorization: `Bearer ${TokenService.getToken()['access-token']}`,
      Accept: 'application/json',
    },
  });
};

import { lazy as _lazy } from 'react';

export default (function lazy(factory: () => Promise<{ default: any }>) {
  return _lazy(() => factory().catch(importErrorHandler));
});

function importErrorHandler() {
  const time = Number(window.location.hash.match(/#s(\d+)/)?.[1]);
  const now = Date.now();
  const isReloading = !time || time + 10_000 < now;

  if (isReloading) {
    window.location.hash = `#s${now}`;
    window.location.reload();
  }

  return {
    default: () => null,
  };
}

export const WS_URL = process.env.VITE_WS_URL || 'ws://localhost:4000';

export const RECAPTCHA_V2_KEY = process.env.VITE_RECAPTCHA_V2_KEY;
export const RECAPTCHA_V3_KEY = process.env.VITE_RECAPTCHA_V3_KEY;
export const VITE_TOKEN_OWN_CALLBACK = process.env.VITE_TOKEN_OWN_CALLBACK;

export const PROJECT_ALIAS =
  process.env.VITE_PROJECT_ALIAS || ('rewardis' as PROJECT_ALIASES);

export enum PROJECT_ALIASES {
  BIWARD = 'biward',
  REWARDIS = 'rewardis',
}

export const CPX_RESEARCH_APP_ID = 19727;

export const GTM_CODE = process.env.VITE_GTM_CODE;

export const DEPLOY_BRANCH = process.env.VITE_DEPLOY_BRANCH;
export const DEPLOY_BY = process.env.VITE_DEPLOY_BY;

export const BUILD_VERSION = process.env.VITE_BUILD_VERSION || null;

export const PLACES_API_KEY = process.env.VITE_PLACES_API_KEY || null;

export const NODE_ENV = process.env.NODE_ENV;

export const POP_TAG_IGNORE_SUB_PARAM = 'no_popup';
export const PIXEL_IFRAME_ERROR = 'PIXEL_IFRAME_ERROR';
export const PIXEL_IFRAME_WARNING = 'PIXEL_IFRAME_WARNING';

export const FB_APP_ID = process.env.VITE_FB_APP_ID;

export const CLOUDFLARE_CAPTCHA_KEY = process.env.VITE_CLOUDFLARE_CAPTCHA_KEY;

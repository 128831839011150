import { useProfileState } from 'src/providers/ProfileProvider';
import { useEffect, useState } from 'react';
import {
  isFailure,
  isLoading,
  isSuccess,
  loading,
  RemoteData,
} from 'src/lib/remoteData';
import { SyncResponse } from 'src/services/CookieSyncService';
import * as CookieSyncService from 'src/services/CookieSyncService';

const useCheckCurrentUser = () => {
  const { profile } = useProfileState();
  const [remoteData, setRemoteData] =
    useState<RemoteData<SyncResponse>>(loading);

  useEffect(() => {
    (async () => {
      setRemoteData(await CookieSyncService.sync());
    })();
  }, []);

  if (isSuccess(remoteData)) {
    return remoteData.data.gid === profile?.oaid;
  }
  if (isFailure(remoteData)) {
    return false;
  }
  return null;
};

export default useCheckCurrentUser;

import * as HomepageService from 'src/services/HomepageService';
import * as ImpressionService from 'src/services/ImpressionService';
import * as storage from 'src/lib/storage';
import { useEffect, useState } from 'react';
import { useLocaleState } from 'src/providers/LocaleProvider';
import {
  isSuccess,
  loading,
  notAsked,
  RemoteDataLoading,
  RemoteDataNotAsked,
  RemoteDataResult,
  success,
} from 'src/lib/remoteData';
import { smartlookTrackEvent } from 'src/services/GoogleTagManagerService';
import { SMARTLOOK_CUSTOM_EVENT } from 'src/constants/enums';
import { useGlobalTasksListener } from 'src/providers/GlobalTasksProvider';
import { GLOBAL_TASK } from 'src/constants/enums';
import { MainDataStatus, useMainState } from 'src/recoil/main';

const FIRST_VISIT = 'firstVisit';

export const setFirstVisit = (type: string) => {
  storage.set(FIRST_VISIT, type);
};

export const getFirstVisit = (): string => {
  return storage.get(FIRST_VISIT, '');
};

const clearFirstVisit = () => {
  storage.reset(FIRST_VISIT);
};

export function useHomepageRequest() {
  const [mainState] = useMainState();
  const { task } = useGlobalTasksListener();
  const [request, setRequest] = useState<
    RemoteDataNotAsked | RemoteDataLoading | RemoteDataResult
  >(notAsked);

  useEffect(() => {
    const firstVisitType = getFirstVisit();
    if (
      mainState.status === MainDataStatus.LOADED_FULL &&
      firstVisitType &&
      !task
    ) {
      ImpressionService.saveMainPageImpression(firstVisitType)
        .then(() => {
          smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.MAIN_PAGE_IMPRESSION);
          clearFirstVisit();
        })
        .catch();
    }
  }, [mainState.status, task]);

  useEffect(() => {
    switch (mainState.status) {
      case MainDataStatus.LOADED_FULL:
        setRequest(success({}));
        break;
      case MainDataStatus.LOADING:
        setRequest(loading);
        break;
      default:
        break;
    }
  }, [mainState.status]);

  return {
    request,
  };
}

import { useMessage } from 'src/providers/WebsocketProvider';
import {
  MESSAGE_OFFERS,
  MESSAGE_SURVEYS,
  MESSAGE_SWEEPSTAKE,
} from 'src/providers/WebsocketUserMessages';
import {
  MAIN_STATE,
  MainDataStatus,
  useClearMainState,
  useMainState,
  useUpdateMainStateItems,
} from 'src/recoil/main';
import * as OffersService from 'src/services/OffersService';
import { useLocaleState } from 'src/providers/LocaleProvider';
import { FEATURE, TASK_TYPE } from 'src/constants/enums';
import { isSuccess } from 'src/lib/remoteData';
import * as SweepstakesService from 'src/services/SweepstakesService';
import { useEffect } from 'react';
import { useProfileState } from 'src/providers/ProfileProvider';
import { GLOBAL_TASK } from 'src/constants/enums';
import { useGlobalTasksPusher } from 'src/providers/GlobalTasksProvider';
import * as HomepageService from 'src/services/HomepageService';
import { getFirstVisit } from 'src/hooks/useHomepage';
import { useLocation } from 'react-router';
import { useFeature } from 'src/providers/FeaturesProvider';

const UpdateMainStateMessagesProvider = () => {
  const [state, update] = useUpdateMainStateItems();
  const [, setMainState, setStatus] = useMainState();
  const clearState = useClearMainState();
  const { profile } = useProfileState();
  const locale = useLocaleState();
  const pushTask = useGlobalTasksPusher();
  const isAuth = !!profile;
  const location = useLocation();
  const sweepTutorialConfig = useFeature(FEATURE.TUTORIAL_SWEEP);

  const dataLoading = async () => {
    const _locale = locale as unknown as Locale;
    const request = await HomepageService.getData(!!getFirstVisit(), _locale);
    if (isSuccess(request)) {
      setMainState(request.data, _locale);
    }
  };

  useEffect(() => {
    if (!isAuth) {
      return;
    }
    setStatus(MainDataStatus.LOADING);
    dataLoading();
  }, [locale, isAuth]);

  const updateOffers = async (
    stateType: MAIN_STATE,
    requestType: TASK_TYPE
  ) => {
    const request = await OffersService.getTasks(requestType, locale);

    if (isSuccess(request)) {
      const topOffers =
        state[MAIN_STATE.TOP_OFFER_LIST]?.filter((top) =>
          request.data.items.some((offer) => offer.id === top.id)
        ) ?? [];

      update({
        [stateType]: request.data,
        [MAIN_STATE.TOP_OFFER_LIST]: topOffers,
      });
    }
  };

  useMessage(
    MESSAGE_SURVEYS,
    () => {
      updateOffers(MAIN_STATE.SURVEY_LIST, TASK_TYPE.SURVEY);
    },
    [state]
  );
  useMessage(
    MESSAGE_OFFERS,
    () => {
      updateOffers(MAIN_STATE.OFFER_LIST, TASK_TYPE.OFFER);
    },
    [state]
  );
  useMessage(
    MESSAGE_SWEEPSTAKE,
    async (data: Array<number>) => {
      const [id] = data;
      const response = await SweepstakesService.getSweepstake(id, locale);

      if (!isSuccess(response)) {
        return;
      }
      if (state[MAIN_STATE.SWEEP_MAIN]?.id === Number(id)) {
        update({ [MAIN_STATE.SWEEP_MAIN]: response.data });
        return;
      }
      if (!state[MAIN_STATE.SWEEP_LIST]) {
        return;
      }

      const updatedList = [...state[MAIN_STATE.SWEEP_LIST]?.items];
      const updatedSweepIndex = updatedList.findIndex(
        (sweepstake) => Number(sweepstake.id) === Number(id)
      );
      if (updatedSweepIndex !== -1) {
        updatedList[updatedSweepIndex] = response.data;

        update({
          [MAIN_STATE.SWEEP_LIST]: {
            ...state[MAIN_STATE.SWEEP_LIST],
            items: updatedList,
          },
        });
      }
    },
    [state]
  );

  useEffect(() => {
    const sweep = state[MAIN_STATE.SWEEP_MAIN];

    if (
      sweepTutorialConfig &&
      profile?.balance &&
      profile?.balance > 0 &&
      sweep?.applied?.by_me &&
      sweep?.applied?.by_me < 2
    ) {
      pushTask(GLOBAL_TASK.SWEEP_TUTORIAL);
    }
  }, [state[MAIN_STATE.SWEEP_MAIN], profile?.balance]);

  useEffect(() => {
    if (!profile) {
      clearState();
    }
  }, [profile]);

  return null;
};

export default UpdateMainStateMessagesProvider;

import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

import { reviewListKey } from './data';

const Reviews = () => {
  const intl = useIntl();

  return (
    <Box
      sx={{
        display: 'inline-flex',
        gap: '12px',
      }}
    >
      {reviewListKey.map((review, index) => (
        <Box
          key={index}
          sx={{
            background: '#FFFFFF url(/landing-quote.svg) no-repeat 30px 30px',
            borderRadius: '20px',
            width: '320px',
            padding: '80px 20px 20px',
            '& p': {
              textAlign: 'center',
              fontSize: '1.1em',
            },
          }}
        >
          <Typography>{intl.formatMessage(review.text)}</Typography>
          <Typography
            sx={{
              fontWeight: 700,
              marginTop: 3,
            }}
          >
            {intl.formatMessage(review.name)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Reviews;

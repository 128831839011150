import React, { useState, useEffect } from 'react';
import useTimeControl from 'src/hooks/usePopupV2/useTimeController';
import * as PopupService from 'src/services/PopupService';
import { getSelector } from 'src/hooks/usePopupV2/utils';

type useNewIClickProps = Required<
  Pick<
    FeaturePopupIpp,
    'interval' | 'excludes' | 'capping' | 'frequency' | 'delay'
  >
>;

type listner = <K extends keyof DocumentEventMap>(
  this: Document,
  ev: DocumentEventMap[K]
) => void;

export type ListItem = { zone: number; link: string };

const useIClickV2 = ({
  interval,
  excludes = [],
  frequency,
  capping,
  delay,
}: useNewIClickProps) => {
  const [linkQueue, setLinkQueue] = useState<ListItem[]>([]);
  const { getIsAllowedToShow, registerClick } = useTimeControl({
    capping,
    frequency,
    interval,
    delay,
  });

  useEffect(() => {
    if (!linkQueue?.length) return;
    const handleClick = (event: React.MouseEvent) => {
      const excluded = excludes.some((selector) =>
        (event.target as Element)?.closest(selector)
      );

      if (!excluded && getIsAllowedToShow()) {
        registerClick();
        window.open(linkQueue[0].link, '_blank')?.focus();
        setLinkQueue((currentQueue) => currentQueue.slice(1));
        PopupService.regClick(
            Number(linkQueue[0].zone),
            getSelector(event.target as Element),
            document.location.pathname.replace('/', '')
        ).then()
      }
    };

    document.addEventListener('click', handleClick as listner);

    return () => {
      document.removeEventListener('click', handleClick as listner);
    };
  }, [getIsAllowedToShow, linkQueue, interval, excludes, setLinkQueue]);

  return {
    linkQueue,
    setLinkQueue,
  };
};
export default useIClickV2;

import { makeStyles, shorthands } from '@griffel/react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#DBF4EB',
    ...shorthands.padding('8px'),
    alignItems: 'center',
    borderRadius: '8px',
    position: 'relative',
  },
  text: {
    color: '#11B479',
    fontSize: '14px',
    paddingRight: '32px',
  },
  textWithBoost: {
    color: '#11B479',
    fontSize: '14px',
    paddingRight: '64px',
  },
});

export default useStyles;

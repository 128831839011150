import { useRecoilSnapshot } from 'recoil';
import { useEffect } from 'react';
import { NODE_ENV } from 'src/constants/app';

const Observer = () => {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
};

function DebugObserver() {
  return NODE_ENV !== 'production' ? <Observer /> : null;
}
export default DebugObserver;

import { FunctionComponent } from 'react';

import Modal from 'src/components/Modal';
import EmailVerificationForm from './EmailVerificationForm';
import useVerificationModal from './useVerificationModal';
import useVerificationOffer from './useVerificationOffer';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close-white.svg';
import useStyles from './get-styles';

const EmailVerificationModal: FunctionComponent = () => {
  const { regClick, regImpression } = useVerificationOffer();
  const [isOpen, onCloseModal] = useVerificationModal(regImpression);
  const classes = useStyles();

  return (
    <Modal
      className={classes.root}
      isOpen={isOpen}
      handleClose={onCloseModal}
      CustomCloseIcon={CloseIcon}
      withClose
      width="100%"
      noPadding
      paperStyles={{
        animation: 'verticalTransition 0.5s',
        width: '100%',
        maxWidth: '100%',
        margin: 0,
        borderRadius: '32px 32px 0 0',
        alignItems: 'center',
      }}
      paperSx={(theme) => ({
        height: '400px',
        [theme.breakpoints.down('md')]: {
          height: 'calc(100vh - 300px)',
        },
      })}
      transitionStyles={{
        alignItems: 'flex-end',
      }}
    >
      <EmailVerificationForm clickCallback={regClick} />
    </Modal>
  );
};

export default EmailVerificationModal;

import { createPortal } from 'react-dom';
import React, { FunctionComponent, MouseEventHandler, useRef } from 'react';
import Box from '@mui/material/Box';

import PrizeModalContent from 'src/components/modal/BackButtonModal/PrizeModalContent';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import './style.scss';

type Props = {
  onClose: () => void;
  onApply: () => void;
};

const MODAL_ID = 'interstitial_modal';

export const openModal = () => {
  (document.getElementById(MODAL_ID) as HTMLDialogElement).showModal();
};
export const closeModal = () => {
  return (document.getElementById(MODAL_ID) as HTMLDialogElement).close();
};

const InterstitialModal: FunctionComponent<Props> = ({ onClose, onApply }) => {
  const ref = useRef<boolean>(false);
  const onClickClose: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    ref.current = true;
    onClose();
  };
  const onClickOk = () => {
    ref.current = true;
    onApply();
  };
  const onCloseDialog = () => {
    if (!ref.current) {
      onClose();
    }

    ref.current = false;
  };

  return createPortal(
    <dialog
      id={MODAL_ID}
      className="dialog-modal"
      onClose={onCloseDialog}
      aria-hidden="false"
    >
      <Box
        sx={{
          position: 'relative',
          height: '80%',
        }}
      >
        <PrizeModalContent
          handleClick={onClickOk}
          containerSx={{
            paddingBottom: '60px',
          }}
          scaleImage={1.5}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={onClickClose}
        >
          <CloseIcon width="20px" height="20px" />
        </Box>
      </Box>
    </dialog>,
    document.body
  );
};

export default InterstitialModal;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfileDispatch } from 'src/providers/ProfileProvider';
import { ROUTE_HOMEPAGE } from 'src/constants/routes';

export default function Logout() {
  const navigate = useNavigate();
  const { logout } = useProfileDispatch();

  useEffect(() => {
    const logoutAction = async () => {
      await logout();
      navigate(ROUTE_HOMEPAGE);
    };

    logoutAction().then();
  }, []);

  return null;
}

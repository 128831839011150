import { shouldShowError, shouldShowSuccess } from 'src/lib/validation';
import { ReactComponent as CircleInvalidIcon } from 'src/assets/icons/circle-invalid.svg';
import { ReactComponent as CheckedIcon } from 'src/assets/icons/circle-checked.svg';
import { ReactComponent as UncheckedIcon } from 'src/assets/icons/circle-unchecked.svg';

interface CheckIconProps<T> {
  formik: any;
  fieldName: keyof T;
  afterSubmitOnly?: boolean;
}

export default function CheckIcon<T>({
  formik,
  fieldName,
  afterSubmitOnly = false,
}: CheckIconProps<T>) {
  if (shouldShowError<T>(formik, fieldName)) {
    return <CircleInvalidIcon />;
  }

  if (shouldShowSuccess<T>(formik, fieldName, afterSubmitOnly)) {
    return <CheckedIcon />;
  }

  return <UncheckedIcon />;
}

interface FieldCheckIconProps {
  meta: any;
}

export function FieldCheckIcon({ meta }: FieldCheckIconProps) {
  if (meta.touched && Boolean(meta.error)) {
    return <CircleInvalidIcon />;
  }

  if (meta.touched && !Boolean(meta.error)) {
    return <CheckedIcon />;
  }

  return <UncheckedIcon />;
}

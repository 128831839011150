import { FunctionComponent, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { FormikProvider } from 'formik';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import { SOURCE_INFO } from 'src/components/TermsConditionSettings/constants';
import TCSettingsSourceCheckbox from 'src/components/TermsConditionSettings/TCSettingsSourceCheckbox';
import { FormikContextType } from 'formik/dist/types';
import { Values } from './types';

type Props = {
  onAcceptAll: () => void;
  handleMarketingClick: () => void;
  formikInstance: FormikContextType<Values>;
};

const TCSettingsForm: FunctionComponent<Props & PropsWithChildren> = ({
  onAcceptAll,
  handleMarketingClick,
  children,
  formikInstance,
}) => {
  const intl = useIntl();

  return (
    <FormikProvider value={formikInstance}>
      {SOURCE_INFO.map((info) => (
        <TCSettingsSourceCheckbox
          key={info.name}
          name={info.name}
          label={intl.formatMessage(info.message, {
            a1: (link: any) => (
              <Link
                className="fixed-color"
                onClick={(e) => {
                  e.preventDefault();
                  handleMarketingClick();
                }}
              >
                {link}
              </Link>
            ),
            a2: (link: any) => (
              <Link
                className="fixed-color"
                href="https://rewardis.online/opt-out"
                target="_blank"
              >
                {link}
              </Link>
            ),
          })}
        />
      ))}
      {children}
      <Button
        sx={{
          marginY: 2,
        }}
        onClick={() => onAcceptAll()}
        color="primary"
        variant="contained"
        fullWidth
      >
        {intl.formatMessage({
          id: 'tc_settings_modal.save_all',
          defaultMessage: 'Accept all and continue',
        })}
      </Button>
      <Button onClick={formikInstance.submitForm} color="secondary" fullWidth>
        {intl.formatMessage({
          id: 'tc_settings_modal.save',
          defaultMessage: 'Save selection',
        })}
      </Button>
    </FormikProvider>
  );
};

export default TCSettingsForm;

type AgeGender = {
  min: number;
  max: number;
  id: Record<1 | 2, string>;
};

const idByAgeGender: Array<AgeGender> = [
  {
    min: 0,
    max: 25,
    id: {
      1: '6e55e00652d13b827e7d315a7847a88957cba77fb7b1d616211efd9497d6db13',
      2: '0fc40592bcbcc71dae3085787b19b6b2684fe3d9b360b835f1be739a39adf369',
    },
  },
  {
    min: 25,
    max: 35,
    id: {
      1: '8222c51011b7c300f7a8987119dd8a010959c4c9d1e1609e752a53ff4652af69',
      2: '3f57dc9699bc8115fc9a522f6ca9a53b3bad7e618a416312a62c3760a4e1cd84',
    },
  },
  {
    min: 35,
    max: 45,
    id: {
      1: 'ee70e0568ffd75a5871434afd79559f37d5852a32a3f5299ef03427a180a94ad',
      2: '90ba25d040e179fba2acb2990ed1b4c7d69cd4ad8f09359e4f901c2694cf92fd',
    },
  },
  {
    min: 45,
    max: 55,
    id: {
      1: 'a3fba3873e79522398c6d2c8b604a23ea405f3cf37ec61d4c023c1c4216c5e59',
      2: 'c349d959272484071c1ef4cdf6400ff0e94054fdba0dd8c7ba0acb959f1e30ac',
    },
  },
  {
    min: 55,
    max: 65,
    id: {
      1: '354226f4fe6d22592947880bfb64c60fde8d558dfab7d99cdc47ba29a0b47691',
      2: '9be078190ff272cc753200a3843fc239a09cf818b2da3fc059c028d937b43d3f',
    },
  },
  {
    min: 65,
    max: 1000,
    id: {
      1: 'a363a5ff8fb4c0cf1bde3a0591f2186e37332949580268ed7d142abb5b767b50',
      2: '6daa3eb335b6c1f03daa05b41ce115ba189a9f7c5548fc95285b28ea7f3d8678',
    },
  },
];

export const getAuditoryId = (gender: Gender, birthday: string): string => {
  const yearsOld =
    (new Date().getTime() - new Date(birthday).getTime()) /
    (1000 * 3600 * 24 * 365.25);
  const byAge = idByAgeGender.find(({ min, max }) => {
    return yearsOld >= min && yearsOld < max;
  });

  return byAge ? byAge.id[gender as 1 | 2] : '';
};

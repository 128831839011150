import { Box } from '@mui/material';

import { ReactComponent as Logo } from 'src/assets/logo-white.svg';

const LoadingHeader = () => {
  return (
    <Box
      sx={{
        padding: 4,
        background: '#07091D',
        position: 'fixed',
        width: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: 450,
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            marginBottom: 3,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Logo width="150px" height="33px" />
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingHeader;

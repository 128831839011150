const KEY_NAME = 'gtm';

type propertyName = keyof GTM;

export function set(name: propertyName, value: any) {
  if (!window[KEY_NAME]) {
    window[KEY_NAME] = {} as GTM;
  }

  const gtm = window[KEY_NAME];

  gtm[name] = value;
}

export enum BROWSER {
  FACEBOOK = 'facebook',
  CHROME = 'chrome',
  SAFARI = 'safari',
  OTHER = 'other',
}

export const BROWSER_MAP: Omit<Record<BROWSER, RegExp>, 'other'> = {
  [BROWSER.FACEBOOK]: /\bFB[\w_]+\//i,
  [BROWSER.CHROME]:
    /\b(?:Chrome|CriOS|CrMo)\/[.0-9]*\b(?!.*Edge|OPR|Edg|EdgiOS)/i,
  [BROWSER.SAFARI]:
    /\bSafari\/[.0-9]*\b(?!.*Chrome|CriOS|CrMo|Edge|OPR|Edg|EdgiOS)/i,
};

export const BROWSER_INTENT_MAP: Partial<Record<BROWSER, string>> = {
  [BROWSER.CHROME]: 'com.android.chrome',
};

export const MOBILES_REGEXP =
  /(iPad|iPhone|Android|Mobile|webOS|iPod|BlackBerry|IEMobile|Opera Mini)/i;

export enum OPERATIONS_SYSTEMS {
  WINDOWS = 'Windows',
  MAC_OS = 'macOS',
  UNIX = 'UNIX',
  LINUX = 'Linux',
  ANDROID = 'Android',
  IOS = 'IOS',
}

export const OPERATIONS_SYSTEMS_MAP: Record<OPERATIONS_SYSTEMS, RegExp> = {
  [OPERATIONS_SYSTEMS.WINDOWS]: /Win/,
  [OPERATIONS_SYSTEMS.MAC_OS]: /Mac/,
  [OPERATIONS_SYSTEMS.UNIX]: /X11/,
  [OPERATIONS_SYSTEMS.ANDROID]: /Android/,
  [OPERATIONS_SYSTEMS.IOS]: /iPad|iPhone|iPod/,
  [OPERATIONS_SYSTEMS.LINUX]: /Linux/,
};

import { FunctionComponent, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';

import * as FirstNameField from 'src/components/forms/RegistationForm/fields/FirstNameField';
import * as LastNameField from 'src/components/forms/RegistationForm/fields/LastNameField';
import * as EmailField from 'src/components/forms/RegistationForm/fields/EmailField';
import * as PasswordField from 'src/components/forms/RegistationForm/fields/PasswordField';
import * as BirthdayFields from 'src/components/forms/RegistationForm/fields/BirthdayFields';
import * as GenderField from 'src/components/forms/RegistationForm/fields/GenderField';
import * as PhoneField from 'src/components/forms/RegistationForm/fields/PhoneField';
import * as ZipFields from 'src/components/forms/RegistationForm/fields/ZipFields';
import { FieldName } from 'src/components/forms/RegistationForm/typings';

type Props = {
  fields: FieldName[];
  recaptchaVersion?: RecaptchaVersion;
};

const FieldRow: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <Box sx={{ marginY: 1, width: '100%' }}>{children}</Box>;
};

const UserFields: FunctionComponent<Props> = ({ fields, recaptchaVersion }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {(fields.includes('first_name') || fields.includes('last_name')) && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: 1.5,
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              gap: 1,
            },
          })}
        >
          {fields.includes('first_name') ? (
            <FirstNameField.Component name="first_name" autoFocus />
          ) : null}
          {fields.includes('last_name') ? (
            <LastNameField.Component name="last_name" />
          ) : null}
        </Box>
      )}
      {fields.includes('email') ? (
        <FieldRow>
          <EmailField.Component name="email" />
        </FieldRow>
      ) : null}
      {fields.includes('password') ? (
        <FieldRow>
          <PasswordField.Component name="password" defaultMessage="Password" />
        </FieldRow>
      ) : null}
      {fields.includes('confirm_password') ? (
        <FieldRow>
          <PasswordField.Component
            name="confirm_password"
            defaultMessage="Confirm password"
          />
        </FieldRow>
      ) : null}
      {fields.includes('birth_date') ? (
        <FieldRow>
          <BirthdayFields.Component name="birth_date" touchRequired />
        </FieldRow>
      ) : null}
      {fields.includes('gender') ? (
        <FieldRow>
          <GenderField.Component name="gender" />
        </FieldRow>
      ) : null}
      {fields.includes('phone') ? (
        <Box sx={{ marginY: 1 }}>
          <PhoneField.Component name="phone" />
        </Box>
      ) : null}
      {fields.includes('postcode') ? (
        <Box sx={{ marginY: 1 }}>
          <ZipFields.Component name="postcode" />
        </Box>
      ) : null}
    </Box>
  );
};

export default UserFields;

import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import { initialSettingsValues } from 'src/components/TermsConditionSettings/constants';
import { Values } from './types';
import TCSettingsForm from 'src/components/TermsConditionSettings/TCSettingsForm';

type Props = {
  onSave: (isRejectedAny: boolean, isRejectedAll?: boolean) => void;
  handleMarketingClick: () => void;
};

const TCSettings: FunctionComponent<Props> = ({
  onSave,
  handleMarketingClick,
}) => {
  const formik = useFormik({
    initialValues: initialSettingsValues,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const isRejectedAny = Object.values(values).some((value) => !value);
      const isRejectedAll =
        Object.values(values).filter((value) => value).length === 0;

      onSave(isRejectedAny, isRejectedAll);
    },
  });

  return (
    <TCSettingsForm
      onAcceptAll={() => onSave(false)}
      handleMarketingClick={handleMarketingClick}
      formikInstance={formik}
    />
  );
};

export default TCSettings;

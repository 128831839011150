import { PropsWithChildren, useEffect, useState } from 'react';

import {
  googleLogout,
  GoogleOAuthProvider,
  TokenResponse,
  useGoogleLogin,
} from '@react-oauth/google';
import { smartlookTrackEvent } from 'src/services/GoogleTagManagerService';
import { SMARTLOOK_CUSTOM_EVENT } from 'src/constants/enums';
import * as ImpressionService from 'src/services/ImpressionService';
import { useRecoilValue } from 'recoil';
import { registrationParamsGetState } from 'src/recoil/registrationParams';
import {
  getGoogleAccountInfo,
  getGooglePersonalData,
} from 'src/services/AuthService';
import {
  EXTERNAL_AUTH,
  useUpdateExternalAuthProfile,
} from 'src/recoil/externalAuth';

const clientId =
  '131248382018-e0pfb6btstu9rhvqm4mc0sleugiacrhu.apps.googleusercontent.com';

type PartialProfile = {
  first_name: string;
  last_name: string;
  email: string;
  birth_date?: string;
  gender?: Gender;
  avatar?: string;
};

export function useGoogleAuthDispatch(
  withExtraScope: boolean = false,
  extraData: {}
) {
  const updater = useUpdateExternalAuthProfile();
  const login = useGoogleAuthRequest((profile) => {
    updater(EXTERNAL_AUTH.GOOGLE, { ...extraData, ...profile });
  }, withExtraScope);

  return {
    login: () => {
      smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.GOOGLE_AUTH);
      login();
    },
    logout: () => {
      googleLogout();
    },
  };
}

const getGoogleExtraScope = async (accessToken: string) => {
  const data: {
    birth_date?: string;
    gender?: Gender;
  } = {};

  const fields = 'birthdays,genders';
  const peopleData = await getGooglePersonalData(accessToken, fields);
  if (!peopleData) {
    return data;
  }
  const rawGender = peopleData?.genders?.find((gender: any) => gender.value);
  switch (rawGender?.value) {
    case 'male':
      data.gender = 1;
      break;
    case 'female':
      data.gender = 2;
      break;
  }
  const rawBirthday = peopleData?.birthdays?.find(
    (birthday: any) =>
      birthday.date.year && birthday.date.month && birthday.date.day
  );
  if (rawBirthday) {
    const { year, month, day } = rawBirthday.date;

    data.birth_date = `${year}-${month}-${day}`;
  }

  return data;
};

function useGoogleAuthRequest(
  onProfile: (profile: PartialProfile) => void,
  withExtraScope: boolean = false
) {
  const [authResponse, setAuthResponse] = useState<TokenResponse>();
  const regParams = useRecoilValue(registrationParamsGetState);

  const login = useGoogleLogin({
    scope: withExtraScope
      ? 'https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read'
      : undefined,
    onSuccess: (tokenResponse) => setAuthResponse(tokenResponse),
  });

  useEffect(() => {
    (async () => {
      if (!authResponse || !regParams || !regParams?.reg_imp_id) {
        return;
      }
      const userInfoData = await getGoogleAccountInfo(
        authResponse.access_token
      );
      if (!userInfoData) {
        return;
      }

      const profileFields: PartialProfile = {
        email: userInfoData.email,
        first_name: userInfoData.given_name,
        last_name: userInfoData.family_name,
        avatar: userInfoData.picture,
        ...(withExtraScope
          ? await getGoogleExtraScope(authResponse.access_token)
          : {}),
      };

      onProfile(profileFields);
      await ImpressionService.saveRegImpressionId(
        authResponse.access_token,
        regParams.reg_imp_id
      );
    })();
  }, [authResponse, regParams]);

  return login;
}

export default function ProfileProvider({ children }: PropsWithChildren) {
  return (
    <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>
  );
}

import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SwitchLanguage from 'src/components/SwitchLanguage';
import SidebarMenu from 'src/layouts/_SidebarMenu';
import logoImage from 'src/assets/logo.svg';

interface HeaderProps {
  hasColumn?: boolean;
}

export default function Header({ hasColumn = false }: HeaderProps) {
  const [isMenuOpened, setMenuOpened] = useState<boolean>(false);

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: '1px solid #f0f0f0',
        alignSelf: 'baseline',
      }}
    >
      <SidebarMenu isMenuOpened={isMenuOpened} setMenuOpened={setMenuOpened} />

      <Container
        component="header"
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '65px',
        }}
      >
        <Button
          onClick={() => setMenuOpened(true)}
          sx={{
            minWidth: '32px',
            display: { xs: 'flex', sm: 'none' },
          }}
        >
          <MenuIcon sx={{ color: '#07091D' }} fontSize="medium" />
        </Button>

        <Typography variant="h5" color="inherit">
          <Link to="/">
            <img src={logoImage} style={{ width: '98px', height: '18px' }} />
          </Link>
        </Typography>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <SwitchLanguage fullWidth={hasColumn} />
        </Box>
      </Container>
    </Box>
  );
}

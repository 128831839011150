import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { FieldHookConfig, useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FieldCheckIcon } from 'src/components/forms/CheckIcon';
import { ValidationSchemaProps } from '../typings';

export const initialValue = '';

const MIN_LIMIT = 2;
const MAX_LIMIT = 128;

export const createValidationSchema = ({
  intl,
  blacklist = [],
}: ValidationSchemaProps) =>
  yup
    .string()
    .trim()
    .required(
      intl.formatMessage({
        id: 'form.validation.required.lastName',
        defaultMessage: 'This field is required',
      })
    )
    .test(
      'blacklist',
      intl.formatMessage({
        id: 'form.validation.blacklist',
        defaultMessage: 'Not allowed value',
      }),
      (value) => {
        return !blacklist.includes(value || '');
      }
    )
    .min(
      MIN_LIMIT,
      intl.formatMessage(
        {
          id: 'form.validation.min',
          defaultMessage: 'The minimum field length is {value}',
        },
        { value: MIN_LIMIT }
      )
    )
    .max(
      MAX_LIMIT,
      intl.formatMessage(
        {
          id: 'form.validation.max',
          defaultMessage: 'The maximum field length is {value}',
        },
        { value: MAX_LIMIT }
      )
    );

export function Component(props: FieldHookConfig<string>) {
  const [field, meta, helpers] = useField(props);
  const intl = useIntl();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={Boolean(meta.touched && meta.error)}
    >
      <OutlinedInput
        {...field}
        fullWidth
        placeholder={intl.formatMessage({
          id: 'form.last_name',
          defaultMessage: 'Last name',
        })}
        id={field.name}
        endAdornment={
          <InputAdornment position="end">
            <FieldCheckIcon meta={meta} />
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

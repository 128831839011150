import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import AmountTickets from 'src/components/AmountTickets';
import Typography from '@mui/material/Typography';
import ModalTaskListButton from 'src/components/Tasks/ModalTaskListButton';
import AwardTickets from 'src/components/AwardTickets';

export type TaskModalContentProps = {
  source: string;
  task: Task;
  applyTask: (source: string, task: Task) => Promise<void>;
  boost?: number;
};

export const TaskModalContent: FunctionComponent<TaskModalContentProps> = ({
  source,
  task,
  applyTask,
  boost,
}) => {
  const intl = useIntl();
  return (
    <Box
      sx={() => ({
        maxWidth: 320,
      })}
    >
      <Box
        sx={() => ({
          position: 'relative',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${task.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: '#bdbdbd',
          width: '100%',
          height: 180,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 50%)',
            bottom: '0px',
          }}
        >
          <AwardTickets
            type={task.type!}
            amount={task.amount}
            boostAmount={boost}
          />
        </Box>
      </Box>

      <Box sx={{ padding: 2, marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '1.1em',
          }}
        >
          {task.title}
        </Typography>
        <Typography
          color="secondary"
          sx={{
            marginBottom: 1,
            wordBreak: 'break-all',
          }}
          dangerouslySetInnerHTML={{
            __html: task.description.replace(/(\r)?\n/g, '<br />'),
          }}
        />
        <ModalTaskListButton
          source={`${source}.task-modal`}
          task={task}
          applyTask={applyTask}
        />
      </Box>
    </Box>
  );
};

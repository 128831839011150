import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  failure,
  loading,
  notAsked,
  RemoteData,
  RemoteDataResult,
} from 'src/lib/remoteData';

interface RequestManager<S, F> {
  reload: () => void;
  reloadAsync: () => Promise<RemoteDataResult<S, F>>;
  reloadAsyncSoftly: () => Promise<RemoteDataResult<S, F>>;
}

export default function useRequest<S = any, F = any>(
  request: () => Promise<RemoteDataResult<S, F>>,
  deps: ReadonlyArray<any> = []
): [RemoteData<S, F>, RequestManager<S, F>] {
  const [remoteData, setRemoteData] = useState<RemoteData<S, F>>(notAsked);
  const [reloadsCount, setReloadsCount] = useState<number>(0);

  const load = useCallback(async () => {
    try {
      return await request();
    } catch (err: any) {
      return failure(err.response ? err.response.data : err.message);
    }
  }, deps);

  useEffect(() => {
    (async () => {
      setRemoteData(loading);
      setRemoteData(await load());
    })();
  }, [...deps, reloadsCount, load]);

  const reload = useCallback(() => setReloadsCount((x) => x + 1), []);

  const reloadAsync = useCallback(async () => {
    setRemoteData(loading);
    const response = await load();
    setRemoteData(response);

    return response;
  }, [...deps, load]);

  const reloadAsyncSoftly = useCallback(async () => {
    const response = await load();
    setRemoteData(response);

    return response;
  }, [...deps, load]);

  const manager = useMemo(
    () => ({ reload, reloadAsync, reloadAsyncSoftly }),
    [reload, reloadAsync, reloadAsyncSoftly]
  );

  return [remoteData, manager];
}

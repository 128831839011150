import * as storage from 'src/lib/storage';

const STORAGE_TOKEN_KEY = 'rw.token';

export function getToken() {
  return storage.get(STORAGE_TOKEN_KEY);
}

export function setToken(newToken: Token) {
  storage.set(STORAGE_TOKEN_KEY, newToken);
}

export function resetToken() {
  storage.set(STORAGE_TOKEN_KEY, '');
}

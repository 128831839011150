import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer, useRef,
} from 'react';
import io, { Socket } from 'socket.io-client';
import { WS_URL } from 'src/constants/app';

type State = {
  socket?: Socket;
  callbacks: Record<string, Function[]>;
};

type Dispatch = (action: Action) => void;
type Action =
  | { type: 'create'; payload: { socket: Socket } }
  | { type: 'message'; payload: { eventName: string; data: any } }
  | { type: 'sendIdentity'; payload: { userId: number } }
  | { type: 'addCallback'; payload: { eventName: string; callback: Function } }
  | {
      type: 'removeCallback';
      payload: { eventName: string; callback: Function };
    };

const initialState = {
  callbacks: {},
};

const ConnectionStateContext = createContext<State | undefined>(initialState);
const ConnectionDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

function connectionReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'create': {
      return {
        ...state,
        socket: action.payload.socket,
      };
    }

    case 'sendIdentity': {
      if (state.socket && action.payload.userId) {
        state.socket.emit('identify', { userId: action.payload.userId });
      }
      return state;
    }

    case 'addCallback': {
      const newEventCallbacks =
        action.payload.eventName in state.callbacks
          ? [
              ...state.callbacks[action.payload.eventName],
              action.payload.callback,
            ]
          : [action.payload.callback];

      return {
        ...state,
        callbacks: {
          ...state.callbacks,
          [action.payload.eventName]: newEventCallbacks,
        },
      };
    }

    case 'removeCallback': {
      const newEventCallbacks =
        action.payload.eventName in state.callbacks
          ? state.callbacks[action.payload.eventName].filter(
              (cb) => cb !== action.payload.callback
            )
          : [];

      return {
        ...state,
        callbacks: {
          ...state.callbacks,
          [action.payload.eventName]: newEventCallbacks,
        },
      };
    }

    case 'message': {
      if (action.payload.eventName in state.callbacks) {
        state.callbacks[action.payload.eventName].forEach((cb) =>
          cb(action.payload.data)
        );
      }

      return {
        ...state,
      };
    }

    default:
      throw new Error(`Unhandled action`);
  }
}

export function useWebsocket() {
  const dispatch = useContext(ConnectionDispatchContext);
  const state = useContext(ConnectionStateContext);

  if (dispatch === undefined || state === undefined) {
    throw new Error('sendIdentity must be used within a WebsocketProvider');
  }

  return {
    isConnected: !!state.socket,
    sendIdentity: (profile: Profile) =>
      dispatch({
        type: 'sendIdentity',
        payload: { userId: Number(profile.id) },
      }),
  };
}

export function useMessage(
    eventName: string,
    callback: Function,
    deps: any[] = []
) {
  const dispatch = useContext(ConnectionDispatchContext);
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback, ...deps]);

  useEffect(() => {
    if (dispatch === undefined) {
      throw new Error('useMessage must be used within a WebsocketProvider');
    }

    const wrappedCallback = (...args: any[]) => {
      callbackRef.current(...args);
    };

    dispatch({ type: 'addCallback', payload: { eventName, callback: wrappedCallback } });

    return () => {
      dispatch({ type: 'removeCallback', payload: { eventName, callback: wrappedCallback } });
    };
  }, [eventName, dispatch]);
}

export default function WSConnectionProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(connectionReducer, initialState);

  useEffect(() => {
    if (typeof io !== 'function') {
      return;
    }
    const socket = io(WS_URL, {
      transports: ['websocket'],
    });

    socket.on('error', console.error);

    socket.on('connect', function open() {
      dispatch({
        type: 'create',
        payload: {
          socket,
        },
      });
    });

    socket.on('notify', function message(eventName, ...data) {
      const delay = data.find((value) => value?.delay)?.delay;
      const notify = () =>
        dispatch({
          type: 'message',
          payload: {
            eventName,
            data,
          },
        });

      if (delay) {
        setTimeout(() => {
          notify();
        }, delay * 1000);
      } else {
        notify();
      }
    });
  }, []);

  return (
    <ConnectionStateContext.Provider value={state}>
      <ConnectionDispatchContext.Provider value={dispatch}>
        {children}
      </ConnectionDispatchContext.Provider>
    </ConnectionStateContext.Provider>
  );
}

import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { FieldHookConfig, useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldCheckIcon } from 'src/components/forms/CheckIcon';
import { ValidationSchemaProps } from '../typings';

const regexp: RegExp = /\+[0-9\s()-]{10,17}/;

export const initialValue = '';

export const createValidationSchema = ({
  phone,
  intl,
}: ValidationSchemaProps) => {
  const basic = yup.string();
  const validation = phone
    ? basic.required(
        intl.formatMessage({
          id: 'form.validation.required',
          defaultMessage: 'This field is required',
        })
      )
    : basic.nullable();

  return validation.matches(
    regexp,
    intl.formatMessage({
      id: 'form.validation.phone',
      defaultMessage:
        'We accept "Mobile phone" number in full international format includes a plus sign (+), country code, city code, local phone number (10 - 12 digits). Make sure to remove any leading null signs or special calling codes.',
    })
  );
};

export function Component(
  props: { disabled?: boolean } & FieldHookConfig<string>
) {
  const { disabled = false } = props;
  const [field, meta, helpers] = useField(props);
  const intl = useIntl();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={Boolean(meta.touched && meta.error)}
    >
      <OutlinedInput
        {...field}
        disabled={disabled}
        fullWidth
        placeholder={intl.formatMessage({
          id: 'form.phone',
          defaultMessage: 'Phone',
        })}
        endAdornment={
          <InputAdornment position="end">
            <FieldCheckIcon meta={meta} />
          </InputAdornment>
        }
      />
      <FormHelperText
        sx={{
          height: 0,
          '&.Mui-error': {
            height: 'auto',
          },
        }}
      >
        {meta?.error}
      </FormHelperText>
    </FormControl>
  );
}

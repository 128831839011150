import { RemoteDataResult, failure, success } from 'src/lib/remoteData';

const url = 'https://my.rtmark.net/gid.js';

export interface SyncResponse {
  gid: string;
  skipSubscribe: boolean;
}

export async function sync(): Promise<RemoteDataResult<SyncResponse>> {
  try {
    const response = await fetch(url, { credentials: 'include' });
    const data = await response.json();

    return success(data);
  } catch (err: any) {
    return failure(err);
  }
}

import * as storage from 'src/lib/storage';

const KEY = 'reg_flow_remained';
const REG_PRIZE_KEY = 'reg_prize';

export const RegFlowService = {
  get: (): number => {
    return storage.get(KEY) ?? 0;
  },
  update: (step: number) => {
    storage.set(KEY, step);
  },
  clear: () => {
    storage.reset(KEY);
  },
};

export const RegPrizeService = {
  setState: (data: Record<string, any>) => {
    storage.set(REG_PRIZE_KEY, data);
  },
  getState: (): {
    image?: string;
    icon?: string;
    isNewDesign?: boolean;
    offerId?: number;
  } => storage.get(REG_PRIZE_KEY, {}),
  clearState: () => {
    storage.reset(REG_PRIZE_KEY);
  },
};

export const clearAfterReg = () => {
  RegPrizeService.clearState();
  RegFlowService.clear();
};

import { useEffect, useRef } from 'react';

import { getRegistrationUrlParams } from 'src/lib/url';
import { setupPushTag } from 'src/lib/extraScripts';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import { useProfileState } from 'src/providers/ProfileProvider';

const PUSH_TAG_INJECT_TIMEOUT = 30_000;

const usePushRegSubscription = () => {
  const pushRegInjected = useRef<boolean>(false);
  const featurePushReg = useFeature(FEATURE.PUSH_REG);
  const { profile } = useProfileState();

  useEffect(() => {
    if (
      featurePushReg &&
      !profile &&
      !pushRegInjected.current &&
      'Notification' in window &&
      Notification.permission !== 'denied'
    ) {
      const { sub_id1 } = getRegistrationUrlParams();

      const injectPush = async () => {
        if (!pushRegInjected.current) {
          setupPushTag(
            '',
            sub_id1 ?? '',
            featurePushReg.zone,
            featurePushReg.link
          );
          pushRegInjected.current = true;
        }
      };

      const timeoutId = setTimeout(
        injectPush,
        featurePushReg.interval
          ? featurePushReg.interval * 1_000
          : PUSH_TAG_INJECT_TIMEOUT
      );

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [Boolean(profile), featurePushReg]);
};

export default usePushRegSubscription;

import { SxProps, Theme, Typography } from '@mui/material';
import greenBackgroundImage from 'src/assets/ticket-green.svg';
import whiteBackgroundImage from 'src/assets/ticket-white.svg';

interface AmountTicketsProps {
  amount: number | string;
  isWhite?: boolean;
  sx?: SxProps<Theme>;
}

export default function AmountTickets({
  amount,
  sx,
  isWhite,
}: AmountTicketsProps) {
  const white = !Boolean(amount) || isWhite;

  return (
    <Typography
      textAlign="center"
      sx={{
        color: white ? '#97A6B2' : '#fff',
        paddingY: 0.5,
        paddingX: 1.5,
        fontSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${
          white ? whiteBackgroundImage : greenBackgroundImage
        })`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        ...sx,
      }}
    >
      {amount}
    </Typography>
  );
}

export const reviewListKey = [
  {
    name: {
      id: 'review.1.name',
      defaultMessage: 'Lucy Parks',
    },
    text: {
      id: 'review.1.text',
      defaultMessage:
        "It's a great way to get some nice stuff. Earn tickets is pretty easy, and the choice of gifts is fantastic!",
    },
  },
  {
    name: {
      id: 'review.2.name',
      defaultMessage: 'Matt',
    },
    text: {
      id: 'review.2.text',
      defaultMessage:
        'This really works! I thought it was a scam, but I actually won a gift card😍',
    },
  },
  {
    name: {
      id: 'review.3.name',
      defaultMessage: 'MJ',
    },
    text: {
      id: 'review.3.text',
      defaultMessage:
        "I certainly will use this service again. I've won an Amazon gift card, and now I'm dreaming of an Apple Watch...😄",
    },
  },
  {
    name: {
      id: 'review.4.name',
      defaultMessage: 'Paola',
    },
    text: {
      id: 'review.4.text',
      defaultMessage:
        'I love that their tasks are super easy! You need to just spend a few minutes to get a lot of tickets. And the prizes are pretty good!',
    },
  },
  {
    name: {
      id: 'review.5.name',
      defaultMessage: 'Ross K.',
    },
    text: {
      id: 'review.5.text',
      defaultMessage:
        'Amazing site! Just got myself another gift card. They offer really good gift cards instead of crappy ones like in other places. ',
    },
  },
  {
    name: {
      id: 'review.6.name',
      defaultMessage: 'J_Jones',
    },
    text: {
      id: 'review.6.text',
      defaultMessage:
        'I spend a lot of time on this site lately, and I love it! An easy way to convert your spare time into cool gifts!',
    },
  },
];

import * as yup from 'yup';
import { memo, useCallback } from 'react';
import { FieldHookConfig, useField } from 'formik';
import { IntlShape, useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import GenderControlField from './GenderControlField';

const GENDER_MALE = 1;
const GENDER_FEMALE = 2;

export const initialValue = null;

interface ValidationSchemaProps {
  intl: IntlShape;
}

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .mixed()
    .nullable(true)
    .oneOf([1, 2], (values) => {
      const value = parseInt(values.value, 10);
      if (((values as any).resolved || []).indexOf(value) == -1) {
        return intl.formatMessage({
          id: 'form.validation.required.gender',
          defaultMessage: 'This field is required',
        });
      }
    })
    .required(
      intl.formatMessage({
        id: 'form.validation.required.gender',
        defaultMessage: 'This field is required',
      })
    );

export function Component(props: FieldHookConfig<number>) {
  const intl = useIntl();
  const [_, meta, helpers] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);

  const handleGenderMaleClick = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue(GENDER_MALE);
  }, [helpers]);

  const handleGenderFemaleClick = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue(GENDER_FEMALE);
  }, [helpers]);

  return (
    <FormControl
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
        alignItems: 'center',
      }}
    >
      <FormLabel
        sx={(theme) => ({
          flex: 1,
          fontSize: '1em',
          fontWeight: theme?.custom?.control?.fontWeight ?? 400,
          [theme.breakpoints.up('md')]: {
            flex: 1.5,
          },
        })}
      >
        {intl.formatMessage({
          id: 'form.gender',
          defaultMessage: 'Gender',
        })}
      </FormLabel>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          width: '100%',
          flex: 4,
        }}
      >
        <GenderControlField
          type="male"
          hasError={hasError}
          handleClick={handleGenderMaleClick}
          isSelected={meta.value == GENDER_MALE}
          label={intl.formatMessage({
            id: 'form.male',
            defaultMessage: 'Male',
          })}
          id="gender_button-male"
        />
        <GenderControlField
          type="female"
          hasError={hasError}
          handleClick={handleGenderFemaleClick}
          isSelected={meta.value == GENDER_FEMALE}
          label={intl.formatMessage({
            id: 'form.female',
            defaultMessage: 'Female',
          })}
          id="gender_button-female"
        />
      </Box>
    </FormControl>
  );
}

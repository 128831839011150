import { useLocation } from 'react-router';

import { useProfileState } from 'src/providers/ProfileProvider';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import EmailVerificationModal from 'src/components/VerificationEmailModal/EmailVerificationModal';
import { ROUTE_POSTREG_TASKS } from 'src/constants/routes';
import { useGlobalTasksListener } from 'src/providers/GlobalTasksProvider';

const EmailVerificationProvider = () => {
  const { profile } = useProfileState();
  const { task } = useGlobalTasksListener();
  const config = useFeature(FEATURE.EMAIL_CONFIRMATION);
  const location = useLocation();

  return config &&
    profile &&
    !task &&
    !profile?.is_confirmed &&
    location.pathname !== ROUTE_POSTREG_TASKS ? (
    <EmailVerificationModal />
  ) : (
    <></>
  );
};

export default EmailVerificationProvider;

const calculateBirthYear = (birth_date: string) => {
  return parseInt(birth_date.split('-')[0], 10) || 0;
};

export const getPopupZones = (
  config: UserZonesConfig,
  profile: Profile
): number[] => {
  try {
    if (!profile?.birth_date || !config?.zones_female || !config?.zones_male)
      return [];

    const year =
      new Date().getFullYear() - calculateBirthYear(profile.birth_date);

    const key = year >= 25 ? 'gte25' : 'lt25';
    const genderZones =
      (profile.gender === 2 ? config.zones_female : config.zones_male) || {};

    return genderZones[key] || [];
  } catch (e) {
    console.warn(e);
    return [];
  }
};

import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { pushNavigateEvent } from 'src/services/GoogleTagManagerService';
import { useProfileState } from 'src/providers/ProfileProvider';

const ObserveLocationProvider = () => {
  const location = useLocation();
  const { profile } = useProfileState();

  useEffect(() => {
    pushNavigateEvent(window.location.href, profile?.id);
  }, [location]);

  return null;
};

export default ObserveLocationProvider;

type RegistrationSubParam =
  | 'sub_id1'
  | 'sub_id2'
  | 'sub_id3'
  | 'sub_id4'
  | 'sub_id5'
  | 'sub_id6'
  | 'sub_id7'
  | 'sub_id8'
  | 'ab2r'
  | 'oaid';

type RegistrationParam =
  | RegistrationSubParam
  | 'partner_id'
  | 'reg_offer_id'
  | 'design_id'
  | 'ext_data';

type RegistrationSubParams = {
  [K in RegistrationSubParam]?: string;
};

type RegistrationParams = RegistrationSubParams; /* {
    partner?: number
} & RegistrationSubParams;
*/

const stringParams: RegistrationParam[] = [
  'sub_id1',
  'sub_id2',
  'sub_id3',
  'sub_id4',
  'sub_id5',
  'sub_id6',
  'sub_id7',
  'sub_id8',
  'ext_data',
  'ab2r',
  'oaid',
];

const numberParams: RegistrationParam[] = [
  'partner_id',
  'reg_offer_id',
  'design_id',
];

function normalizeParamName(name: string): string {
  return name.replace(/_/g, '').toLowerCase();
}

const normalizedParams = [...stringParams, ...numberParams].reduce<
  Record<string, string>
>((acc, item) => {
  acc[normalizeParamName(item)] = item;
  return acc;
}, {});

export const getParams = (
  searchParams: IterableIterator<[string, string]>
): Record<string, string> => {
  const acc: Record<string, string> = {};

  for (const [searchParamKey, searchParamValue] of searchParams) {
    const normalizedSearchParam = normalizeParamName(searchParamKey);
    const paramName = normalizedParams[normalizedSearchParam];
    if (paramName) {
      acc[paramName] = searchParamValue;
    }
  }

  return acc;
};

export function getRegistrationUrlParams(): Record<string, any> {
  const searchParams = new URLSearchParams(window.location.search);
  return getParams(searchParams.entries());
}

export function clearUrlParams() {
  history.replaceState(
    null,
    document.title,
    window.location.href.replace(window.location.search, '')
  );
}

import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FunctionComponent, useEffect, useState } from 'react';

import * as VerificationService from 'src/services/VerificationService';
import { isFailure, isSuccess } from 'src/lib/remoteData';
import Countdown from 'src/components/VerificationEmailModal/Countdown';

import bgImage from 'src/assets/confirmation/confirmation-bg.png';
import icon from 'src/assets/confirmation/aircraft-ticket.png';
import iconSmall from 'src/assets/confirmation/aircraft-small.png';
import ticketImage from 'src/assets/icons/ticket.svg';

enum State {
  empty,
  loading,
  success,
  error,
}
const RESENDING_TIMEOUT = 60_000;

const Message: FunctionComponent<{ state: State }> = ({ state }) => {
  const intl = useIntl();

  switch (state) {
    case State.empty:
    default:
      return (
        <Typography
          typography="p"
          sx={(theme) => ({
            color: theme.palette.text.disabled,
          })}
        >
          {intl.formatMessage({
            id: 'verificationPopup.resendPhrase',
            defaultMessage: "Didn't receive verification email?",
          })}
        </Typography>
      );
    case State.success:
      return (
        <Typography
          typography="p"
          sx={(theme) => ({
            color: theme.palette.success.main,
          })}
        >
          {intl.formatMessage({
            id: 'verificationPopup.resendSent',
            defaultMessage: 'Email has been successfully sent!',
          })}
        </Typography>
      );
  }
};

const EmailVerificationForm: FunctionComponent<{
  clickCallback: () => void;
}> = ({ clickCallback }) => {
  const intl = useIntl();
  const [state, setState] = useState(State.empty);
  const resend = async () => {
    setState(State.loading);
    const request = await VerificationService.send();
    clickCallback();

    if (isSuccess(request) || isFailure(request)) {
      setState(State.success);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          background: `#000000 url(${bgImage}) no-repeat 50% 100%`,
          height: '50%',
          display: 'flex',
          position: 'relative',
          '&:before': {
            content: '""',
            background: `url(${iconSmall}) no-repeat`,
            position: 'absolute',
            width: '100px',
            height: '100px',
            bottom: '20px',
            left: 'calc(50% - 150px)',
          },
        }}
      >
        <Box
          sx={{
            background: `url(${icon}) no-repeat 50% 50%/contain`,
            width: '60%',
            height: '62%',
            margin: 'auto',
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          typography="h4"
          sx={{
            marginTop: 2,
            lineHeight: '1.5',
            marginBottom: 2,
            '& span': {
              color: '#12AE9F',
            },
          }}
        >
          {intl.formatMessage(
            {
              id: 'verificationPopup.title',
              defaultMessage:
                'Get your <span>FREE</span> <value></value> tickets Bonus',
            },
            {
              span: (value: any) => <span>{value}</span>,
              value: () => '3',
            }
          )}
        </Typography>
        <Typography
          typography="p"
          sx={{
            fontWeight: 500,
            '& span': {
              color: '#12AE9F',
            },
          }}
        >
          {intl.formatMessage(
            {
              id: 'verificationPopup.description',
              defaultMessage: 'when you verify your email address',
            },
            {
              span: (value: any) => (
                <>
                  <br />
                  <span>{value}</span>
                </>
              ),
            }
          )}
        </Typography>
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <Message state={state} />
        </Box>
        <Box>
          {state === State.success ? (
            <Countdown endCallback={() => setState(State.empty)} />
          ) : (
            <Button
              onClick={resend}
              color="primary"
              disabled={state !== State.empty}
              fullWidth={false}
            >
              {intl.formatMessage({
                id: 'verificationPopup.resend',
                defaultMessage: 'Resend',
              })}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EmailVerificationForm;

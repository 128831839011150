import themeOptions from './rewardis-landing';
import { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { checkboxClasses } from '@mui/material/Checkbox';

const landingThemeOptions: ThemeOptions = {
  ...themeOptions,
  components: {
    ...themeOptions.components,
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          [theme.breakpoints.up('md')]: {
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          if (ownerState.type !== 'button') {
            return {
              color: '#3D25D2',
              textDecorationColor: '#3D25D2',
            };
          }
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#03A369',
      light: '#FFFFFF',
    },
    secondary: {
      main: '#07091D',
      light: '#131526',
    },
    error: {
      main: '#FF8787',
      light: '#FDF2F2',
    },
    success: {
      main: '#03A369',
      light: '#11B479',
    },
    text: {
      primary: '#07091D',
      disabled: '#6D6E81',
      secondary: '#FFFFFF',
    },
    background: {
      default: '#07091D',
      paper: '#FFFFFF',
    },
  },
  custom: {
    ...themeOptions.custom,
    genderField: {
      mainColor: '#03A369',
      height: '42px',
    },
    control: {
      height: '42px',
      fontWeight: 500,
      fontSize: '14px',
    },
    inheritContainedButton: {
      color: 'black',
      backgroundColor: 'inherit',
      hoverBackgroundColor: 'inherit',
    },
    earningStreamItem: {
      mainBackground: '#FFFFFF',
      secondBackground: '#F5F5F5',
      mainColor: '#07091D',
      secondColor: '#686A77',
    },
  },
};

const theme = createTheme(landingThemeOptions);

export default theme;

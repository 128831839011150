import { BROWSER } from './browsers';
import { INeedBrowsersForIntent, TYPE_BROWSERS_WV } from './types';

type listOfWebviewBrowsers = {
  [key in TYPE_BROWSERS_WV]?: RegExp[];
};

class InApp {
  ua: string;

  useBrowsersForIntent?: INeedBrowsersForIntent;

  private listOfWebviewBrowsers: listOfWebviewBrowsers | undefined;

  constructor(
    useragent: string,
    useBrowsersForIntent?: INeedBrowsersForIntent
  ) {
    this.ua = useragent;
    this.useBrowsersForIntent = useBrowsersForIntent;
  }

  get browser(): string {
    let browserKey;

    browserKey = Object.entries(BROWSER).find(([, regex]) =>
      regex.test(this.ua)
    );
    return browserKey ? browserKey[0] : 'other';
  }

  get isInApp(): boolean {
    const rules = [
      'WebView',
      '(iPhone|iPod|iPad)(?!.*(Chrome|CriOS|CrMo|Edge|OPR|Edg|EdgiOS))(.*Safari/)',
      'Android.*(wv)',
    ];
    if (this.useBrowsersForIntent) {
      Object.entries(this.useBrowsersForIntent).forEach(([, value]) => {
        if (value.isEnabled) {
          rules.push(value.type);
        }
      });
    }

    const regex = new RegExp(`(${rules.join('|')})`, 'ig');

    return Boolean(this.ua.match(regex));
  }
}

export default InApp;

import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { checkboxClasses } from '@mui/material/Checkbox';
import { radioClasses } from '@mui/material/Radio';
import { ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    h2: {
      fontSize: '45px',
      fontWeight: 500,
      lineHeight: '38px',
    },
    h3: {
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '38px',
    },
    h4: {
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '48px',
    },
  },
  palette: {
    primary: {
      main: '#3D25D2',
    },
    secondary: {
      main: '#666',
    },
    success: {
      main: '#11B87C',
    },
    error: {
      main: red.A100,
    },
    text: {
      primary: '#bbb',
      secondary: '#64646F',
      disabled: '#FFF',
    },
    background: {
      paper: '#181829',
    },
    action: {
      disabledBackground: '#3a3a3a',
      disabled: '#aaa',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#000',
          borderColor: '#fff',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#000',
          borderColor: '#fff',
          '&.Mui-selected': {
            backgroundColor: '#333',
          },
          '&:hover': {
            backgroundColor: '#222',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          [`&:not(.${checkboxClasses.checked}) .MuiBox-root`]: {
            borderWidth: '1px',
            borderStyle: 'solid',
            ...(ownerState.color === 'error'
              ? {
                  borderColor: theme.palette.error.main,
                }
              : {
                  borderColor: theme.palette.text.secondary,
                  [theme.breakpoints.down('md')]: {
                    borderColor: theme.palette.text.primary,
                  },
                }),
          },
          padding: 0,
          margin: 0,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          paddingRight: '0 !important',
        },
        icon: {
          display: 'none',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          [`&.${radioClasses.checked}`]: {
            color: '#11B87C',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#FFF',
          '&.Mui-focused': {
            color: '#FFF',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            color: '#CCC',
            '-webkit-text-fill-color': '#CCC',
          },
        },
        root: {
          border: '0',
          borderRadius: '12px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: '#eee',
          },
          '&.Mui-disabled': {
            color: '#CCC',
            '-webkit-text-fill-color': '#CCC',
          },
          color: '#fff',
        },
        root: {
          backgroundColor: '#181829',
          borderRadius: '12px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant !== 'contained' &&
            ownerState.color === 'inherit' && {
              padding: '8px 24px',
              color: '#FFFFFF',
              backgroundColor: '#2A2C39',
              ':hover': {
                backgroundColor: 'rgba(42, 44, 57, 0.8)',
                color: '#FFFFFF',
              },
              ':focus': {
                outline: 'none',
              },
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant !== 'contained' && {
              padding: 0,
              color: '#FFFFFF',
              textDecoration: 'underline',
            }),
          ...(ownerState.color === 'secondary' &&
            ownerState.variant !== 'contained' && {
              background: 'rgba(42, 44, 57, 0.8)',
              color: '#FFFFFF',
            }),
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.error': {
            color: '#000000',
          },
        }),
      },
    },
  },
  custom: {
    genderField: {
      height: '56px',
    },
    earningStreamItem: {
      mainBackground: '',
      mainColor: '',
      secondBackground: '',
      secondColor: '',
    },
  },
};
const theme = createTheme(themeOptions);

export default theme;

import * as storage from 'src/lib/storage';

const KEY = 'traffic_source';
const TTL = 1800000; // 30 min

export function setTrafficSource(trafficSource: string): void {
  const now = new Date();
  storage.set(KEY, { value: trafficSource, expiry: now.getTime() + TTL });
}

export function getTrafficSource(): string | null {
  const { value, expiry } = storage.get(KEY, {});
  if (!value || !expiry) {
    return null;
  }

  const now = new Date();
  if (now.getTime() > expiry) {
    storage.reset(KEY);
    return null;
  }

  return value;
}

import { useFlowConsumer } from 'src/providers/RegFlowProvider';
import { useEffect } from 'react';
import { REG_FLOW } from 'src/constants/enums';
import { ROUTE_POSTREG_TASKS } from 'src/constants/routes';
import { useNavigate } from 'react-router-dom';
import { useProfileState } from 'src/providers/ProfileProvider';

const PostRegProvider = () => {
  const [step, pop] = useFlowConsumer();
  const navigate = useNavigate();
  const { profile } = useProfileState();

  useEffect(() => {
    if (profile && step === REG_FLOW.POST_REG_TASKS) {
      pop();
      navigate(ROUTE_POSTREG_TASKS);
    }
  }, [step, profile]);

  return null;
};

export default PostRegProvider;

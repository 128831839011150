import { buildUrlWithParams } from 'src/pages/registration/utils';
import { useRecoilValue } from 'recoil';
import { registrationParamsState } from 'src/recoil/registrationParams';
import * as Sentry from '@sentry/browser';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import { RegParamsStorageService } from 'src/services/AuthService';

const ZONE_LINK = 'https://shulugoo.net/4/7189611';

const useRedirectUrl = () => {
  const regParams = useRecoilValue(registrationParamsState);
  const feature = useFeature(FEATURE.INAPP_POPUP);

  return () => {
    const { oaid: _oiad, sub_id1, sub_id2 } = regParams;
    const oaid = _oiad ?? RegParamsStorageService.data?.oaid;

    if (!oaid) {
      Sentry.captureException('empty oaid', (scope) => {
        scope.setExtra('meta', regParams);

        return scope;
      });
      return;
    }

    return buildUrlWithParams(feature?.link ?? ZONE_LINK, {
      var: sub_id1!,
      ymid: sub_id2!,
      oaid,
    });
  };
};

export default useRedirectUrl;

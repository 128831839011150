import { atom } from 'recoil';
import * as GlobalTasksService from 'src/services/GlobalTasksService';
import { GLOBAL_TASK } from 'src/constants/enums';

type State = GLOBAL_TASK[];
const defaultState: GLOBAL_TASK[] = [];

export const globalTasksAtom = atom<State>({
  key: 'globalTasks',
  default: GlobalTasksService.get() ?? defaultState,
});

import { useRef, useState } from 'react';
import { Box, Theme } from '@mui/material';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { useIFrameTaskDispatch, useIFrameTaskState } from '.';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';

const containerStyle = (theme: Theme) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  boxShadow: 2,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    width: '70%',
    height: '80%',
    maxWidth: '960px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    height: '95%',
  },
});

const createIFrameStyle = (isIFrameLoaded: boolean) => ({
  width: isIFrameLoaded ? '100%' : '1px',
  height: isIFrameLoaded ? '100%' : '1px',
});

export default function IFrameModal() {
  const { task } = useIFrameTaskState();
  const { resetTask } = useIFrameTaskDispatch();
  const [isIFrameLoaded, setIFrameLoaded] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const close = () => {
    resetTask();
    setIFrameLoaded(false);
  };

  if (!task || !open) {
    return null;
  }

  return (
    <Modal open={Boolean(task)} onClose={() => close()}>
      <Box sx={containerStyle} data-iframe>
        <>
          {!isIFrameLoaded ? (
            <CircularProgress
              size={'3em'}
              sx={{ margin: '0 auto', display: 'block' }}
            />
          ) : null}
          <iframe
            src={task.url}
            ref={iframeRef}
            onLoad={() => setIFrameLoaded(true)}
            style={createIFrameStyle(isIFrameLoaded)}
          />
          <Box
            onClick={() => close()}
            sx={{
              background: '#fff',
              borderRadius: 2,
              position: 'absolute',
              right: 15,
              top: 15,
              width: 28,
              height: 28,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <CloseIcon />
          </Box>
        </>
      </Box>
    </Modal>
  );
}

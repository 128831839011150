import { RemoteData, RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';
import { PROJECT_ALIAS } from 'src/constants/app';

export function getRegistationImpression(
  params: Object
): Promise<RemoteDataResult<RegistationImpression>> {
  return request({
    url: `v1/impression/reg`,
    method: 'GET',
    params: { ...params, project: PROJECT_ALIAS },
  });
}

export const offerImpression = (
  userId: number,
  offerId: number,
  position = 1,
  geo: string,
  route?: string
) => {
  return request({
    url: 'v1/offer_impression/',
    params: {
      offer_id: offerId,
      user_id: userId,
      position,
      route,
      geo,
    },
  });
};

export const savePromotionClick = (
  reg_promotion_id: number,
  answer: number
): Promise<RemoteData<boolean>> => {
  return request({
    url: 'v1/promotion/click/',
    method: 'get',
    params: {
      reg_promotion_id,
      answer,
    },
  });
};

export const saveImpressionByUrl = (url: string) => {
  return request({
    url: url,
    method: 'get',
  });
};

export const saveMainPageImpression = (type: string) => {
  return request({
    url: '/v1/member_impression/main_page',
    method: 'get',
    params: {
      type,
    },
  });
};

export const saveRegImpressionId = (googleToken: string, regImpId: number) =>
  request({
    url: '/v1/google/token_store',
    method: 'post',
    data: {
      token: googleToken,
      reg_imp_id: regImpId,
    },
  });

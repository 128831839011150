export const DESIGN_ID = {
  OLD: 1,
  NEW: 2,
  OLD_FAST: 3,
  SHORT_FORM: 4,
  FB_BUTTON: 21,
  INNER_AUTH_SHORT_FORM_2_STEPS: 31,
  INNER_AUTH_FULL_FORM_2_STEPS: 32,
  INNER_AUTH_FULL_FORM_1_STEP: 33,
  HIDE_GOOGLE_AUTH_FULL_DATA: 29,
  DISABLED_GOOGLE_CAPTCHA: 40,
  CLOUDFLARE_CAPTCHA: 41,
  CLOUDFLARE_CAPTCHA_BASE: 42,
};

export const LANDING_DESIGN_ID = [
  DESIGN_ID.SHORT_FORM,
  DESIGN_ID.NEW,
  DESIGN_ID.OLD,
  DESIGN_ID.OLD_FAST,
  DESIGN_ID.FB_BUTTON,
  DESIGN_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
  DESIGN_ID.INNER_AUTH_FULL_FORM_1_STEP,
  DESIGN_ID.INNER_AUTH_FULL_FORM_2_STEPS,
  DESIGN_ID.INNER_AUTH_SHORT_FORM_2_STEPS,
  DESIGN_ID.DISABLED_GOOGLE_CAPTCHA,
  DESIGN_ID.CLOUDFLARE_CAPTCHA,
  DESIGN_ID.CLOUDFLARE_CAPTCHA_BASE,
];

export const GREEN_LANDING_DESIGN_ID = [
  DESIGN_ID.OLD,
  DESIGN_ID.FB_BUTTON,
  DESIGN_ID.SHORT_FORM,
  DESIGN_ID.NEW,
  DESIGN_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
  DESIGN_ID.INNER_AUTH_FULL_FORM_1_STEP,
  DESIGN_ID.INNER_AUTH_FULL_FORM_2_STEPS,
  DESIGN_ID.INNER_AUTH_SHORT_FORM_2_STEPS,
  DESIGN_ID.DISABLED_GOOGLE_CAPTCHA,
  DESIGN_ID.CLOUDFLARE_CAPTCHA,
  DESIGN_ID.CLOUDFLARE_CAPTCHA_BASE,
];

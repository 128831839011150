import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { FieldHookConfig, useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

import {
  MIN_AGE_LIMIT,
  createRange,
  CURRENT_YEAR,
  daysInMonth,
  MAX_AGE_LIMIT,
} from 'src/lib/date';
import { ValidationSchemaProps } from '../typings';

const date = new Date();

const CURRENT_MONTH = date.getMonth();
const CURRENT_DAY = date.getDate();

const YEAR_FROM = CURRENT_YEAR - 100;
const YEAR_TO = CURRENT_YEAR - MIN_AGE_LIMIT;

export const initialValue = '';

const createMonthRange = (intl: IntlShape) => {
  const months = [
    { value: 1, days: 31, id: 'date.january', defaultMessage: 'January' },
    { value: 2, id: 'date.february', defaultMessage: 'February' },
    { value: 3, id: 'date.march', defaultMessage: 'March' },
    { value: 4, id: 'date.april', defaultMessage: 'April' },
    { value: 5, id: 'date.may', defaultMessage: 'May' },
    { value: 6, id: 'date.june', defaultMessage: 'June' },
    { value: 7, id: 'date.july', defaultMessage: 'July' },
    { value: 8, id: 'date.august', defaultMessage: 'August' },
    { value: 9, id: 'date.september', defaultMessage: 'September' },
    { value: 10, id: 'date.october', defaultMessage: 'October' },
    { value: 11, id: 'date.november', defaultMessage: 'November' },
    { value: 12, id: 'date.december', defaultMessage: 'December' },
  ];

  return months.map(({ value, days, id, defaultMessage }) => ({
    value,
    days,
    label: intl.formatMessage({ id, defaultMessage }),
  }));
};

const createYearsMenuItems = () =>
  createRange(YEAR_TO, YEAR_FROM).map((year) => (
    <MenuItem key={year} value={year}>
      {year}
    </MenuItem>
  ));

const createMonthMenuItems = (intl: IntlShape) =>
  createMonthRange(intl).map(({ value, label }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ));

const createDayMenuItems = (year: number, month: number) =>
  createRange(1, daysInMonth(year, month)).map((day) => (
    <MenuItem key={day} value={day}>
      {day}
    </MenuItem>
  ));

const parseBirthDate = (value: string = '') => {
  const [year, month, day] = value.split('-').map((s) => parseInt(s, 10));
  if (day) {
    return { year, month, day };
  }

  return { year: YEAR_TO, month: CURRENT_MONTH + 1, day: CURRENT_DAY };
};

const prepareBirthDate = (
  birthDateDay: number,
  birthDateMonth: number,
  birthDateYear: number
): string => {
  const day = birthDateDay < 10 ? '0' + birthDateDay : birthDateDay;
  const month = birthDateMonth < 10 ? '0' + birthDateMonth : birthDateMonth;

  return `${birthDateYear}-${month}-${day}`;
};

const requiredMessage = (intl: IntlShape) =>
  intl.formatMessage({
    id: 'form.validation.required.birthDate',
    defaultMessage: 'This field is required',
  });

const ageLimitMessage = (intl: IntlShape) =>
  intl.formatMessage(
    {
      id: 'form.validation.ageLimit',
      defaultMessage: `Should be greater than {minAgeLimit} years and less than {maxAgeLimit} years old`,
    },
    {
      minAgeLimit: MIN_AGE_LIMIT,
      maxAgeLimit: MAX_AGE_LIMIT,
    }
  );
const isDateWithinAgeLimits: yup.TestFunction<Date | undefined> = (
  date
): boolean => {
  if (!date) return false;

  const currentDate = new Date();
  const oneHundredYearsAgo = new Date(
    currentDate.getFullYear() - 100,
    currentDate.getMonth(),
    currentDate.getDate() - 1
  );
  const ageLimitDate = new Date(
    currentDate.getFullYear() - MIN_AGE_LIMIT,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  return date > oneHundredYearsAgo && date <= ageLimitDate;
};

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .date()
    .required(requiredMessage(intl))
    .test('ageLimit', ageLimitMessage(intl), isDateWithinAgeLimits);

export function Component(
  props: { touchRequired?: boolean } & FieldHookConfig<string>
) {
  const [field, meta, helpers] = useField(props);
  const { year, month, day } = parseBirthDate(field.value);
  const [fieldsTouched, setFieldsTouched] = useState<boolean>(
    Boolean(!props.touchRequired)
  );
  const [birthdateDay, setBirthdateDay] = useState<number>(day);
  const [birthdateMonth, setBirthdateMonth] = useState<number>(month);
  const [birthdateYear, setBirthdateYear] = useState<number>(year);

  const intl = useIntl();

  useEffect(() => {
    setBirthdateDay(day);
    setBirthdateMonth(month);
    setBirthdateYear(year);
  }, [field.value]);

  useEffect(() => {
    if (fieldsTouched) {
      if (birthdateDay && birthdateMonth && birthdateYear) {
        helpers.setValue(
          prepareBirthDate(birthdateDay, birthdateMonth, birthdateYear)
        );
      } else {
        helpers.setValue('');
      }
    }
  }, [fieldsTouched, birthdateDay, birthdateMonth, birthdateYear]);

  const onFieldTouch = () => {
    if (!fieldsTouched) {
      setFieldsTouched(true);
    }
  };

  const hasError = Boolean(meta.touched && meta.error);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '12px',
        alignItems: 'center',
      }}
    >
      <FormControl
        sx={(theme) => ({
          flex: 1,
          [theme.breakpoints.up('md')]: {
            flex: 1.5,
          },
        })}
      >
        <FormLabel
          sx={(theme) => ({
            display: 'block',
            fontSize: '1em',
            fontWeight: theme?.custom?.control?.fontWeight ?? 400,
          })}
        >
          {intl.formatMessage({
            id: 'form.birth_date',
            defaultMessage: 'Birth date',
          })}
        </FormLabel>
      </FormControl>

      <FormGroup
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          flex: 4,
          [theme.breakpoints.down('sm')]: {
            gap: 1,
          },
        })}
      >
        <FormControl
          sx={{ flex: 2, width: '60px' }}
          fullWidth
          variant="outlined"
          error={hasError}
        >
          <Select
            displayEmpty
            name="birth_date_day"
            id="birth_date_day"
            value={birthdateDay}
            onOpen={() => onFieldTouch()}
            onChange={(e) => {
              setBirthdateDay(Number(e.target.value));
            }}
            placeholder={intl.formatMessage({
              id: 'form.birth_date_day',
              defaultMessage: 'Day',
            })}
          >
            <MenuItem value="">
              <Typography sx={{ color: 'grey' }}>
                {intl.formatMessage({
                  id: 'form.birth_date_day',
                  defaultMessage: 'Day',
                })}
              </Typography>
            </MenuItem>
            {createDayMenuItems(birthdateYear, birthdateMonth)}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          error={hasError}
          sx={{
            flex: '4',
          }}
        >
          <Select
            displayEmpty
            name="birth_date_month"
            id="birth_date_month"
            value={birthdateMonth}
            onOpen={() => onFieldTouch()}
            onChange={(e) => {
              const month = e.target!.value as number;
              const days = daysInMonth(birthdateYear, month);

              const newDays =
                year === YEAR_TO && month - 1 === CURRENT_MONTH
                  ? Math.min(days, birthdateDay, CURRENT_DAY)
                  : Math.min(days, birthdateDay);

              if (birthdateDay != newDays) {
                setBirthdateDay(newDays);
              }

              setBirthdateMonth(month);
            }}
            placeholder={intl.formatMessage({
              id: 'form.birth_date_month',
              defaultMessage: 'Month',
            })}
          >
            <MenuItem value="">
              <Typography sx={{ color: 'grey' }}>
                {intl.formatMessage({
                  id: 'form.birth_date_month',
                  defaultMessage: 'Month',
                })}
              </Typography>
            </MenuItem>
            {createMonthMenuItems(intl)}
          </Select>
        </FormControl>

        <FormControl
          sx={{ flex: 3 }}
          fullWidth
          variant="outlined"
          error={hasError}
        >
          <Select
            displayEmpty
            name="birth_date_year"
            id="birth_date_year"
            value={birthdateYear}
            onOpen={() => onFieldTouch()}
            onChange={(e) => {
              const year = e.target!.value as number;
              const days = daysInMonth(year, birthdateMonth);

              const newDays =
                year === YEAR_TO && month - 1 === CURRENT_MONTH
                  ? Math.min(days, birthdateDay, CURRENT_DAY)
                  : Math.min(days, birthdateDay);

              if (birthdateDay != newDays) {
                setBirthdateDay(newDays);
              }

              setBirthdateYear(year);
            }}
            placeholder={intl.formatMessage({
              id: 'form.birth_date_year',
              defaultMessage: 'Year',
            })}
          >
            <MenuItem value="">
              <Typography sx={{ color: 'grey' }}>
                {intl.formatMessage({
                  id: 'form.birth_date_year',
                  defaultMessage: 'Year',
                })}
              </Typography>
            </MenuItem>
            {createYearsMenuItems()}
          </Select>
        </FormControl>
      </FormGroup>
    </Box>
  );
}

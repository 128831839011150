import {
  FieldHookConfig,
  FieldInputProps,
  FieldMetaProps,
  useField,
} from 'formik';
import { useIntl } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import Checkbox from 'src/components/inputs/Checkbox';
import { FunctionComponent } from 'react';
import Link from '@mui/material/Link';

export const initialValue = false;

export interface PrivacyAgreementFieldProps {
  onPrivacyLinkClick: () => void;
  onTermsLinkClick: () => void;
  field: FieldInputProps<boolean>;
  meta: FieldMetaProps<boolean>;
}

const PrivacyAgreementField: FunctionComponent<PrivacyAgreementFieldProps> = ({
  onPrivacyLinkClick,
  onTermsLinkClick,
  field,
  meta,
}) => {
  const intl = useIntl();
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <FormGroup
      sx={{
        marginY: 1,
        paddingTop: 1,
      }}
    >
      <FormControl required error={hasError}>
        <FormControlLabel
          {...field}
          control={<Checkbox hasError={hasError} id={field.name} />}
          checked={field.value}
          sx={{
            margin: 0,
            alignItems: 'flex-start',
          }}
          label={
            <Typography
              className={`${hasError && 'error'}`}
              sx={(theme) => ({
                fontSize: '0.7em',
                color: '#7B7B7B',
                marginLeft: 1.5,
                ...(hasError && {
                  backgroundColor: theme.palette.error.light,
                  boxShadow: `0px 0px 1px 2px ${theme.palette.error.main}`,
                }),
              })}
              onClick={(e) => {
                if (field.value) {
                  e.preventDefault();
                }
              }}
            >
              {intl.formatMessage(
                {
                  id: 'form.term_and_conditions_checkbox',
                  defaultMessage: `By clicking 'Create an account' you agree to <name>name</name> <a1>Terms and Conditions</a1> and <name>name</name> <a2>Privacy Policy</a2>`,
                },
                {
                  a1: (link: any) => (
                    <Link
                      onClick={(e) => {
                        onTermsLinkClick();
                      }}
                      sx={{
                        display: 'inline-block',
                      }}
                    >
                      {link}
                    </Link>
                  ),
                  a2: (link: any) => (
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        onPrivacyLinkClick();
                      }}
                    >
                      {link}
                    </Link>
                  ),
                  name: () => 'Rewardis',
                }
              )}
            </Typography>
          }
        />
      </FormControl>
    </FormGroup>
  );
};

export default PrivacyAgreementField;

import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import UserFields from 'src/components/forms/RegistationForm/UserFields';
import {
  FormEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import * as yup from 'yup';
import { FieldName } from 'src/components/forms/RegistationForm/typings';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';

import { createValidationSchema } from 'src/components/forms/RegistationForm';
import { createErrorContainer } from 'src/lib/errors';

type Props = {
  fields: FieldName[];
  onSubmit: (values: {}, formikHelpers: FormikHelpers<any>) => Promise<any>;
  handleErrorsChange: any;
  submitButtonText: string;
};

const MultiStepRegistrationForm: FunctionComponent<Props> = ({
  fields,
  onSubmit,
  handleErrorsChange,
  submitButtonText,
}) => {
  const intl = useIntl();
  const validationProps = { intl };
  const validationSchema = useMemo(
    () => createValidationSchema(fields, validationProps),
    [fields, validationProps]
  );
  const formik = useFormik({
    initialValues: fields.reduce((acc, field) => {
      return {
        ...acc,
        [field]: '',
      };
    }, {}),
    validationSchema,
    onSubmit,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const getFormErrorContainer = async () => {
    try {
      await validationSchema.validate(formik.values, {
        abortEarly: false,
      });
      return null;
    } catch (err) {
      return createErrorContainer(err as yup.ValidationError);
    }
  };

  const validateError = async () => {
    const errorContainer = await getFormErrorContainer();

    if (errorContainer) {
      handleErrorsChange(formik.errors, errorContainer);
    }
  };

  const submitForm = useCallback(async () => {
    validateError();
    formik.handleSubmit();
  }, [
    formik.errors,
    formik.handleSubmit,
    formik.values,
    validationSchema,
    handleErrorsChange,
  ]);

  const handleSubmitForm = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      submitForm();
    },
    [submitForm]
  );

  return (
    <FormikProvider value={formik}>
      <form onReset={formik.handleReset} onSubmit={handleSubmitForm}>
        <UserFields fields={fields} />
        <Box
          sx={() => ({
            display: 'flex',
            gap: 1,
            marginTop: 2,
            flexWrap: 'wrap',
            '& button': {
              flex: '0 auto',
              flexBasis: '100%',
            },
          })}
        >
          <Button
            size="large"
            disabled={formik.isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
            id="signup_button"
            onClick={handleSubmitForm}
            data-reg-popup
          >
            {formik.isSubmitting ? (
              <CircularProgress style={{ color: 'white' }} size={26} />
            ) : (
              submitButtonText
            )}
          </Button>
        </Box>
      </form>
    </FormikProvider>
  );
};

export default MultiStepRegistrationForm;

import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';

interface ModalTaskListButtonProps {
  source: string;
  task: Task;
  applyTask: (source: string, task: Task) => Promise<void>;
}

const ModalTaskListButton: FunctionComponent<ModalTaskListButtonProps> = ({
  source,
  task,
  applyTask,
}) => {
  const intl = useIntl();

  if (task.url) {
    return (
      <Button
        fullWidth
        variant="contained"
        onClick={async () => await applyTask(source, task)}
      >
        {intl.formatMessage({
          id: 'task.complete',
          defaultMessage: 'Complete',
        })}
      </Button>
    );
  }

  return null;
};

export default ModalTaskListButton;

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface LoaderProps {
  title?: string;
  fullSize?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit';
}

export default function Loader({
  title,
  fullSize = false,
  color = 'primary',
}: LoaderProps) {
  return (
    <Box
      sx={{
        width: 1,
        minHeight: fullSize ? '100vh' : undefined,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {title ? (
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      ) : null}
      <CircularProgress size={'3em'} color={color} />
    </Box>
  );
}

import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';

type Blacklist = string[];

export function getData(): Promise<RemoteDataResult<Blacklist>> {
  return request({
    url: `v1/blacklists/`,
    method: 'GET',
  });
}

import { CSSProperties } from 'react';
import useRequest from 'src/hooks/useRequest';
import { isSuccess } from 'src/lib/remoteData';
import * as DebugService from 'src/services/DebugService';
import { DEPLOY_BRANCH, DEPLOY_BY } from 'src/constants/app';

const panelStyle: CSSProperties = {
  width: '100%',
  color: '#FFF',
  background: '#000',
  fontSize: 12,
};

const containerStyle: CSSProperties = {
  width: '80%',
  margin: '0 auto',
  paddingTop: 4,
  paddingBottom: 4,
  borderBottom: '1px solid #505050',
  textAlign: 'center',
};

const titleStyle: CSSProperties = {
  fontWeight: 'bold',
  marginTop: 2,
  marginBottom: 2,
};

const labelStyle: CSSProperties = {
  background: '#3da200',
  paddingLeft: 2,
  paddingRight: 2,
  marginLeft: 2,
  marginRight: 2,
  borderRadius: 2,
};

interface DebugRowProps {
  title: string;
  branch?: string;
  revision?: string;
  deployedBy?: string;
  deployedAt?: string;
}

function DebugRow({
  title,
  branch,
  revision,
  deployedBy,
  deployedAt,
}: DebugRowProps) {
  return (
    <div style={panelStyle}>
      <div style={containerStyle}>
        <span style={titleStyle}>{title}: </span>
        Deployed{' '}
        {deployedBy ? (
          <>
            by
            <span style={labelStyle}>{deployedBy}</span>
          </>
        ) : null}
        {deployedAt ? (
          <>
            at
            <span style={labelStyle}>{deployedAt}</span>
          </>
        ) : null}
        {branch ? (
          <>
            Branch
            <span style={labelStyle}>{branch}</span>
          </>
        ) : null}
        {revision ? (
          <>
            Revision
            <span style={labelStyle}>{revision}</span>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default function WrapperDebugPanel() {
  const shouldRender = DEPLOY_BRANCH || DEPLOY_BY;

  if (shouldRender) {
    return <DebugPanel />;
  }

  return null;
}

function DebugPanel() {
  const [response] = useRequest(async () => await DebugService.getDebugData());

  if (!isSuccess(response)) {
    return (
      <DebugRow
        title="frontend"
        branch={DEPLOY_BRANCH}
        deployedBy={DEPLOY_BY}
      />
    );
  }

  const { branch, revision, deployed_by, deployed_at } = response.data;

  return (
    <>
      <DebugRow
        title="frontend"
        branch={DEPLOY_BRANCH}
        deployedBy={DEPLOY_BY}
      />
      <DebugRow
        title="backend"
        branch={branch}
        revision={revision}
        deployedBy={deployed_by}
        deployedAt={new Date(deployed_at).toUTCString()}
      />
    </>
  );
}

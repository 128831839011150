import { ReactNode, createContext, useReducer, useContext } from 'react';

import ApplixirModal from './ApplixirModal';

interface State {
  task?: Task;
  error?: string;
}

const initialState = {};

type Dispatch = (action: Action) => void;

const ApplixirStateContext = createContext<State | undefined>(undefined);
const ApplixirDispatchContext = createContext<Dispatch | undefined>(undefined);

type Action =
  | { type: 'resetTask' }
  | { type: 'setTask'; payload: Task }
  | { type: 'setError'; error: string };

function ApplixirReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'resetTask': {
      return {
        ...state,
        task: undefined,
      };
    }
    case 'setError': {
      return {
        ...state,
        error: action.error,
      };
    }
    case 'setTask': {
      return {
        ...state,
        task: action.payload,
      };
    }
    default:
      throw new Error(`Unhandled action`);
  }
}

export function useApplixirState() {
  const state = useContext(ApplixirStateContext);

  if (state === undefined) {
    throw new Error(
      'useApplixirState must be used within a `ApplixirStateContext`'
    );
  }

  return state;
}

export function useApplixirDispatch() {
  const dispatch = useContext(ApplixirDispatchContext);

  if (dispatch === undefined) {
    throw new Error(
      'useApplixirDispatch must be used within a `ApplixirDispatchProvider`'
    );
  }

  return {
    setError: (error: string) => dispatch({ type: 'setError', error }),
    setTask: (payload: Task) => dispatch({ type: 'setTask', payload }),
    resetTask: () => dispatch({ type: 'resetTask' }),
  };
}

interface ApplixirProviderProps {
  children?: ReactNode;
}

export default function ApplixirProvider({
  children,
}: ApplixirProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(ApplixirReducer, initialState);

  return (
    <ApplixirStateContext.Provider value={state}>
      <ApplixirDispatchContext.Provider value={dispatch}>
        {children}
        <ApplixirModal />
      </ApplixirDispatchContext.Provider>
    </ApplixirStateContext.Provider>
  );
}

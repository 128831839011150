import Alert from '@mui/material/Alert';
import { AxiosResponse } from 'axios';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';

export function ErrorEntity({ error }: ResponseError) {
  const intl = useIntl();

  if (error.key) {
    return (
      <Alert severity="error">
        <span>
          {intl.formatMessage({
            id: error.key,
            defaultMessage: error.message,
          })}
        </span>
      </Alert>
    );
  }

  return <Alert severity="error">{error.message}</Alert>;
}

function ErrorEntities({ errors }: ResponseErrors) {
  const intl = useIntl();

  const alert = Object.entries(errors).map(([fieldName, fieldError]) => (
    <div key={fieldName}>
      <strong>{intl.formatMessage({ id: fieldName })}:</strong>:
      <ErrorEntity error={fieldError} />
    </div>
  ));

  if (!alert.length) {
    return null;
  }

  return <Alert severity="error">{alert}</Alert>;
}

interface ResponseErrorsProps {
  responseError?: AxiosError<ResponseErrors | ResponseError>;
}

export default function ResponseErrors({ responseError }: ResponseErrorsProps) {
  if (!responseError || !Object.keys(responseError).length) {
    return null;
  }

  if ((responseError.response?.data as ResponseError)?.error) {
    return (
      <ErrorEntity
        error={(responseError.response?.data as ResponseError).error}
      />
    );
  }

  if ((responseError.response?.data as ResponseErrors)?.errors) {
    return (
      <ErrorEntities
        errors={(responseError.response?.data as ResponseErrors).errors}
      />
    );
  }

  return <Alert severity="error">{responseError.message}</Alert>;
}

import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import useRequest from 'src/hooks/useRequest';
import { isSuccess } from 'src/lib/remoteData';
import EarningUserItem from './EarningUserItem';
import { TaskRequestModal } from '../Tasks/TaskModal/taskRequestModal';
import * as OffersService from 'src/services/OffersService';
import { useProfileState } from 'src/providers/ProfileProvider';
import { ROUTE_SIGNIN } from 'src/constants/routes';
import useLastClick from 'src/hooks/useLastClick';
import { SAVE_CLICK_ELEMENT_NAME } from 'src/constants/enums';

const DEFAULT_MOVE_LIMIT = 1;
const ITEM_SHOW_LIMIT = 15;
const ITEM_SHOW_TIMEOUT = 5000;

interface EarningSteam {
  loaded: UserEarning[];
  shown: UserEarning[];
}

const moveItems = <T,>(
  { loaded, shown }: { loaded: T[]; shown: T[] },
  limit: number = DEFAULT_MOVE_LIMIT
) => {
  return {
    loaded: loaded.slice(limit),
    shown: [...loaded.slice(0, limit).reverse(), ...shown].slice(
      0,
      ITEM_SHOW_LIMIT
    ),
  };
};

interface EarningUserStreamProps {
  source: string;
}

export default function EarningUserStream({ source }: EarningUserStreamProps) {
  const [taskId, setTaskId] = useState<number | null>(null);
  const [request, manager] = useRequest(
    async () => await OffersService.getLastUserEarnings()
  );
  const { saveLast } = useLastClick();

  const [items, setItems] = useState<EarningSteam>({
    loaded: [],
    shown: [],
  });

  const navigate = useNavigate();
  const { profile } = useProfileState();

  // move items
  useEffect(() => {
    const timer = setTimeout(() => {
      setItems((items) => moveItems(items));
    }, ITEM_SHOW_TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, [items, setItems]);

  // request  batch
  useEffect(() => {
    if (items.shown.length > 0 && items.loaded.length === 0) {
      manager.reloadAsyncSoftly();
    }
  }, [items]);

  // process batch
  useEffect(() => {
    if (isSuccess(request)) {
      setItems((items) => {
        if (items.shown.length === 0) {
          return moveItems(
            {
              shown: [],
              loaded: request.data,
            },
            ITEM_SHOW_LIMIT
          );
        }

        return {
          shown: items.shown,
          loaded: [...items.loaded, ...request.data],
        };
      });
    }
  }, [request]);

  if (!isSuccess(request)) {
    return null;
  }

  const createClickHandler =
    (earning: UserEarning, profile: Profile | null) => async () => {
      saveLast(SAVE_CLICK_ELEMENT_NAME.EARNING_STREAM);
      if (!profile) {
        navigate(ROUTE_SIGNIN);
        return;
      }
      const { offer_id } = earning;
      if (!offer_id) {
        return;
      }

      setTaskId(offer_id);
    };

  return (
    <>
      {taskId == null ? null : (
        <TaskRequestModal
          offerId={taskId}
          source={`earning-stream`}
          onClose={() => setTaskId(null)}
        />
      )}
      <Box
        sx={(theme) => ({
          paddingY: 2,
          overflowX: 'auto',
          display: 'flex',
          flexDirection: 'row',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar ': {
            display: 'none',
          },
          [theme.breakpoints.down('sm')]: {
            paddingY: 0,
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            '@keyframes zoomIn': {
              '0%': {
                opacity: 0,
                transform: 'scale3d(.3, .3, .3)',
              },
              '100%': {
                opacity: 1,
              },
            },
          }}
        >
          {items.shown.map((userEarning) => (
            <EarningUserItem
              key={`${userEarning.name}-${userEarning.created_at}`}
              onClick={createClickHandler(userEarning, profile)}
              userEarning={userEarning}
            />
          ))}
        </Box>
      </Box>
    </>
  );
}
